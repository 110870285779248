import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { pandaAccessToken } from './useGetDocuments'

const updateDocument = async documentData => {
  const url = `${baseUrl}/aws/pandadocuments/${documentData?.deal?.contractId}/update`
  if (!pandaAccessToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.patch(url, documentData, {
      headers: { Authorization: ` ${pandaAccessToken}` },
    })
    console.log('Response Update Document: ', response)
    return response.data
  } catch (error) {
    console.error('Error updating new doc:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useUpdateDocument = () => {
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: updateDocument,
    onSuccess: () => {
      console.log('Successfully updated document')
    },
    onError: error => {
      console.error('[useUpdateDocument] Error updating doc:', error)
    },
  })

  return mutation
}
