import React, { useEffect } from 'react'
import { Typography, ListItem, Box, Avatar, IconButton, List, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUsersById } from '../../api/customHooks/useUsersById'
import { useParams } from 'react-router-dom'
import DetailsLoading from '../Loading/DetailsLoading'
import { useStrategy } from '../../api/aws/useStrategy'

const StrategyNotes = ({ notes, setNotes }) => {
  const theme = useTheme()
  const { dealId } = useParams()
  const { strategy, isLoading, isError } = useStrategy(dealId)
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()

  // Extract user IDs from notes
  const userIds = notes ? notes.flatMap(note => note.owner) : []
  const { users, isLoading: isUsersLoading } = useUsersById(userIds, 'noteUser')

  useEffect(() => {
    if (strategy && strategy?.notes?.length > 0) {
      const sortedNotes = [...strategy.notes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setNotes(sortedNotes)
    }
  }, [strategy])

  const noteSectionBorderColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'

  const handleDeleteNote = noteId => {
    const updatedNotes = notes.filter(note => note.note_id !== noteId)
    setNotes(updatedNotes)

    updateItems(
      {
        updates: [
          {
            primaryKey: 'strategy_uuid',
            tableName: 'strategy',
            itemId: dealId,
            fieldName: 'notes',
            newValue: updatedNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }

  if (isLoading || isUsersLoading) {
    return <DetailsLoading />
  }

  if (isError || !strategy) {
    return <Typography>Error loading strategy notes or no strategy available.</Typography>
  }

  return (
    <List
      sx={{
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
      }}
    >
      {notes && Array.isArray(notes) && notes.length > 0 ? (
        notes.map(note => {
          const noteOwner = users.find(user => user.id === note.owner?.[0])
          return (
            <ListItem
              key={note.note_id}
              sx={{
                m: 0.8,
                display: 'flex',
                flexDirection: 'column', // Stack items vertically
                width: '95%', // Stretch to full width
                bgcolor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(245, 245, 245)',
              }}
            >
              {/* Header with avatar, created time, and delete button */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center', // Align avatar, time, and delete button
                  justifyContent: 'space-between', // Spread avatar and delete button apart
                  width: '100%',
                }}
              >
                {noteOwner && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={noteOwner.name} src={noteOwner.image_512} sx={{ width: 35, height: 35, mr: 2 }} />
                    <Typography sx={{ fontSize: 12 }} variant='subtitle2' fontWeight='bold'>
                      {new Date(note.createdAt).toLocaleString()}
                    </Typography>
                  </Box>
                )}
                <IconButton edge='end' aria-label='delete' onClick={() => handleDeleteNote(note.note_id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>

              {/* Note content displayed underneath */}
              <Typography
                variant='body2'
                sx={{ textAlign: 'left', mt: 1, width: '100%', color: theme.palette.text.primary }} // Adjust content alignment and margin
                dangerouslySetInnerHTML={{ __html: note.contents }}
              />
            </ListItem>
          )
        })
      ) : (
        <ListItem
          key={'notes-placeholder'}
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            width: '100%', // Stretch to full width
          }}
        >
          <Typography sx={{ fontSize: 14, color: noteSectionBorderColor }} variant='subtitle2' fontWeight='bold'>
            Add Notes Here
          </Typography>
        </ListItem>
      )}
    </List>
  )
}

export default StrategyNotes
