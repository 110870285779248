import React, { useState, useEffect } from 'react'
import { List, ListItem, Typography, ListItemIcon, ListItemButton, ListItemText, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import GroboticVersionControl from '../../Version'
import CreateMenu from '../../CreateMenu/CreateMenu'

const MenuItemsNavSection = ({
  open,
  drawerOpen,
  setDrawerOpen,
  onNotifyClose,
  imageUrl,
  handleOpenCreateOrganization,
  handleOpenCreateDeal,
  handleOpenCreateContact,
  onOpenCreateTask,
  onCloseCreateTask,
}) => {
  const theme = useTheme()
  const [initialValues, setInitialValues] = useState([])
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  // Initialize the values when the component mounts
  useEffect(() => {
    setInitialValues([
      { label: 'Create Menu', icon: <AddIcon /> },
      { label: 'Version', icon: <GroboticVersionControl /> },
    ])
  }, [])

  return (
    <List>
      {initialValues.map((item, index) => (
        <ListItem key={item.label} sx={{ display: 'block' }} disablePadding>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              '&:hover': {
                backgroundColor: bgHoverColor,
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: open ? 40 : 0, // Ensure consistent spacing for icons
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* Conditionally render icons based on index */}
              {index === 0 && (
                <CreateMenu
                  onOpenCreateOrganization={handleOpenCreateOrganization}
                  onOpenCreateDeal={handleOpenCreateDeal}
                  onOpenCreateContact={handleOpenCreateContact}
                  onOpenCreateTask={onOpenCreateTask}
                  onCloseCreateTask={onCloseCreateTask}
                />
              )}
              {index === 1 && <GroboticVersionControl />}
            </ListItemIcon>

            <ListItemText
              sx={{
                opacity: open ? 1 : 0,
                ml: open ? 0 : 0, // Ensure the text moves closer to the icon when closed
                whiteSpace: 'nowrap', // Prevent text from wrapping
                fontSize: '14px', // Adjust as per your preference
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
                  transition: 'opacity 0.3s ease', // Smooth transition for the text visibility
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default MenuItemsNavSection
