import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getStrategyById = async (endpoint, tableName, id) => {
  try {
    const url = id ? `${baseUrl}${endpoint}/${id}` : `${baseUrl}${endpoint}`
    const response = await axios.get(url, {
      params: id ? {} : { table: 'strategy' }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useStrategy = (strategyId, queryKey = ['strategy', strategyId]) => {
  const { data: strategy, isLoading, isError } = useQuery({
    queryKey: queryKey, // Use consistent query key
    queryFn: () => getStrategyById('/aws/strategy', 'strategy', strategyId),
    staleTime: Infinity, // Optional: adjust based on your caching needs
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!strategyId, // Only fetch if strategyId is provided
  })

  // Return in the same format as the original hook
  return { strategy, isLoading, isError }
}
