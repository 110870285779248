export const invoiceViewState = {
  id: false,
  implementationFee: false,
  total: false,
  dealStatus: false,
  reasonChurn: false,
  lastDateOfService: false,
  churnNote: false,
  organization: false,
  type: false,
  owner: false,
  billingStatus: false,
  billingContact: false,
  primaryContact: false,
}
export const churnViewState = {
  id: false,
  implementationFee: false,
  total: false,
  dealStage: false,
  invoiceStatus: false,
  organization: false,
  type: false,
  startDate: false,
  endDate: false,
  owner: false,
  billingStatus: false,
  billingContact: false,
  primaryContact: false,
}
export const billingViewState = {
  id: false,
  implementationFee: false,
  total: false,
  dealStatus: false,
  invoiceStatus: false,
  reasonChurn: false,
  lastDateOfService: false,
  churnNote: false,
  organization: false,
  type: false,
  owner: false,
}
