import React, { useState, useEffect, useRef } from 'react'
import { Box, useTheme, Button, Typography, Tooltip, Chip } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteOrganization } from '../../api/aws/useDeleteOrganization'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { renderOrganizationColumns } from '../DataGrid/createColumns/renderOrganizationColumns'
import { filterPanelProps, gridContainerStyles } from './style'
import { useUpdateMassItems } from '../../api/aws/useUpdateMassItems'
import LoadingOverlay from '../Loading/LoadingOverlay'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { OrganizationsGridFooter } from './OrganizationsGridFooter'
import OrganizationsGridToolbar from './OrganizationsGridToolbar'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useUsers } from '../../api/aws/useUsers'
import { useQueryClient } from '@tanstack/react-query'
import { useContacts } from '../../api/aws/useContacts'

const initialSortModel = [
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
]

const OrganizationsPage = () => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['organizations'])
  const { contacts, isContactsLoading, isContactsError } = useContacts(true, ['organizations', 'contacts'])
  // const { data: organizations } = queryClient.getQueryData(['organizations'])
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const { showSnackbar } = useSnackbarContext()
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['organizations', 'gridTeamMembers', 'users'])
  const [progress, setProgress] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // Load filters from localStorage or use an empty default filter model
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('organizationFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('organizationColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          org_uuid: false,
          affiliated: false,
          source: false,
          street: false,
          city: false,
          state: false,
          zip: false,
          cmsUsed: false,
          social: false,
          dealIds: false,
        }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('organizationSortModel')
    return savedSortModel
      ? JSON.parse(savedSortModel)
      : [
          { field: 'creation_timestamp', sort: 'desc' },
          { field: 'name', sort: 'asc' },
        ]
  })
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const [density, setDensity] = useState('standard')
  const [selectionModel, setSelectionModel] = useState([])
  const [massUpdateData, setMassUpdateData] = useState({
    vertical: [],
    type: '',
    status: '',
  })
  const [rowCount, setRowCount] = useState(organizations?.length)
  const [visibleRowCount, setVisibleRowCount] = useState(
    filterModel?.items?.length && filterModel?.items?.length > 0 ? filterModel?.items?.length : organizations?.length
  )
  const apiRef = useGridApiRef()
  // const quickFilterRef = useRef(null)
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateMassItems } = useUpdateMassItems(['organizations'])
  const { mutate: deleteItem } = useDeleteMutation()

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('organizationFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (sortModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('organizationSortModel', JSON.stringify(sortModel))
    }
  }, [sortModel])
  useEffect(() => {
    if (selectionModel.length === 1) {
      const newOrganization = organizations.find(org => org.id === selectionModel[0])
      setSelectedOrganization(newOrganization)
      setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedOrganization(null)
    }
  }, [selectionModel, organizations])

  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedOrganization) {
      if (selectedOrganization.dealIds && selectedOrganization.dealIds.length > 0) {
        showSnackbar('Cannot delete the organization. Please delete associated deals first.', 'error')
        setIsDeleting(false)
        return
      }

      const params = {
        endpoint: `/aws/delete/organizations/${selectedOrganization.id || selectedOrganization.org_uuid}`,
        table: 'organizations',
      }
      deleteItem(params, {
        onSuccess: message => {
          handleClosePopover()
          showSnackbar(message, 'success') // Show success message from backend
          setIsDeleting(false)
        },
        onError: error => {
          console.error('Failed to delete organization:', error)
          showSnackbar(error.message, 'error') // Show error message from backend
          setIsDeleting(false)
        },
      })
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    apiRef.current.setRowSelectionModel([]) // Unselect all rows
    setSelectionModel([])
    setSelectedOrganization(null)
  }

  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }

  const handleMassUpdate = () => {
    setIsUpdating(true)
    const updates = []

    if (massUpdateData.type) {
      selectionModel.forEach(id => {
        updates.push({
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: id,
          fieldName: 'type',
          newValue:
            Array.isArray(massUpdateData.type) && massUpdateData.type.length === 1
              ? massUpdateData.type[0]
              : massUpdateData.type,
        })
      })
    }

    if (massUpdateData.vertical.length) {
      selectionModel.forEach(id => {
        updates.push({
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: id,
          fieldName: 'vertical',
          newValue: massUpdateData.vertical,
        })
      })
    }
    if (massUpdateData.status) {
      selectionModel.forEach(id => {
        updates.push({
          primaryKey: 'org_uuid',
          tableName: 'organizations',
          itemId: id,
          fieldName: 'status',
          newValue:
            Array.isArray(massUpdateData.status) && massUpdateData.status.length === 1
              ? massUpdateData.status[0]
              : massUpdateData.status,
        })
      })
    }

    if (selectionModel.length === 1) {
      updateItems(
        { updates: updates }, // Pass only the first update object
        {
          onSuccess: () => {
            setIsUpdating(false)
            handleClosePopover()
            showSnackbar('Organization updated successfully', 'success')
          },
          onError: () => {
            setIsUpdating(false)
            showSnackbar('Organization update failed', 'error')
          },
        }
      )
    } else {
      // Else use batch update logic
      updateMassItems(
        { updates },
        {
          onSuccess: () => {
            setIsUpdating(false)
            handleClosePopover()
            showSnackbar(`${selectionModel ? selectionModel.length : ''} Organizations updated successfully`, 'success')
          },
          onError: () => {
            setIsUpdating(false)
            showSnackbar('Organizations update failed', 'error')
          },
        }
      )
    }
  }
  const isLoading = isOrganizationsLoading || isUsersLoading || isContactsLoading

  if (isLoading) {
    return <DetailsLoading />
  }

  const columns = renderOrganizationColumns(
    organizations,
    contacts,
    users,
    isUsersLoading,
    density,
    columnVisibilityModel
  )

  const handleFilterModelChange = model => {
    setFilterModel(model)
  }
  const computeVisibleRows = () => {
    if (apiRef.current) {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const allRows = apiRef.current.getAllRowIds()
      console.log('allRows: ', allRows)
      const rows = allRows.filter(rowId => filteredRowsLookup[rowId])
      return rows
    }
    return []
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('organizationSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {
      org_uuid: false,
      affiliated: false,
      source: false,
      street: false,
      city: false,
      state: false,
      zip: false,
      cmsUsed: false,
      social: false,
      dealIds: false,
    }
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('organizationColumnVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ...gridContainerStyles,
          height: 'calc(100vh - 20px)',
        }}
      >
        <DataGridPro
          initialState={{
            // pagination: {
            //   paginationModel: {
            //     pageSize: 25, // Set the default page size here
            //   },
            // },
            sorting: {
              sortModel: [
                { field: 'creation_timestamp', sort: 'desc' },
                { field: 'organizationName', sort: 'asc' },
              ],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: {
                org_uuid: false,
                affiliated: false,
                source: false,
                street: false,
                city: false,
                state: false,
                zip: false,
                cmsUsed: false,
                social: false,
                dealIds: false,
              },
            },
          }}
          rows={organizations}
          columns={columns}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.org_uuid}
          checkboxSelection
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          // pagination
          // paginationMode='server'
          // rowCount={visibleRowCount}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('organizationColumnVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('organizationSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          apiRef={apiRef}
          slots={{
            toolbar: OrganizationsGridToolbar,
            footer: OrganizationsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              setFilterModel: setFilterModel,
              setFilterModel: setFilterModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
            },
            footer: {
              context: 'organizations',
              massUpdateData: massUpdateData,
              selectionModel: selectionModel,
              rowCount,
              onChange: handleMassUpdateChange,
              onUpdate: handleMassUpdate,
              organizations: organizations,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              selectedData: selectedOrganization,
              setSelectedData: setSelectedOrganization,
              setSelectionModel: setSelectionModel,
              apiRef: apiRef,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          // headerFilters
          loading={isLoading}
        />
      </Box>
      <LoadingOverlay open={isUpdating} message={'Updating Organizations'} />
    </>
  )
}

export default OrganizationsPage
