import React from 'react'
import { Button, CircularProgress, Typography, useTheme } from '@mui/material'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
const CreateContractButton = ({ isCreating, onCreate }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(29, 106, 82)',
      }}
      startIcon={<PandaDocImageComponent size={30} />}
      disabled={isCreating}
      onClick={onCreate}
      name='isApproved'
      variant='contained'
    >
      {isCreating ? (
        <Typography>
          Creating...{' '}
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.text.secondary,
            }}
          />
        </Typography>
      ) : (
        <Typography>Create Contract</Typography>
      )}
    </Button>
  )
}

export default CreateContractButton
