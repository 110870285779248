import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const createDeal = async taskData => {
  const url = `${baseUrl}/aws/tasks`

  //////////////////////////////////////////////////
  console.log('NEW DEAL DATA TO SUBMIT[MUTATION]: ', taskData)
  const response = await axios.post(url, taskData, {})
  return response.data // This allows the response data to be accessed in onSuccess
}

export const useCreateTask = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createDeal,
    onMutate: async newTask => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['tasks'])

      // Snapshot the previous value
      const previousDeals = queryClient.getQueryData(['tasks'])
      console.log('[useCreateTask] previousDeals: ', previousDeals ? previousDeals : 'No Previous Deals Cached')
      // Optimistically update to the new value

      return { previousDeals }
    },
    onError: (err, newTask, context) => {
      // Roll back the optimistic update
      queryClient.setQueryData(['tasks'], context.previousDeals)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      console.log('[useCreateTask] onSuccess data: ', data)
      // Invalidate and refetch or update query data

      queryClient.setQueryData(['tasks'], old =>
        old ? [...old, { ...data, id: data.task_uuid }] : [{ ...data, id: data.task_uuid }]
      )
      showSnackbar('Task created successfully', 'success')
    },

    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries(['tasks'])
    },
  })

  return mutation
}
