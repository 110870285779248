import { Box, Chip, useTheme } from '@mui/material'

const RenderVertical = ({ vertical, width, height, size }) => {
  const theme = useTheme()

  // Normalize vertical to be an array and take the first element
  const verticalItem = Array.isArray(vertical) ? vertical[0] : vertical

  let label = ''
  let color = ''
  switch (verticalItem) {
    case 'Real Estate - Student':
    case 'Student':
    case 'Student Housing':
      label = 'Student'
      color = 'rgb(255, 165, 93)'
      break
    case 'Real Estate - Senior':
    case 'Senior':
    case 'Senior Living':
      label = 'Senior'
      color = 'rgb(110, 156, 222)'
      break
    case 'Real Estate - MF':
    case 'MF':
    case 'Multifamily':
    case 'Multi-Family':
    case 'Multi Family':
      label = 'Multifamily'
      color = 'rgb(50, 187, 130)'
      break
    case 'Real Estate - Homebuilding':
    case 'Homebuilding':
    case 'Home Builder':
    case 'Home Building':
      label = 'Homebuilding'
      color = 'rgb(132, 101, 192)'
      break
    case 'Real Estate':
      label = 'Real Estate'
      color = 'rgb(50, 187, 130)'
      break
    case 'Mortgage':
      label = 'Mortgage'
      color = 'rgb(50, 187, 130)'
      break
    case 'Condo Sales':
      label = 'Condo Sales'
      color = 'rgb(50, 187, 130)'
      break
    case 'Build to rent':
    case 'Build To Rent':
    case 'Build To rent':
      label = 'Build to rent'
      color = 'rgb(212, 113, 91)'
      break
    case 'Financial Institution':
      label = 'Financial Institution'
      color = 'rgb(212, 113, 91)'
      break
    case 'Finance':
      label = 'Finance'
      color = 'rgb(255, 155, 147)'
      break
    case 'Technology':
      label = 'Technology'
      color = '#89CFF0'
      break
    case 'Medical':
      label = 'Medical'
      color = 'rgb(248, 131, 121)'
      break
    case 'Healthcare':
    case 'healthcare':
      label = 'Healthcare'
      color = 'rgb(248, 131, 121)'
      break
    case 'Health and Wellness':
      label = 'Health and Wellness'
      color = 'rgb(248, 131, 121)'
      break
    case 'Construction':
      label = 'Construction'
      color = 'rgb(50, 141, 181)'
      break
    case 'Tourism':
      label = 'Tourism'
      color = 'rgb(212, 113, 91)'
      break
    case 'Marketing Agency':
      label = 'Marketing Agency'
      color = 'rgb(212, 113, 91)'
      break
    case 'Manufacturing':
      label = 'Manufacturing'
      color = 'rgb(248, 131, 121)'
      break
    case 'Entertainment':
      label = 'Entertainment'
      color = 'rgb(248, 131, 121)'
      break
    case 'Lawyer':
      label = 'Lawyer'
      color = 'rgb(248, 131, 121)'
      break
    case 'Attorney':
      label = 'Attorney'
      color = 'rgb(248, 131, 121)'
      break
    case 'Education':
      label = 'Education'
      color = 'rgb(248, 131, 121)'
      break
    case 'City/Government':
      label = 'City/Government'
      color = 'rgb(137, 107, 99)'
      break
    case 'Restaurant (Food & Beverage)':
    case 'Restaurant':
      label = 'Restaurant (Food & Beverage)'
      color = 'rgb(224, 188, 50)'
      break
    case 'Please Enter Industry':
    case 'Not Listed':
    case 'No Vertical Assigned':
      label = 'No Vertical Assigned'
      color = 'rgba(110, 114, 132,0.5)'
      break
    default:
      return null
  }

  return (
    <Chip
      size={size ? size : 'small'}
      label={label}
      style={{
        height: height ? height : 30,
        width: width ? width : 150, // Set a fixed width for all chips
        backgroundColor: color,
        color: theme.palette.mode === 'dark' ? 'black' : 'black',
        fontWeight: 'bold',
        borderRadius: '4px',
        textAlign: 'center',
      }}
    />
  )
}

export default RenderVertical
