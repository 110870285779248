import React from 'react'
import { Table, TableBody, Typography, Box, useTheme, TableCell, Grid } from '@mui/material'
import { EmptyPlaceholderRow, EmptyRow } from './style'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import RenderRow from '../ContractDialog/RenderRow'

const ProductRowsTable = ({ newDeal, productRows, onDragEnd, onProductChange, validationErrors, removeRow }) => {
  const theme = useTheme()
  // Calculate how many empty rows to render (3 rows is the target layout consistency)
  const emptyRowsCount = productRows && productRows.length < 3 ? 3 - productRows.length : 0
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)',
      }}
    >
      {productRows && Array.isArray(productRows) && productRows.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' isDropDisabled={!newDeal?.isDraft}>
            {provided => {
              return (
                <Grid {...provided.droppableProps} ref={provided.innerRef}>
                  {/* Render actual product rows */}
                  {productRows.map((row, index) => (
                    <Draggable key={row.rowId} draggableId={row.rowId} index={index} isDragDisabled={!newDeal?.isDraft}>
                      {(provided, snapshot) => {
                        return (
                          <RenderRow
                            key={row.rowId}
                            row={row}
                            index={index}
                            handleProductChange={onProductChange}
                            provided={provided}
                            snapshot={snapshot}
                            handleRemoveRow={removeRow}
                            validationErrors={validationErrors}
                            type={newDeal ? newDeal.type : ''}
                            isDraft={newDeal?.isDraft}
                          />
                        )
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        // Render full "ADD SERVICE HERE" if no rows exist
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '81px', // Ensure a minimum height for the row
          }}
        >
          <EmptyRow>
            <Grid item sx={{ color: 'rgb(123, 126, 134)' }}>
              ADD SERVICE HERE
            </Grid>
          </EmptyRow>
        </Box>
      )}
    </Box>
  )
}

export default ProductRowsTable
