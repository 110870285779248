import React, { useState } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import TeamMembersAutocomplete from './TeamMembersAutocomplete'
import { useUsers } from '../../api/aws/useUsers'
import DetailsLoading from '../Loading/DetailsLoading'

const RenderOrgTeam = ({ users, organization, onRemove, onChange, onBlur, isUpdating }) => {
  const roles = ['AM', 'PM', 'DMS', 'SEO', 'BD']
  const handleSelectNewUser = (role, selectedMemberIds) => {
    console.log('role, selectedUserIds [handleSelectNewUser]', role, selectedMemberIds)
    const newUserIds = selectedMemberIds.map(user => user.id || user.user_uuid)
    onChange(role, newUserIds)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant='h6' sx={{ textAlign: 'center', mb: 2 }}>
        Team Members
      </Typography>
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mb: 2,
        }}
      >
        {roles.map(role => (
          <Grid
            key={role}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{
              p: 0.5,
              minWidth: '85%', // Ensure consistent width
            }}
          >
            <TeamMembersAutocomplete
              users={users}
              role={role}
              label={`Add ${role}`}
              defaultValue={
                organization[role]
                  ? organization[role].map(userId => users.find(user => user.id === userId)).filter(Boolean)
                  : []
              }
              onChange={newMembers => handleSelectNewUser(role, newMembers)}
              onBlur={onBlur}
              isUpdating={isUpdating}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RenderOrgTeam
