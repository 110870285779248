import React, { useState } from 'react'
import { TextField, Popover, Box, Typography } from '@mui/material'
import { StyledGridTypography } from '../../style/styleElements'

const RenderTaskName = ({ params, handleChangeName }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [inputValue, setInputValue] = useState(params.row.taskName)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    // Call update function if value has changed
    if (inputValue !== params.row.taskName && inputValue.trim() !== '') {
      handleChangeName(params.id, inputValue)
    }
    setAnchorEl(null)
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleKeyPress = event => {
    // Check for Enter or Tab key press
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault() // Prevent the default action to keep focus
      if (inputValue !== params.row.taskName && inputValue.trim() !== '') {
        handleChangeName(params.id, inputValue)
      }
      setAnchorEl(null) // Close the popover
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          flexGrow: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start', // 'left' is not a valid value for justifyContent
          height: '100%',
          textAlign: 'left', // This applies to text alignment, not the box's layout
        }}
      >
        <StyledGridTypography variant='body2'>{params.row.taskName || 'Enter Task Name'}</StyledGridTypography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyPress} autoFocus fullWidth />
      </Popover>
    </>
  )
}

export default RenderTaskName
