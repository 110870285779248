import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'

const EditIconButton = ({ onEdit }) => {
  return (
    <IconButton
      onClick={onEdit}
      sx={{
        width: '50px', // Same size as PandaIconBorder
        height: '50px',
        borderRadius: '50%',
        backgroundColor: 'rgb(42, 128, 200)',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          backgroundColor: 'rgb(33, 100, 160)',
        },
      }}
    >
      <EditIcon sx={{ fontSize: '28px' }} /> {/* Adjusted size for consistency */}
    </IconButton>
  )
}

export default EditIconButton
