import axios from 'axios'

export const fetchViewLink = async contractId => {
  const panda_access_token = localStorage.getItem('panda_access_token')
  if (!panda_access_token) {
    console.log('No Panda Access Token Found')
    return null
  }
  const authHeader =
    process.env.REACT_APP_ENV === 'laptop' ? `API-KEY ${panda_access_token}` : `Bearer ${panda_access_token}`
  try {
    const response = await axios.get(`https://api.pandadoc.com/public/v1/documents/${contractId}/download`, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
      },
      responseType: 'blob', // Return data as a Blob
    })

    // Return the Blob for direct download and iframe usage
    return response.data
  } catch (error) {
    console.error('Error fetching the document:', error)
    return null
  }
}

// import axios from 'axios'

// export const fetchViewLink = async contractId => {
//   const panda_access_token = localStorage.getItem('panda_access_token')
//   if (!panda_access_token) {
//     console.log('No Panda Access Token Found')
//     return null
//   }
//   const authHeader =
//     process.env.REACT_APP_ENV === 'laptop' ? `API-KEY ${panda_access_token}` : `Bearer ${panda_access_token}`
//   try {
//     const response = await axios.get(`https://api.pandadoc.com/public/v1/documents/${contractId}/download`, {
//       headers: {
//         Authorization: authHeader,
//         'Content-Type': 'application/json',
//       },
//       responseType: 'blob', // Ensure the response is treated as a Blob
//     })
//     console.log('RESPONSE: ', response)
//     // Create a URL for the Blob
//     const blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
//     console.log('blobUrl', blobUrl)
//     return blobUrl // Return the Blob URL for viewing
//   } catch (error) {
//     console.error('Error fetching the document:', error)
//     return null
//   }
// }
