import React, { useState, useEffect, useMemo } from 'react'
import { DataGridPro, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro'
import renderTeamTasksColumns from './renderTeamTasksColumns'
import { useTasks } from '../../api/aws/useTasks'
import { Box, Typography, useTheme } from '@mui/material'
import { useTask } from '../../context/TaskProvider/TaskProvider'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { useUsers } from '../../api/aws/useUsers'
import { useDeals } from '../../api/aws/useDeals'
import CustomDataGrid from './CustomDataGrid'
import { StyledDataGrid } from '../DataGrid/style'
import { gridContainerStyles } from '../Contacts/style'
import { useUpdateTask } from '../../api/aws/useUpdateTask'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { dataGridStyles } from './style'

const TeamTasksPage = () => {
  const { deals, isDealsLoading, isDealsError } = useDeals(true, ['teamtasks', 'deals'])
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['teamtasks', 'users'])
  const { tasks: fetchedTasks, isTasksLoading, isTasksError } = useTasks(true, ['tasks'])
  const [tasks, setTasks] = useState(fetchedTasks ?? [])
  const { createMessagePayload, addNotification } = useNotify()
  const [selectionModel, setSelectionModel] = useState([])

  const [selectedTaskIds, setSelectedTaskIds] = useState([])
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false)
  const [selectedTaskData, setSelectedTaskData] = useState(null)
  const { mutate: updateTask } = useUpdateTask()
  const { showSnackbar } = useSnackbarContext()
  const [filterModel, setFilterModel] = useState({
    items: [],
  })
  const theme = useTheme()
  const { dispatch, handleTaskUpdate } = useTask()
  useEffect(() => {
    const updateFilterModelForDeals = () => {
      const newFilterItems = filterModel.items.filter(item => {
        // Assuming item.columnField and item.value can determine a project-related filter
        // Adjust the logic here based on how your project filters are structured
        if (item.columnField === 'projectName') {
          return deals.some(deal => deal.name === item.value)
        }
        return true // Keep the item if it's not project-related
      })

      if (newFilterItems.length !== filterModel.items.length) {
        setFilterModel({ ...filterModel, items: newFilterItems })
      }
    }

    updateFilterModelForDeals()
  }, [deals, filterModel])
  useEffect(() => {
    if (fetchedTasks) {
      setTasks(fetchedTasks)
    }
  }, [fetchedTasks])
  const handleChangeMasterStatus = (taskIds, newValue, isMassUpdate = false) => {
    handleTaskUpdate(tasks, taskIds, { masterStatus: newValue }, 'masterStatus', isMassUpdate)
  }

  const handleChangeProject = (taskIds, newValue, isMassUpdate = false) => {
    handleTaskUpdate(tasks, taskIds, { project: newValue }, 'projectChange', isMassUpdate)
  }

  const handleChangeTaskType = (taskIds, newValue, isMassUpdate = false) => {
    handleTaskUpdate(tasks, taskIds, { taskType: newValue }, 'taskTypeChange', isMassUpdate)
  }

  const handleChangeUserRole = (taskIds, newRoleValue, userIdsToNotify, roleType, isMassUpdate = false) => {
    console.log('TaskIds: ', taskIds)
    console.log('User Ids To Notify: ', userIdsToNotify)
    console.log('New Role Value: ', newRoleValue)
    const updateProperties = { [roleType]: newRoleValue }
    console.log('Update Properties: ', updateProperties)
    const postUpdateCallback = updatedTask => {
      console.log('[POSTUPDATECALLBACK] updatedTask: ', updatedTask)
      if (updatedTask) {
        try {
          const payload = createMessagePayload(
            userIdsToNotify,
            'task',
            'warning',
            'Assigned New Task',
            `Assigned to new task ${updatedTask?.taskName}`,
            'tasks',
            updatedTask?.taskName,
            updatedTask?.task_uuid,
            '',
            `https://localhost:3000/teamtasks`
          )
          addNotification(payload)
        } catch (error) {
          console.error('error creating new task notification')
        }
      }
    }

    handleTaskUpdate(tasks, taskIds, updateProperties, `${roleType}RoleChange`, isMassUpdate, postUpdateCallback)
  }

  const handleDateChange = (taskIds, newValue, dateField, isMassUpdate = false) => {
    handleTaskUpdate(tasks, taskIds, { [dateField]: newValue }, 'dateChange', isMassUpdate)
  }

  const handleHighPrioChange = (taskIds, isHighPrio, isMassUpdate = false) => {
    handleTaskUpdate(tasks, taskIds, { highPrio: isHighPrio }, 'highPrioChange', isMassUpdate)
  }
  const handleChangeName = (taskId, newName) => {
    console.log('handleNameChange: ', taskId, newName)
    const updatedTasks = tasks.map(task => (task.task_uuid === taskId ? { ...task, taskName: newName } : task))

    console.log('Change name:', taskId, newName) // Log to check the received parameters

    // Dispatch the action with the 'type' property
    dispatch({ type: 'SET_TASKS', payload: updatedTasks })

    // Additionally, call handleTaskUpdate if needed
    handleTaskUpdate(tasks, taskId, { taskName: newName }, 'nameChange')
  }
  const handleTaskClick = (event, taskData) => {
    showTaskDrawer(taskData)
  }
  const showTaskDrawer = task => {
    console.log('showTaskDrawer Team Tasks: ', task)
    setSelectedTaskData(task)
    setTaskDrawerOpen(true)
  }

  const closeTaskDrawer = () => {
    setTaskDrawerOpen(false)
    setSelectedTaskData(null)
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  const handleSingleUpdate = async (taskId, updatedRow, updatedData) => {
    try {
      // Call the mutation with the task ID and the updated data
      updateTask(
        { taskId, taskData: updatedData },
        {
          onSuccess: () => {
            showSnackbar(
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                  {updatedRow?.name || ''}
                </Typography>
                <Typography>Updated successfully</Typography>
              </Box>,
              'success'
            )
            console.log('Successfully updated task')
          },
          onError: error => {
            console.error('Error updating task', error)
          },
        }
      )

      // Optionally show a success message or perform other actions
      console.log(`Deal ${taskId} updated successfully with`, updatedData)
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error(`Failed to update task ${taskId}:`, error)
    }
  }
  const columns = renderTeamTasksColumns(
    theme,
    users,
    selectedTaskIds,
    handleChangeUserRole,
    handleChangeProject,
    handleChangeName,
    handleChangeTaskType,
    handleDateChange,
    handleHighPrioChange,
    handleChangeMasterStatus,
    handleTaskClick,
    showTaskDrawer,
    closeTaskDrawer
  )

  return (
    <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
      <DataGridPro
        autoHeight
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
        }}
        rows={tasks}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        processRowUpdate={async (updatedRow, oldRow) => {
          const updatedData = {}
          if (updatedRow.taskType !== oldRow.taskType) {
            // Call the centralized update function
            await handleSingleUpdate(updatedRow.task_uuid, updatedRow, {
              taskType: updatedRow.taskType,
            })
          } else if (updatedRow.masterStatus !== oldRow.masterStatus) {
            await handleSingleUpdate(updatedRow.task_uuid, updatedRow, {
              masterStatus: updatedRow.masterStatus,
            })
          }

          // Optimistically update the local state
          setTasks(prevTasks =>
            prevTasks.map(task => (task.task_uuid === updatedRow.task_uuid ? { ...task, ...updatedData } : task))
          )

          return updatedRow // Return updated row to the grid
        }}
        onProcessRowUpdateError={error => {
          console.error('Error updating row:', error)
        }}
        getRowId={row => row.task_uuid}
        checkboxSelection
        disableRowSelectionOnClick
        selectionModel={selectedTaskIds}
        onRowSelectionModelChange={newSelectionModel => {
          console.log('newSelectionModel', newSelectionModel)
          setSelectedTaskIds(newSelectionModel)
        }}
        filterModel={filterModel}
        onFilterModelChange={model => setFilterModel(model)}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={dataGridStyles(theme)}
      />
    </Box>
  )
}

export default TeamTasksPage
