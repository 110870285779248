import React, { useState } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  Tooltip,
  Box,
  Typography,
} from '@mui/material'
import NewRenderCell from '../Deals/NewRenderCell'
import { columns, csaColumns, ioColumns } from './columns' // Import your columns configuration
import ServiceDetails from './ServiceDetails'

const RenderRow = ({
  row,
  index,
  handleProductChange,
  provided,
  snapshot,
  handleRemoveRow,
  validationErrors,
  type,
  isDraft,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)
  const csaTypes = ['CSA', 'RCSA']
  const ioType = ['IO']
  const rowColumns = type && csaTypes.includes(type) ? csaColumns : ioType.includes(type) ? ioColumns : columns
  // Dynamically filter columns for always visible fields and modal fields
  const alwaysVisibleFields = rowColumns.filter(column =>
    ['productName', 'description', 'price', 'deleteButton'].includes(column.id)
  )
  const fieldsInModal = rowColumns.filter(
    column => !['productName', 'description', 'price', 'deleteButton'].includes(column.id)
  )
  const allFields = rowColumns.filter(column =>
    [
      'productName',
      'description',
      'price',
      'billedPrice',
      'margin',
      'mediaSpend',
      'dateRange',
      'payer',
      'accountUsed',
      'grossProfit',
      'deleteButton',
    ].includes(column.id)
  )
  return (
    <>
      <Grid
        container
        key={'cell-container'}
        spacing={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: snapshot.isDragging ? theme.palette.action.hover : theme.palette.background.default,
          p: 2,
          borderRadius: '8px',
        }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {/* Render always visible fields */}
        {isSmallScreen ? (
          <>
            {alwaysVisibleFields.map(column => (
              <Grid
                item
                xs={column?.xs}
                sm={column?.sm}
                md={column?.md}
                lg={column?.md}
                xl={column?.xl}
                key={column.id}
              >
                <NewRenderCell
                  row={row}
                  column={column}
                  index={index}
                  handleProductChange={handleProductChange}
                  validationErrors={validationErrors}
                  handleRemoveRow={handleRemoveRow}
                  snapshot={snapshot}
                  isDraft={isDraft}
                />
              </Grid>
            ))}

            {/* Show button to open modal for additional fields on smaller screens */}

            <Grid item xs={2} sm={2} md={2}>
              <Tooltip
                title={
                  <Box
                    sx={{
                      // p: 2, // Consistent padding on all sides
                      borderRadius: 2, // Rounded corners for better aesthetics
                      wordWrap: 'break-word',
                    }}
                  >
                    {fieldsInModal.map(column => (
                      <ServiceDetails key={column.id} column={column} value={row[column?.id]} />
                    ))}
                  </Box>
                }
                arrow
                placement='bottom-start'
                PopperProps={{
                  sx: {
                    width: '300px',
                  },
                }}
              >
                <Button variant='outlined' onClick={handleOpenModal}>
                  Edit
                </Button>
              </Tooltip>
            </Grid>
          </>
        ) : (
          <>
            {rowColumns.map(column => {
              const cellValue = row?.[column.id]
              const startDate = row?.startDate
              const endDate = row?.endDate
              const paidSelectLabels = ['Payer', 'Account Used']
              const totalsLabels = ['Total', 'Gross Profit', 'Media Spend']
              if (column.id === 'rowId') return null
              return (
                <React.Fragment key={column?.id}>
                  {totalsLabels?.includes(column?.label) && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.label === 'Margin' && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.label === 'Price' && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.label === 'Billed Price' && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.label === 'Qty' && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.label === 'Date Range' && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}

                  {paidSelectLabels?.includes(column.label) && (
                    <Grid
                      item
                      key={column?.id}
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.id === 'description' && (
                    <Grid
                      key={column?.id}
                      item
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.type === 'autocomplete' && (
                    <Grid
                      key={column?.id}
                      item
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                  {column?.type === 'component' && (
                    <Grid
                      key={column?.id}
                      item
                      xs={column?.xs}
                      sm={column?.sm}
                      md={column?.md}
                      lg={column?.xl}
                      xl={column?.xl}
                    >
                      <NewRenderCell
                        row={row}
                        column={column}
                        index={index}
                        handleProductChange={handleProductChange}
                        validationErrors={validationErrors}
                        handleRemoveRow={handleRemoveRow}
                        snapshot={snapshot}
                        isDraft={isDraft}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </Grid>
      {/* Modal for additional fields */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth='sm'>
        <DialogTitle>Edit Additional Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {fieldsInModal.map(column => (
              <Grid item xs={12} key={column.id}>
                <NewRenderCell
                  row={row}
                  column={column}
                  index={index}
                  handleProductChange={handleProductChange}
                  validationErrors={validationErrors}
                  handleRemoveRow={handleRemoveRow}
                  snapshot={snapshot}
                  isDraft={isDraft}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RenderRow

// import React from 'react'
// import { useTheme, useMediaQuery, Grid } from '@mui/material'
// import { columns, csaColumns, ioColumns } from './columns'
// import NewRenderCell from '../Deals/NewRenderCell'

// const RenderRow = ({
//   row,
//   index,
//   handleProductChange,
//   provided,
//   snapshot,
//   handleRemoveRow,
//   validationErrors,
//   type,
//   isDraft,
// }) => {
//   const theme = useTheme()
//   const tableBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(255,255,255,1)'
//   const csaTypes = ['CSA', 'RCSA']
//   const ioType = ['IO']
//   const rowColumns = type && csaTypes.includes(type) ? csaColumns : ioType.includes(type) ? ioColumns : columns
//   const isExtraLargeScreen = useMediaQuery(theme => theme.breakpoints.down('xl'))
//   const isLargeScreen = useMediaQuery(theme => theme.breakpoints.down('lg'))
//   const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
// const totalsLabels = ['Total', 'Gross Profit', 'Media Spend']
//   return (
//     <Grid
//       container
//       key={'cell-container'}
//       spacing={1}
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         backgroundColor:
//           snapshot.isDragging && theme.palette.mode === 'dark'
//             ? 'rgba(30, 39, 53,0.4)'
//             : snapshot.isDragging && theme.palette.mode === 'light'
//             ? 'rgba(255,255,255,1)'
//             : tableBgColor,
//         p: 2,
//         borderRadius: '8px', // Optional: Adds rounded corners
//         transition: 'box-shadow 0.2s ease, border 0.2s ease', // Smooth effect on dragging
//       }}
//       ref={provided.innerRef}
//       {...provided.draggableProps}
//       {...provided.dragHandleProps}
//     >
// {rowColumns.map(column => {
//   const cellValue = row?.[column.id]
//   const startDate = row?.startDate
//   const endDate = row?.endDate
//   const paidSelectLabels = ['Payer', 'Account Used']
//   if (column.id === 'rowId') return null
//   return (
// <React.Fragment key={column?.id}>
//   {totalsLabels?.includes(column?.label) && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.label === 'Margin' && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.label === 'Price' && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.label === 'Billed Price' && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.label === 'Qty' && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.label === 'Date Range' && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}

//   {paidSelectLabels?.includes(column.label) && (
//     <Grid
//       item
//       key={column?.id}
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.id === 'description' && (
//     <Grid
//       key={column?.id}
//       item
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.type === 'autocomplete' && (
//     <Grid
//       key={column?.id}
//       item
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
//   {column?.type === 'component' && (
//     <Grid
//       key={column?.id}
//       item
//       xs={column?.xs}
//       sm={column?.sm}
//       md={column?.md}
//       lg={column?.xl}
//       xl={column?.xl}
//     >
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   )}
// </React.Fragment>
//   )
// })}
//     </Grid>
//   )
// }

// export default RenderRow
// <>
//   {allFields.map(column => (
//     <Grid item xs={6} sm={4} md={2} key={column.id}>
//       <NewRenderCell
//         row={row}
//         column={column}
//         index={index}
//         handleProductChange={handleProductChange}
//         validationErrors={validationErrors}
//         handleRemoveRow={handleRemoveRow}
//         snapshot={snapshot}
//         isDraft={isDraft}
//       />
//     </Grid>
//   ))}
// </>
