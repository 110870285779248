import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Stack,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import {
  DialogIcon,
  dialogStyles,
  personIconStyles,
  dialogTitleStyles,
  inputStyles,
  boxStyles,
  dialogContentStyles,
} from './style'
import OrganizationsAutocomplete from '../Organizations/OrgAutocomplete'
import UsersAutocomplete from '../Users/UsersAutocomplete'
import { useCreateContact } from '../../api/aws/useCreateContact'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import CreateContactHeader from './CreateContactHeader'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useUsers } from '../../api/aws/useUsers'

const formatPhoneNumber = phoneNumber => {
  // Remove all non-digit characters
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')

  // Remove the leading '1' if present
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    cleaned = cleaned.slice(1)
  }

  // Match and format the number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}`
  }

  return phoneNumber // Return original if it doesn't match expected format
}

const CreateContact = ({ open, onClose, onSaveContact }) => {
  const { mutate: createContact } = useCreateContact()
  const { showSnackbar } = useSnackbarContext()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['createContact', 'users'])

  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['createContact', 'organizations'])
  const [validationErrors, setValidationErrors] = useState({
    organizations: false,
    email: false,
  })
  const theme = useTheme()
  const initialState = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    officePhone: '',
    organizations: [],
    title: '',
    createdBy: {},
    owner: [],
  }
  const [newContact, setNewContact] = useState({
    ...initialState,
    createdBy: {
      id: activeUser?.user?.id,
      name: activeUser?.user?.real_name,
      firstName: activeUser?.user?.profile?.first_name,
      lastName: activeUser?.user?.profile?.last_name,
      image: activeUser?.user?.profile?.image_512,
    },
    owner: [activeUser?.user?.id], // Set the owner to the active user
  })

  const handleChange = (key, event) => {
    let { value } = event.target

    // Apply phone number formatting if the key is 'phone' or 'officePhone'
    if (key === 'phone' || key === 'officePhone') {
      value = formatPhoneNumber(value)
    }

    setNewContact(prev => {
      const updatedContact = { ...prev, [key]: value }

      // Update the name field based on firstName and lastName
      if (key === 'firstName' || key === 'lastName') {
        updatedContact.name = `${updatedContact.firstName} ${updatedContact.lastName}`.trim()
      }

      return updatedContact
    })
  }

  const handleSave = () => {
    // Basic email validation
    if (!/\S+@\S+\.\S+/.test(newContact.email)) {
      setValidationErrors(prev => ({ ...prev, email: true }))
      return
    }

    if (!newContact.name) {
      // Set the name if it is not already set
      newContact.name = `${newContact.firstName} ${newContact.lastName}`
    }

    console.log('Creating new contact: ', newContact)
    createContact(newContact, {
      onSuccess: data => {
        console.log('data: ', data)
        showSnackbar('New Contact Created!', 'success')
        onClose()
      },
    })

    setNewContact({ ...initialState })
  }

  const handleAddOrganization = organization => {
    console.log('added organization: ', organization)
    setNewContact(prev => ({
      ...prev,
      organizations: Array.isArray(organization)
        ? organization.map(item => item.org_uuid || item.id)
        : organization
        ? [organization.org_uuid || organization.id]
        : [],
    }))
  }

  const handleAddOwner = owner => {
    console.log('added owner: ', owner)
    setNewContact(prev => ({
      ...prev,
      owner: Array.isArray(owner)
        ? owner.map(item => item.users_uuid || item.id)
        : owner
        ? [owner.users_uuid || owner.id]
        : [],
    }))
  }
  if (isUsersLoading) {
    return <DetailsLoading />
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' PaperProps={{ sx: dialogStyles(theme) }}>
      <CreateContactHeader context='Create Contacts' />
      {isOrganizationsLoading && <DetailsLoading />}
      <DialogContent sx={dialogContentStyles}>
        <Stack spacing={0}>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={6}>
              <TextField
                label='First Name'
                type='text'
                fullWidth
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: inputStyles(theme) }}
                value={newContact.firstName}
                onChange={e => handleChange('firstName', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Last Name'
                type='text'
                fullWidth
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: inputStyles(theme) }}
                value={newContact.lastName}
                onChange={e => handleChange('lastName', e)}
              />
            </Grid>
          </Grid>
          <UsersAutocomplete
            users={users}
            label={'Owner'}
            onChange={handleAddOwner}
            defaultOwner={activeUser?.user?.id}
          />
          <OrganizationsAutocomplete
            organizations={organizations}
            label={'Select Organization'}
            addOrganization={handleAddOrganization}
            error={validationErrors.organizations}
            helperText={validationErrors.organizations && 'Organization is required'}
          />

          <TextField
            label='Email'
            type='email'
            fullWidth
            InputLabelProps={{ style: { color: '#aaa' } }}
            InputProps={{ style: inputStyles(theme) }}
            value={newContact.email}
            onChange={e => {
              setValidationErrors(prev => ({ ...prev, email: false }))
              handleChange('email', e)
            }}
            error={validationErrors.email}
            helperText={validationErrors.email && 'Please enter a valid email address'}
          />
          <TextField
            label='Phone'
            type='tel'
            fullWidth
            InputLabelProps={{ style: { color: '#aaa' } }}
            InputProps={{ style: inputStyles(theme) }}
            value={newContact.phone}
            onChange={e => handleChange('phone', e)}
          />
          <TextField
            label='Office Phone'
            type='tel'
            fullWidth
            InputLabelProps={{ style: { color: '#aaa' } }}
            InputProps={{ style: inputStyles(theme) }}
            value={newContact.officePhone}
            onChange={e => handleChange('officePhone', e)}
          />

          {Object.entries(newContact).map(([key, value]) => {
            if (
              key !== 'name' &&
              key !== 'firstName' &&
              key !== 'lastName' &&
              key !== 'createdBy' &&
              key !== 'owner' &&
              key !== 'organizations' &&
              key !== 'email' &&
              key !== 'phone' &&
              key !== 'officePhone'
            ) {
              return (
                <TextField
                  key={key}
                  label={key[0].toUpperCase() + key.slice(1)}
                  type='text'
                  fullWidth
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: inputStyles(theme) }}
                  value={value}
                  onChange={e => handleChange(key, e)}
                />
              )
            }
            return null
          })}

          {newContact.createdBy && (
            <Typography variant='body2' color='textSecondary'>
              Created By: {newContact.createdBy.name}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSave} color='primary' variant='contained'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateContact
