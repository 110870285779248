import { useQueries } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const fetchById = async (endpoint, id) => {
  const { data } = await axios.get(`${baseUrl}${endpoint}/${id}`)
  return data
}

export const useGetQueryByIds = (endpoint, tableName, ids = [], queryKey, options = {}) => {
  // console.log('endpoint, tableName, ids = [], queryKey, options = {}', endpoint, tableName, ids, queryKey, options)
  // Ensure ids is an array
  const validIds = Array.isArray(ids) ? ids.filter(id => !!id) : []
  // console.log('[useGetQueryByIds] Valid IDs:', validIds) // This should show only valid IDs

  // Generate query configurations for useQueries
  const queries = validIds.map((id, index) => ({
    queryKey: [`${queryKey}-${index}` ?? endpoint, id],
    queryFn: () => fetchById(endpoint, id),
    enabled: !!id, // Ensure the query only runs if the ID is valid
    staleTime: options.staleTime || Infinity, // Default to Infinity if not provided
    cacheTime: options.cacheTime || 1000 * 60 * 10, // Default to 10 minutes if not provided
    onSuccess: options.onSuccess || (data => console.log('Query success:', [endpoint, id], data)),
    onError: options.onError || (error => console.error('Query error:', [endpoint, id], error)),
    onSettled: options.onSettled || (() => console.log('Query settled:', [endpoint, id])),
  }))

  return useQueries({ queries })
}
/*

[SCAN] GRO_NOTIFY Total items:  167 Total capacity used:  9.5
[GET] DEALS ConsumedCapacity  4 key  4749b02e-f6e9-4cb3-9be3-d52680bfc46c
[GET] STRATEGY ConsumedCapacity  1 key  4749b02e-f6e9-4cb3-9be3-d52680bfc46c
[getDynamoDB] Request already queued: deals:deal_uuid
[GET] USERS ConsumedCapacity  0.5 key  U062JDP8R5X
[Batch Processor] Batch processing completed.
[GET] DEALS ConsumedCapacity  4 key  4749b02e-f6e9-4cb3-9be3-d52680bfc46c
requestQueue { 'organizations:org_uuid': 'c7982d02-753c-4deb-94fc-8726729402eb' }
[Batch Processor] Batch processing completed.
[GET] ORGANIZATIONS ConsumedCapacity  0.5 key  c7982d02-753c-4deb-94fc-8726729402eb
No changes detected; skipping update.
requestQueue {
  'users:user_uuid': 'U062JDP8R5X',
  'contacts:contacts_uuid': 'b0bd49a9-51b0-4af6-8a04-0e0fb2998d2f'
}
[Batch Processor] Batch processing completed.
[GET] USERS ConsumedCapacity  0.5 key  U062JDP8R5X
[GET] CONTACTS ConsumedCapacity  0.5 key  b0bd49a9-51b0-4af6-8a04-0e0fb2998d2f
requestQueue { 'users:user_uuid': 'U062JDP8R5X' }
[Batch Processor] Batch processing completed.
[GET] USERS ConsumedCapacity  0.5 key  U062JDP8R5X






[SCAN] GRO_NOTIFY Total items:  167 Total capacity used:  9.5
[GET] DEALS ConsumedCapacity  4 key  4749b02e-f6e9-4cb3-9be3-d52680bfc46c
requestQueue { 'organizations:org_uuid': 'c7982d02-753c-4deb-94fc-8726729402eb' }
[Batch Processor] Batch processing completed.
[GET] ORGANIZATIONS ConsumedCapacity  0.5 key  c7982d02-753c-4deb-94fc-8726729402eb
No changes detected; skipping update.
requestQueue {
  'users:user_uuid': 'U062JDP8R5X',
  'contacts:contacts_uuid': 'b0bd49a9-51b0-4af6-8a04-0e0fb2998d2f'
}
[Batch Processor] Batch processing completed.
[GET] USERS ConsumedCapacity  0.5 key  U062JDP8R5X
[GET] CONTACTS ConsumedCapacity  0.5 key  b0bd49a9-51b0-4af6-8a04-0e0fb2998d2f
requestQueue { 'users:user_uuid': 'U062JDP8R5X' }
[Batch Processor] Batch processing completed.
[GET] USERS ConsumedCapacity  0.5 key  U062JDP8R5X








*/
