import React from 'react'
import { Box, Typography, Grid, useTheme } from '@mui/material'
import { InfoBox, InfoRow, InfoLabel, InfoValueWrapper, ContactTextField, Underline, CityStateZipRow } from './style'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import AssignCompaniesAutocomplete from '../EditModeComponents/AssignCompaniesAutocomplete'
import SaveIconButton from '../ui/save-icon-button'
import { useOrganizationsById } from '../../api/customHooks/useOrganizationsById'

const EditContactsInfo = ({
  contact,
  details,
  handleChange,
  handleBlur,
  handleCompanyChange,
  handleObjectBlur,
  handleSave,
}) => {
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const typographyColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  // Ensure default values for all input fields
  const getDefaultValue = value => (value === null || value === undefined ? '' : value)
  const { organizations: newOrganizations, isOrganizationsByIdLoading } = useOrganizationsById(
    details?.organizations || []
  )
  return (
    <InfoBox sx={{ backgroundColor: gridBgColor }}>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <SaveIconButton color='secondary' variant='contained' onSave={handleSave}>
          Save Contact
        </SaveIconButton>
      </Box>
      <Typography sx={{ color: typographyColor }} variant='h6' gutterBottom>
        Information
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <InfoRow>
            <InfoLabel>Contact Owner</InfoLabel>
            <InfoValueWrapper>
              <AssignOwnerAutocomplete
                userId={contact && Array.isArray(contact.owner) && contact.owner[0] ? contact.owner[0] : []}
                label='Owner'
                onChange={handleCompanyChange}
                context={'owner'}
                onBlur={handleObjectBlur}
                multiple={false} // Single select for owner
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <CityStateZipRow>
            <Box sx={{ flex: 1, mx: 1 }}>
              <InfoValueWrapper>
                <InfoLabel>First Name</InfoLabel>
                <ContactTextField
                  placeholder='No Name Assigned'
                  label=''
                  name='firstName'
                  value={getDefaultValue(details?.firstName || details?.first_name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                <Underline />
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1, mx: 1 }}>
              <InfoValueWrapper>
                <InfoLabel>Middle Name</InfoLabel>
                <ContactTextField
                  placeholder='No Name Assigned'
                  label=''
                  name='middleName'
                  value={getDefaultValue(details?.middleName || '')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                <Underline />
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1, mx: 1 }}>
              <InfoValueWrapper>
                <InfoLabel>Last Name</InfoLabel>
                <ContactTextField
                  placeholder='No Name Assigned'
                  label=''
                  name='lastName'
                  value={getDefaultValue(details?.lastName || details?.last_name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
                <Underline />
              </InfoValueWrapper>
            </Box>
          </CityStateZipRow>
          <InfoRow>
            <InfoLabel>Name</InfoLabel>
            <InfoValueWrapper>
              <ContactTextField
                placeholder='No Name Assigned'
                label=''
                name='name'
                value={getDefaultValue(details?.name)}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
                fullWidth
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Title</InfoLabel>
            <InfoValueWrapper>
              <ContactTextField
                placeholder='No Title Assigned'
                label=''
                name='title'
                value={getDefaultValue(details.title)}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </InfoValueWrapper>
            <Underline />
          </InfoRow>
          <InfoRow>
            <InfoLabel>Organizations</InfoLabel>
            <InfoValueWrapper>
              <AssignCompaniesAutocomplete
                label=''
                value={newOrganizations}
                onChange={handleCompanyChange}
                context={'organizations'}
                onBlur={handleObjectBlur}
              />

              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Summary</InfoLabel>
            <ContactTextField
              label=''
              name='summary'
              value={getDefaultValue(details.summary)}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </InfoRow>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoRow>
            <InfoLabel>Mobile Phone</InfoLabel>
            <ContactTextField
              label=''
              name='phone'
              value={getDefaultValue(details.phone)}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </InfoRow>
          <InfoRow>
            <InfoLabel>Office Phone</InfoLabel>
            {/* <InfoValueWrapper>
              <Typography href={`tel:${details.officePhone}`}>{contact.officePhone || 'N/A'}</Typography>
              <Underline />
            </InfoValueWrapper> */}
            <ContactTextField
              label=''
              name='officePhone'
              value={getDefaultValue(details.officePhone)}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </InfoRow>
          <InfoRow>
            <InfoLabel>Email</InfoLabel>
            <InfoValueWrapper>
              {/* <Typography href={`mailto:${contact.email}`}>{contact.email || 'N/A'}</Typography> */}
              <ContactTextField
                label=''
                type='email'
                name='email'
                value={getDefaultValue(details.email)}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Billing Email</InfoLabel>
            <InfoValueWrapper>
              {/* <Typography href={`mailto:${contact.email}`}>{contact.email || 'N/A'}</Typography> */}
              <ContactTextField
                label=''
                type='email'
                name='billingEmail'
                value={getDefaultValue(details.billingEmail)}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Linkedin</InfoLabel>
            <InfoValueWrapper>
              {/* <Typography href={`mailto:${contact.email}`}>{contact.email || 'N/A'}</Typography> */}
              <ContactTextField
                label=''
                type='url'
                name='linkedinProfile'
                value={getDefaultValue(details.linkedinProfile)}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Street</InfoLabel>
            <ContactTextField
              label=''
              name='street'
              value={getDefaultValue(details.street)}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </InfoRow>
          <CityStateZipRow>
            <Box sx={{ flex: 2 }}>
              <InfoLabel>City</InfoLabel>
              <InfoValueWrapper>
                <ContactTextField
                  label=''
                  name='city'
                  value={getDefaultValue(details.city)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1, mx: 2 }}>
              <InfoLabel>State</InfoLabel>
              <InfoValueWrapper>
                <ContactTextField
                  label=''
                  name='state'
                  value={getDefaultValue(details.state)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Zip</InfoLabel>
              <InfoValueWrapper>
                <ContactTextField
                  label=''
                  name='zip'
                  value={getDefaultValue(details.zip)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </InfoValueWrapper>
            </Box>
            <Underline />
          </CityStateZipRow>
        </Grid>
      </Grid>
    </InfoBox>
  )
}

export default EditContactsInfo
