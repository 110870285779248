import React from 'react'
import { Box, Typography, IconButton, useTheme } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
const boxCopyStyles = {
  display: 'grid',
  gridTemplateColumns: '0.9fr 3fr 0.3fr auto', // Adjust the ratios as needed
  alignItems: 'center',
  columnGap: '8px', // Space between columns
}
const ContactLinkItem = ({ label, onCopy, isCopied, linkContent, imageUrl }) => {
  const theme = useTheme()
  return (
    <Box sx={boxCopyStyles}>
      <Typography id={`${label}-link-label`} variant='h6' component='h2'>
        {label}
      </Typography>
      <Typography
        sx={{
          fontStyle: 'italic',
          fontSize: 14,
          color: theme.palette.text.highlight,
          textAlign: 'left', // Align text left or right as needed
          whiteSpace: 'nowrap', // Prevent wrapping
          overflow: 'hidden', // Add ellipsis for long links
          textOverflow: 'ellipsis',
        }}
        id='contact-links-info-typography'
        variant='h6'
        component='h2'
      >
        {isCopied ? 'Contact Link copied to clipboard!' : linkContent}
      </Typography>
      <IconButton
        onClick={() => onCopy(linkContent)}
        sx={{
          alignSelf: 'flex-end',
          padding: '4px',
          color: theme.palette.text.secondary,
          height: '45px',
          width: '45px',
        }}
        size='small'
      >
        <ContentCopyIcon fontSize='medium' />
      </IconButton>
    </Box>
  )
}

export default ContactLinkItem
