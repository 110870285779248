import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'
export const getQuery = async (endpoint, tableName, filters = {}) => {
  try {
    const url = `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: { table: tableName, ...filters }, // Pass filters for PK, SK, or EntityType
    })
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}
export const getOrganizations = async (endpoint, tableName, filters = {}) => {
  try {
    const url = `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: { table: tableName, ...filters }, // Pass filters for PK, SK, or EntityType
    })
    return data?.data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useGetQuery = (endpoint, tableName, filters = {}) => {
  return useQuery({
    queryKey: [endpoint, filters], // Include filters in the query key
    queryFn: () => getQuery(endpoint, tableName, filters),
    // staleTime: Infinity, // Default to Infinity if not provided
    // cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    // refetchOnWindowFocus: false, // Avoid refetching on window focus
  })
}
