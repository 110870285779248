import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTasks } from '../../api/aws/useTasks'
import { useBatchUpdateTasks } from '../../api/aws/tasks/useBatchUpdateTasks'
import { useUpdateTask } from '../../api/aws/useUpdateTask'

// Initial state
export const initialState = {
  task: {
    taskName: '',
    type: '', // digital/creative
    taskType: null,
    masterStatus: null,
    startDate: null,
    dueDate: null,
    doneDate: null,
    AM: [],
    DMS: [],
    PM: [],
    MIA: [],
    QA: [],
    SEO: [],
    description: '',
  },
  creativeTask: {
    taskName: '',
    creativeType: null,
    creativeStatus: null,
    masterStatus: null,
    startDate: null,
    dueDate: null,
    doneDate: null,
    creativeLead: [],
    AM: [],
    DMS: [],
    PM: [],
    MIA: [],
    QA: [],
    SEO: [],
    description: '',
  },
  tasks: [],
  hasChanged: false,
  isLoading: false,
  isUpdatingTask: false,
  isDeletingTask: false,
}

// Create context
const TaskContext = createContext()

// Reducer function
export const taskReducer = (state, action) => {
  console.log(`[Task Reducer] State: ${state}, Action: ${action.type}`)
  switch (action.type) {
    case 'UPDATE_TASK_FIELD':
      console.log(
        'Updating task field:',
        action.fieldName,
        'with value:',
        action.value
      )
      if (action.fieldName.includes('.')) {
        const [parentKey, childKey] = action.fieldName.split('.')
        return {
          ...state,
          task: {
            ...state.task,
            [parentKey]: {
              ...state.task[parentKey],
              [childKey]: action.value,
            },
          },
          hasChanged: true,
        }
      } else {
        console.log(
          'Updating field:',
          action.fieldName,
          'with value:',
          action.value
        )
        return {
          ...state,
          task: { ...state.task, [action.fieldName]: action.value },
          hasChanged: true,
        }
      }

    case 'RESET_TASK_FORM':
      return {
        ...state,
        task: initialState.task,
        hasChanged: false,
      }
    case 'OPTIMISTIC_UPDATE_TASK':
      // Update the projects array with the new project data optimistically
      const updatedTask = state.tasks.map((task) =>
        task.gprojects_uuid === action.payload.task_uuid
          ? { ...task, ...action.payload }
          : task
      )
      console.log(
        'UPDATED TASK IN OPTIMISTIC_UPDATE_TASK ACTION: ',
        updatedTask
      )
      return { ...state, tasks: updatedTask, hasChanged: true }
    case 'OPTIMISTIC_UPDATE_TASKS':
      console.log('OPTIMISTIC_UPDATE_TASKS TRIGGERED')
      // Assuming action.payload contains an array of tasks with updated fields
      const optimisticTasks = state.tasks.map((task) => {
        const update = action.payload.find(
          (update) => update.task_uuid === task.task_uuid
        )
        return update ? { ...task, ...update } : task
      })
      return { ...state, tasks: optimisticTasks, hasChanged: true }
    case 'SET_TASKS':
      return {
        ...state,
        tasks: action.payload,
        isLoading: false,
      }
    // Add a new action type
    case 'CLEAR_SELECTION':
      return {
        ...state,
        selectedTaskIds: [], // Assuming this is the state holding the IDs of selected tasks
      }

    ////////////////////////////////////////////////////////
    default:
      return state
  }
}

const TaskProvider = ({ children }) => {
  const [state, dispatch] = useReducer(taskReducer, initialState)
  const {
    data: awsTasks,
    isLoading: isTasksLoading,
    isError: isTasksError,
    error: tasksError, // Assuming you have an error message or object
  } = useTasks()

  const { mutate: updateTask } = useUpdateTask()
  // const { mutate: updateTasks } = useUpdateTasks()
  // Hook to perform batch updates
  const { mutate: batchUpdateTasks } = useBatchUpdateTasks()

  // Function to handle batch updates, adapted for use with context

  // Wrap the mutate function to include your application logic
  const handleUpdateTask = (taskId, updatedData) =>
    updateTask(taskId, updatedData)
  const handleUpdateTasks = (tasksData) => batchUpdateTasks(tasksData)
  // const handleUpdateTasks = (tasksData, callbacks) => {
  //   updateTasks(tasksData, callbacks)
  // }
  useEffect(() => {
    if (awsTasks) {
      const tasksWithDisplayId = awsTasks.map((task, index) => ({
        ...task,
        displayId: index + 1, // Generate a sequential ID starting from 1
      }))
      dispatch({ type: 'SET_TASKS', payload: tasksWithDisplayId })
    }
  }, [awsTasks, dispatch])

  ///// Global Task Update Function /////

  const handleTaskUpdate = (
    tasks,
    taskIds,
    updateProperties,
    updateType,
    isMassUpdate = false,
    postUpdateCallback = null
  ) => {
    const ids = Array.isArray(taskIds) ? taskIds : [taskIds] // Ensure taskIds is an array
    console.log(
      '[handleTaskUpdate] ids',
      ids,
      'updateProperties',
      updateProperties
    )
    if (isMassUpdate || ids.length > 1) {
      // Batch Update
      const updates = ids.map((taskId) => ({
        tableName: 'tasks',
        itemId: taskId,
        fieldName: Object.keys(updateProperties)[0],
        newValue: Object.values(updateProperties)[0],
      }))

      batchUpdateTasks(updates, {
        onSuccess: () => {
          console.log('[handleTaskUpdate] Batch update successful')
          if (postUpdateCallback) postUpdateCallback()
        },
        onError: (error) => {
          console.error('[handleTaskUpdate] Batch update failed', error)
        },
      })
    } else {
      // Single Update
      const taskId = ids[0]
      updateTask(
        { taskId, taskData: updateProperties },
        {
          onSuccess: () => {
            console.log('[handleTaskUpdate] Single update successful')
            if (postUpdateCallback) postUpdateCallback()
          },
          onError: (error) => {
            console.error('[handleTaskUpdate] Single update failed', error)
          },
        }
      )
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  // Notice how we're not using early return for loading or error states anymore
  const value = {
    ...state, // Spread the existing state
    isLoading: isTasksLoading,
    isError: isTasksError,
    errorMessage: tasksError,
    dispatch,
    handleUpdateTask,
    handleUpdateTasks,
    handleTaskUpdate,
    // Any other methods or states you want to expose
  }

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
}

export const useTask = () => {
  const context = useContext(TaskContext)
  if (!context) {
    throw new Error('useTask must be used within a TaskProvider')
  }
  return context
}

export default TaskProvider
