import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import {
  Autocomplete,
  AvatarGroup,
  Avatar,
  TextField,
  Box,
  List,
  ListItem,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material'
import UserImageComponent from '../Users/UserImageComponent'
import { useQuery } from '@tanstack/react-query'
import { getQuery } from '../../api/aws/useGetQuery'
import { useUsers } from '../../api/aws/useUsers'

export const sortUsersBySelectedRoles = (users, selectedRoleMembers) => {
  if (!Array.isArray(users) || users.length === 0) return []

  return [...users].sort((a, b) => {
    const aSelected = selectedRoleMembers.some(member => member && member.user_uuid === a.user_uuid)
    const bSelected = selectedRoleMembers.some(member => member && member.user_uuid === b.user_uuid)
    return bSelected - aSelected
  })
}

const RoleCard = ({ open, initialRoleUsers, handleChange, roleName }) => {
  console.log('initialRoleUsers', initialRoleUsers)
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['roleCard', 'users'])

  const theme = useTheme()
  const [selectedUsers, setSelectedUsers] = useState(initialRoleUsers || [])
  const inputRef = useRef(null) // Ref to handle focusing

  useEffect(() => {
    setSelectedUsers(initialRoleUsers || [])
  }, [initialRoleUsers])

  useEffect(() => {
    // Focus the input when the component opens
    if (open && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open])

  const handleRoleChange = useCallback(
    (event, newValue) => {
      setSelectedUsers(newValue)
      handleChange(roleName, newValue)
    },
    [handleChange, roleName]
  )

  const sortedUsers = useMemo(() => sortUsersBySelectedRoles(users, selectedUsers), [users, selectedUsers])

  const renderOption = (props, option) => {
    const { key, id, ...otherProps } = props
    return (
      <List key={`${key}-${id}`} {...otherProps} sx={{ width: '100%', p: 0 }}>
        <ListItem sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
          {option.image_512 ? (
            <Box
              component='img'
              src={option.image_512}
              alt='User'
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                mr: 1,
              }}
            />
          ) : (
            <Avatar sx={{ width: 40, height: 40, mr: 1 }} />
          )}
          <Typography
            sx={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px',
            }}
          >
            {option.name}
          </Typography>
        </ListItem>
      </List>
    )
  }

  const renderTags = value => (
    <AvatarGroup
      max={4}
      spacing='medium'
      slotProps={{
        additionalAvatar: {
          sx: {
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.getContrastText(theme.palette.secondary.main),
          },
        },
      }}
    >
      {value.map(option =>
        option.image_512 ? (
          <UserImageComponent imageUrl={option.image_512} key={option.user_uuid} />
        ) : (
          <Avatar key={option.user_uuid} sx={{ bgcolor: theme.palette.background.paper }} />
        )
      )}
    </AvatarGroup>
  )

  return (
    <>
      {isUsersLoading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          multiple
          open={open}
          value={selectedUsers}
          onChange={handleRoleChange}
          options={sortedUsers}
          getOptionLabel={option => option.name || ''}
          renderTags={renderTags}
          renderOption={renderOption}
          isOptionEqualToValue={(option, value) => option?.user_uuid === value?.user_uuid}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={inputRef} // Attach ref for focusing
              variant='outlined'
              sx={{
                height: '100%',
                '& .MuiOutlinedInput-root': {
                  height: '100%',
                  alignItems: 'center',
                  pt: 0,
                  pb: 0,
                  pr: 0,
                  pl: 1,
                },
              }}
            />
          )}
        />
      )}
    </>
  )
}

export default React.memo(RoleCard)
