import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getProducts = async (endpoint, tableName, filters = {}) => {
  try {
    const url = `${baseUrl}/aws/products`
    const { data } = await axios.get(url, {
      params: { table: tableName, ...filters }, // Pass filters for PK, SK, or EntityType
    })
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useProducts = enabled => {
  const { data: products, isLoading: isProductsLoading, isError: isProductsError, error: productsError } = useQuery({
    queryKey: ['products'],
    queryFn: () => getProducts('/aws/products', 'gro_products'),
    staleTime: Infinity, // Prevent the query from becoming stale
    cacheTime: 1000 * 60 * 30, // Cache for 30 minutes
    refetchOnWindowFocus: false, // Prevent refetching when window regains focus
    refetchOnReconnect: false, // Prevent refetching when the network reconnects
    enabled: enabled ?? true,
  })
  return { products, isProductsLoading, isProductsError, productsError }
}
