import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Avatar,
  AvatarGroup,
  Button,
  Popover,
  Typography,
} from '@mui/material'
import { CustomTaskGrid } from '../../../style/styleElements'

const RenderTaskRole = ({
  task,
  roleData,
  handleChangeUserRole,
  users,
  role,
  selectedTaskIds,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [userIdsToNotify, setUserIdsToNotify] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setSelectedUserIds(roleData.map((user) => user?.user_uuid ?? null))
  }, [roleData])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUserClick = (event, userId) => {
    console.log('event', event)
    console.log('handleUserClick userId: ', userId)

    // Update selectedUserIds state as before
    setSelectedUserIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    )

    // Find the user by userId to get the slackUserId
    const user = users.find((user) => user.user_uuid === userId)
    console.log('user', user)
    const slackUserId = user ? user.user_uuid : null // Assuming 'slackUserId' is the property name
    console.log('slackUserId: ', slackUserId)

    // Update userIdsToNotify with slackUserId if it exists
    if (slackUserId) {
      setUserIdsToNotify((prev) =>
        prev.includes(slackUserId)
          ? prev.filter((id) => id !== slackUserId)
          : [...prev, slackUserId]
      )
    } else {
      // Handle the case where the user doesn't have a slackUserId,
      // decide whether to ignore or handle differently
      console.log(`User ${userId} does not have a slackUserId.`)
    }
  }

  const handleSave = () => {
    const usersForThisTask = selectedUserIds
      .map((userId) => users.find((user) => user.user_uuid === userId))
      .filter(Boolean) // Filter out null or undefined elements

    if (selectedTaskIds.length > 1) {
      // Mass update
      handleChangeUserRole(
        selectedTaskIds,
        usersForThisTask,
        userIdsToNotify,
        role,
        true
      )
    } else {
      // Single update
      handleChangeUserRole(
        [task.task_uuid],
        usersForThisTask,
        userIdsToNotify,
        role,
        false
      )
    }
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        {roleData && roleData.length > 0 ? (
          <AvatarGroup max={3}>
            {selectedUserIds.map((userId) => {
              const user = users.find((u) => u.user_uuid === userId)
              return user ? (
                <Avatar
                  key={userId}
                  alt={user.name}
                  src={user?.image_512} // Assuming img_link is the path to the image
                  sx={{ width: 40, height: 40 }}
                />
              ) : null
            })}
          </AvatarGroup>
        ) : (
          <Typography>{role}</Typography> // Display "AM" or "DMS" as placeholders
        )}
      </Box>
      <Grid container>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Grid container spacing={0.5}>
              {users.map((user) => (
                <CustomTaskGrid
                  item
                  xs={12}
                  sm={7}
                  md={5}
                  lg={4}
                  xl={1.5} // Adjusted for 6-column layout
                  key={user.user_uuid}
                  onClick={(event) => handleUserClick(event, user.user_uuid)}
                  user={user}
                  selecteduserids={selectedUserIds}
                >
                  <Avatar
                    alt={user.name}
                    src={user?.image_512}
                    sx={{ width: 75, height: 75 }}
                  />
                  <Box
                    sx={{
                      mt: 1,
                      textAlign: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {user.name}
                  </Box>
                </CustomTaskGrid>
              ))}
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Apply Changes
              </Button>
            </Box>
          </Box>
        </Popover>
      </Grid>
    </Box>
  )
}

export default RenderTaskRole
