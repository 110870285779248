import React, { useEffect, useState } from 'react'
import { Grid, Tooltip, useTheme, Box, Typography, ListItem, List } from '@mui/material'
import ApprovalRequestButton from '../ui/approval-request-button'
import RequestButtonTooltip from '../ui/request-button-tooltip'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import CreateContractButton from '../ui/create-contract-button'
import RevertContractButton from '../ui/revert-contract-button'
import GenerateLinksButton from '../ui/generate-links-button'
import { useGenerateLinks } from '../../api/PandaDoc/useGenerateLinks'
import ViewLinksModal from './ViewLinksModal'
import { useDraftDocument } from '../../api/PandaDoc/useDraftDocument'
import { useQueryClient } from '@tanstack/react-query'
import ViewLinksWithBadge from '../Icons/ViewLinksWithBadge'
import GenerateLinksModal from './GenerateLinksModal'
import { dynamicGridSizes } from '../ContractDialog/style'

const RequestGenerateContract = ({
  newDeal,
  activeUser,
  isRequesting,
  onRequestApproval,
  onCreate,
  isStrategyValid,
  validationErrors,
  errorKeys,
  isCreating,
  isValid,
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [isReverting, setIsReverting] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [isLinksOpen, setIsLinksOpen] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [linksGenerated, setLinksGenerated] = useState(
    (newDeal?.authorizedDocumentLink && newDeal?.contactDocumentLink) || newDeal?.linksGenerated || false
  )
  const { mutate: generateLinks } = useGenerateLinks(newDeal, activeUser)
  const { mutate: draftDocument } = useDraftDocument()
  const isRequestValid =
    validationErrors?.dependenciesDueDate ||
    validationErrors?.endDate ||
    validationErrors?.startDate ||
    validationErrors?.targetLaunchDate ||
    validationErrors?.products
  const handleRevert = () => {
    if (!newDeal?.contractId) return
    setIsReverting(true)
    const updateData = { contractId: newDeal?.contractId, dealId: newDeal?.deal_uuid }
    draftDocument(updateData, {
      onSuccess: () => {
        queryClient.invalidateQueries(['dealContract', newDeal?.deal_uuid])
        setIsReverting(false)
      },
      onError: () => {
        setIsReverting(false)
      },
    })
  }

  const handleGenerateLinks = () => {
    if (!newDeal?.contractId || !newDeal?.deal_uuid) return
    setIsGenerating(true)
    const linkData = { contractId: newDeal?.contractId, dealId: newDeal?.deal_uuid }
    generateLinks(linkData, {
      onSuccess: () => {
        queryClient.invalidateQueries(['dealContract', newDeal?.deal_uuid])
        setIsGenerating(false)
        setIsConfirmationOpen(false)
      },
      onError: () => {
        setIsGenerating(false)
      },
    })
  }
  const handleOpenLinks = () => setIsLinksOpen(true)
  const handleCloseLinks = () => setIsLinksOpen(false)
  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true)
  }

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false)
  }

  const handleConfirmGenerateLinks = () => {
    setIsConfirmationOpen(false)
    handleGenerateLinks()
  }
  // useEffect(() => {
  //   if (newDeal) {
  //     console.log('Deal Stage: ', newDeal?.stage)
  //     console.log('isApproved?', newDeal?.isApproved)
  //     console.log('isCreated?', newDeal?.isCreated)
  //     console.log('isCompleted?', newDeal?.isCompleted)
  //     console.log('isRequested?', newDeal?.isRequested)
  //     console.log('isDraft?', newDeal?.isDraft)
  //   }
  //   if (newDeal?.contractId) {
  //     console.log('contractId', newDeal?.contractId)
  //   }
  //   if (authorizedEmail) {
  //     console.log('authorizedEmail', authorizedEmail)
  //   }
  //   if (contactEmail) {
  //     console.log('contactEmail', contactEmail)
  //   }
  // }, [newDeal, authorizedEmail, contactEmail])
  return (
    <>
      {newDeal?.isApproved && !newDeal?.isCreated && !newDeal?.isCompleted && (
        <Tooltip
          title={
            !isValid ? (
              <Box>
                <Typography>Please fix the following validation errors before creating the contract:</Typography>
                <List>
                  {Object.entries(validationErrors)
                    .filter(([key, value]) => value === true) // Only include errors with value `true`
                    .map(([key]) => (
                      <ListItem key={key}>
                        <Typography sx={{ color: theme.palette.text.error }}>{key}</Typography>
                      </ListItem>
                    ))}
                </List>
              </Box>
            ) : (
              ''
            )
          }
        >
          <Box>
            <CreateContractButton
              isCreating={isCreating}
              onCreate={onCreate}
              disabled={!isValid}
              color='primary'
              variant='contained'
              startIcon={<PandaDocImageComponent size={30} />}
            />
          </Box>
        </Tooltip>
      )}
      {newDeal?.isApproved &&
        newDeal?.isCreated &&
        !newDeal?.isDraft &&
        newDeal?.status !== 'Completed' &&
        newDeal?.linksGenerated && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <RevertContractButton text={'Revert to Draft'} isReverting={isReverting} onRevert={handleRevert} />
            </Grid>
            {newDeal?.linksGenerated && (
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <ViewLinksWithBadge onClick={handleOpenLinks} />
              </Grid>
            )}
            {isLinksOpen && <ViewLinksModal isOpen={isLinksOpen} onClose={handleCloseLinks} newDeal={newDeal} />}
          </Box>
        )}
      {newDeal?.isApproved && newDeal?.isCreated && newDeal?.status !== 'Completed' && newDeal?.isDraft && (
        <>
          <GenerateLinksButton text='Generate Links' isGenerating={isGenerating} onGenerate={handleOpenConfirmation} />
          <GenerateLinksModal
            isOpen={isConfirmationOpen}
            onClose={handleCloseConfirmation}
            onConfirm={handleConfirmGenerateLinks}
            isGenerating={isGenerating}
          />
        </>
      )}
      {/* {newDeal && !newDeal?.isApproved && newDeal?.isCreated && !newDeal?.isCompleted && !newDeal?.isRequested && newDeal?.isDraft} */}
      {newDeal?.isApproved && newDeal?.isCreated && newDeal?.status === 'Completed' && !newDeal?.isDraft && (
        <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
          <Typography color={'rgb(80, 200, 120)'} textAlign={'center'}>
            Contract Completed
          </Typography>
        </Grid>
      )}
      {newDeal && !newDeal?.isApproved && !newDeal?.isCreated && newDeal?.status !== 'Completed' && (
        <Tooltip title={<RequestButtonTooltip newDeal={newDeal} />}>
          <Grid item {...dynamicGridSizes.requestButton}>
            <ApprovalRequestButton
              newDeal={newDeal}
              isRequested={newDeal?.isRequested}
              isRequesting={isRequesting}
              onRequest={onRequestApproval}
              startIcon={true}
              errorIcon={errorKeys?.length > 0}
              // isValid={isStrategyValid}
              isValid={!isRequestValid}
            />
          </Grid>
        </Tooltip>
      )}
    </>
  )
}

export default RequestGenerateContract
{
  /* <LinksModalButton text={'Create Links'} onOpen={handleOpenGenerateLinks} /> */
}
// const handleOpenGenerateLinks = () => {
//   console.log('generate links open, dealId: ', newDeal?.deal_uuid, 'contractId: ', newDeal?.contractId)
//   setIsLinksModalOpen(true)
// }
// const handleCloseGenerateLinks = () => {
//   setAuthorizedEmail('')
//   setContactEmail('')
//   setIsLinksModalOpen(false)
// }
