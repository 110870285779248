import { format, isToday, isTomorrow, differenceInCalendarDays } from 'date-fns'
import { StyledGridTypography } from '../../style/styleElements'

export const calculateTaskDays = taskType => {
  const taskDaysMapping = {
    'Copy Update': 3,
    'Campaign Audit': 5,
    'Budget Rec': 4,
    'Budget Updates': 4,
    'Launch New Creatives': 5,
    'GTM/GA Setup': 4,
    'Campaign Setup': 6,
    'AM Task / Update': 4,
    'Offboard Client': 3,
    Discovery: 5,
    'Campaign Updates': 4,
    'SEO Change': 4,
    'SEO Update': 3,
    'New Client Onboard': 6,
  }
  return taskDaysMapping[taskType] || 0 // Default to 0 if task type not found
}

export const workday = (startDate, taskDays) => {
  // Placeholder implementation; you should replace this with actual logic
  // This should account for weekends and possibly holidays based on your needs
  if (!startDate) return
  const resultDate = new Date(startDate)
  resultDate.setDate(resultDate.getDate() + taskDays)
  return resultDate
}
export const formatDateString = date => {
  return format(new Date(date), 'MM/dd/yyyy') // Use 'MM/dd/yyyy' to ensure two digits
}

export const calculateDueDateDisplay = params => {
  const { masterStatus, highPrio, startDate, taskType, doneDate } = params.row

  if (masterStatus === 'Done' && doneDate) {
    return <StyledGridTypography sx={{ color: 'lightgreen' }}>{`Completed`}</StyledGridTypography>
  } else if (masterStatus === 'Done') {
    return 'Completed'
  }

  if (highPrio) {
    return <StyledGridTypography sx={{ color: 'crimson' }}>{`High Priority`}</StyledGridTypography>
  }

  // Calculate the task days and due date based on the task type and start date
  const taskDays = calculateTaskDays(taskType)
  const calculatedDueDate = workday(startDate, taskDays)

  // Now we use the calculatedDueDate in place of dueDate
  const dueDateObj = new Date(calculatedDueDate)
  const today = new Date()
  const daysDiff = differenceInCalendarDays(dueDateObj, today)

  if (isToday(dueDateObj)) {
    return <StyledGridTypography sx={{ color: 'rgb(255, 68, 161)' }}>{'TODAY'}</StyledGridTypography>
  } else if (daysDiff < 0) {
    const daysLate = Math.abs(daysDiff)
    return (
      <StyledGridTypography sx={{ color: 'pink' }}>{`LATE - ${daysLate} day${
        daysLate > 1 ? 's' : ''
      } ago`}</StyledGridTypography>
    )
  } else if (isTomorrow(dueDateObj)) {
    return 'Tomorrow'
  } else {
    const daysLeft = daysDiff
    return `${daysLeft} day${daysLeft > 1 ? 's' : ''} left`
  }
}
