import { Box, Checkbox } from '@mui/material'
import RenderTaskName from './RenderTaskName'
import TaskInfo from '../TaskDrawer/TaskInfo'
import { calculateTaskDays, calculateDueDateDisplay, workday } from '../Tasks/taskUtil'
import { masterStatusList } from '../Tasks/CreateTasks/taskObjects/masterStatusList'
import RenderListSelectionComponent from '../Reusable/RenderListSelectionComponent'
import RenderTaskType from '../Tasks/RenderTaskType/RenderTaskType'
import { taskTypeList } from '../Tasks/CreateTasks/taskObjects/taskTypeList'
import dayjs from 'dayjs'
import { NcoStatusContainer, StyledGridDefaultTypography, StyledGridTypography } from '../../style/styleElements'
import RenderTaskRole from '../Tasks/RenderTaskRole/RenderTaskRole'
import EditSelectCell from '../NCO/EditSelectCell'
import { strictEqualsOperator } from '../DataGrid/gridOperators'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { renderColorByString } from '../DataGrid/renderColorByString'

const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

const renderTeamTasksColumns = (
  theme,
  users,
  selectedTaskIds,
  handleChangeUserRole,
  handleChangeProject,
  handleChangeName,
  handleChangeTaskType,
  handleDateChange,
  handleHighPrioChange,
  handleChangeMasterStatus,
  handleTaskClick,
  showTaskDrawer,
  closeTaskDrawer
) => {
  return [
    {
      field: 'taskName',
      headerName: 'Name',
      flex: 0.6,
      minWidth: 175,
      // editable: true,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
          >
            <RenderTaskName params={params} handleChangeName={handleChangeName} />
            {/* <TaskInfo handleTaskClick={handleTaskClick} params={params.row} /> */}
          </Box>
        )
      },
    },
    {
      field: 'info',
      headerName: 'Info',
      flex: 0.3,
      minWidth: 50,
      // editable: true,
      renderCell: params => {
        return (
          <Box
            style={{
              // display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TaskInfo handleTaskClick={handleTaskClick} params={params.row} />
          </Box>
        )
      },
    },
    // {
    //   field: 'masterStatus',
    //   headerName: 'Master Status',
    //   flex: 0.3,
    //   minWidth: 175,
    //   renderCell: params => (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'left',
    //         width: '100%',
    //         height: '100%',
    //         boxSizing: 'border-box', // Include padding in the size calculation
    //       }}
    //     >
    //       <RenderListSelectionComponent
    //         params={params}
    //         handleChangeFn={handleChangeMasterStatus}
    //         selectedTaskIds={selectedTaskIds} // Pass selectedTaskIds as a prop
    //         dataList={masterStatusList}
    //       />
    //     </Box>
    //   ),
    // },
    {
      field: 'masterStatus',
      headerName: 'Master Status',
      flex: 0.3,
      minWidth: 175,
      editable: true,
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              // padding: '2px', // Adjust padding to ensure no overflow
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value, theme), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={masterStatusList} />,
    },
    {
      field: 'taskDays',
      headerName: 'Task Days',
      flex: 0.3,
      minWidth: 50,
      align: 'center', // This centers the column's content
      headerAlign: 'left', // Optionally, also center the header text
      valueGetter: params => {
        return calculateTaskDays(params?.row?.taskType)
      },
    },
    {
      field: 'highPrio',
      headerName: 'High Priority',
      flex: 0.3,
      minWidth: 50,
      align: 'center',
      renderCell: params => (
        <Checkbox
          checked={!!params.value}
          onChange={event => {
            // Determine if this is a mass update or a single update
            const isMassUpdate = selectedTaskIds.length > 1 && selectedTaskIds.includes(params.id)
            // Prepare the task IDs for update - either just this one or the selected ones
            const taskIdsToUpdate = isMassUpdate ? selectedTaskIds : [params.id]
            // Call the update function with the new high priority status
            handleHighPrioChange(taskIdsToUpdate, event.target.checked, isMassUpdate)
          }}
          inputProps={{ 'aria-label': 'High Priority' }}
        />
      ),
    },

    // // using reusable component //
    // {
    //   field: 'taskType',
    //   headerName: 'Task Type',
    //   flex: 0.3,
    //   minWidth: 175,
    //   renderCell: params => {
    //     return (
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'left',
    //           width: '100%',
    //           height: '100%',
    //           boxSizing: 'border-box', // Include padding in the size calculation
    //         }}
    //       >
    //         <RenderTaskType
    //           params={params}
    //           handleChangeFn={handleChangeTaskType}
    //           selectedTaskIds={selectedTaskIds}
    //           dataList={taskTypeList}
    //         />
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'taskType',
      headerName: 'Task Type',
      flex: 0.3,
      minWidth: 175,
      editable: true,
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              // padding: '2px', // Adjust padding to ensure no overflow
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value, theme), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={taskTypeList} />,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'dateDue',
      headerName: 'Date Due',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        const taskDays = calculateTaskDays(params?.row?.taskType)
        if (taskDays > 1 && params?.row?.startDate) {
          const dueDate = workday(new Date(params?.row?.startDate), taskDays)
          return <StyledGridDefaultTypography>{formatDateString(dueDate)}</StyledGridDefaultTypography>
        }
        return 'Needs Status Type' // Return a simple string value
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },

    {
      field: 'AM',
      headerName: 'AM',
      flex: 0.3,
      minWidth: 65,
      renderCell: params => (
        <RenderTaskRole
          task={params?.row}
          roleData={params.row.AM ? params.row.AM : []} // Assuming 'AM' field contains the current AM, adjust if needed
          handleChangeUserRole={handleChangeUserRole}
          users={users}
          role='AM'
          selectedTaskIds={selectedTaskIds}
        />
      ),
    },
    {
      field: 'DMS',
      headerName: 'DMS',
      flex: 0.3,
      minWidth: 65,
      renderCell: params => (
        <RenderTaskRole
          task={params?.row}
          roleData={params.row.DMS ? params.row.DMS : []} // Assuming 'DMS' field contains the current DMS, adjust if needed
          handleChangeUserRole={handleChangeUserRole}
          users={users}
          role='DMS'
          selectedTaskIds={selectedTaskIds}
        />
      ),
    },
    {
      field: 'displayId',
      headerName: 'ID',
      flex: 0.3,
      minWidth: 50,
      renderCell: params => {
        // console.log('params: ', params)
        return <StyledGridTypography component='div'>{params.row.displayId}</StyledGridTypography>
      },
    },
  ]
}

export default renderTeamTasksColumns
