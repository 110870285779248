import React from 'react'
import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material'
import PandaIcon from './panda-icon'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'

const GenerateLinksButton = ({ text, isGenerating, onGenerate, disabled = false }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 42,
        backgroundColor: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disabled={isGenerating || disabled}
      onClick={onGenerate}
      name='generate-links'
      variant='contained'
    >
      <Tooltip title={<Typography>Generate Links</Typography>}>
        {isGenerating ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1, // Space between items
            }}
          >
            <PandaDocImageComponent size={30} />
            <Typography>Generating...</Typography>
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.primary, // Use primary color for visibility
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              textAlign: 'left', // Align text left or right as needed
              whiteSpace: 'nowrap', // Prevent wrapping
              overflow: 'hidden', // Add ellipsis for long links
              textOverflow: 'ellipsis',
              gap: 1, // Space between items
            }}
          >
            <PandaDocImageComponent size={30} />
            <Typography
              sx={{
                textAlign: 'left', // Align text left or right as needed
                whiteSpace: 'nowrap', // Prevent wrapping
                overflow: 'hidden', // Add ellipsis for long links
                textOverflow: 'ellipsis',
              }}
            >
              {text ?? 'Generate Links'}
            </Typography>
          </Box>
        )}
      </Tooltip>
    </Button>
  )
}

export default GenerateLinksButton
