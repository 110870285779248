import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import UpdateIcon from '@mui/icons-material/Update'
import { renderTimelineText } from '../DataGrid/renderTimelineText'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'

const renderTimelineIcons = action => {
  switch (action) {
    case 'Approval':
    case 'approval':
      return <ThumbUpOffAltIcon />
    case 'Deals':
    case 'deals':
    case 'Save':
      return <AttachMoneyIcon />
    case 'document':
    case 'Generation':
    case 'Generate':
      return <PandaDocImageComponent size={25} />
    case 'Update':
    case 'update':
      return <UpdateIcon />
    case 'productUpdate':
      return <ShoppingCartIcon />
  }
}

const TimelineType = ({ type }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Grid
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      item
      xs={2}
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      {isHovered ? (
        <Typography>{renderTimelineText(type)}</Typography>
      ) : (
        <Typography>{renderTimelineIcons(type)}</Typography>
      )}
    </Grid>
  )
}

export default TimelineType
