import { styled } from '@mui/material'

export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  // backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '30px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'white',
    minWidth: '100%', // Ensure the input base width fits within the dialog
  },
  '& .MuiFormControl-root': {
    width: '100%', // Ensure the form control takes full width
  },
  '& .MuiDateRangePicker-root': {
    width: '100%', // Ensure the DateRangePicker takes full width
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,51)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
})
export const dialogTitleStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  fontWeight: 'bold',
  textAlign: 'center',
})
export const boxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
export const dialogContentStyles = {
  width: 'auto',
  // maxWidth: '700px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 3, // Consistent vertical gap
  padding: '24px', // Optional additional padding
}

export const disabledTextStyles = theme => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(229, 229, 229)',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75, 0.4)' : 'white',
  },
})

export const namePreviewStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const DialogIcon = styled('img')({
  width: 50,
  height: 50,
  borderRadius: '50%',
})
export const accordionStyle = theme => ({
  // border: '1px solid',
  // borderColor: theme.palette.mode === 'dark' ? 'rgb(108, 113, 120)' : 'rgb(229, 229, 229)',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(49, 54, 67)' : 'rgba(224, 224, 224, 0.3)',
})
export const accordionDetailStyle = theme => ({
  padding: 2,
  width: '100%',
  // border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? 'rgb(49, 54, 67)' : 'rgb(229, 229, 229)',
  backgroundColor: 'transparent',
})
export const accordionSummaryStyle = theme => ({
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? 'rgb(108, 113, 120)' : 'rgba(224, 224, 224, 0.3)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
  borderRadius: '5px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75)' : 'rgb(229, 229, 229)',
})
