import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useLeadsById = (leadIds, queryKey) => {
  const validIds = Array.isArray(leadIds) ? leadIds.filter(id => !!id) : []

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/leads', 'leads', validIds, queryKey ?? 'lead')

  // Extract user data from successful queries
  const leads = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data

  // Aggregate loading and error states
  const isLeadsByIdLoading = queryResults.some(result => result.isLoading)
  const isLeadsByIdError = queryResults.some(result => result.isError)
  const leadsByIdError = queryResults.find(result => result.isError)?.error

  return { leads, isLeadsByIdLoading, isLeadsByIdError, leadsByIdError }
}
