import { useUpdateDeal } from '../../../api/aws/useUpdateDeal'
import { useCreateTimeline } from '../../../api/aws/useCreateTimeline'
import { updateContractTimelineData } from '../../Timeline/createTimelineData'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateDocument } from '../../../api/PandaDoc/useUpdateDocument'
import { useQueryClient } from '@tanstack/react-query'
import { useReassignAuthRecipient } from '../../../api/PandaDoc/useReassignAuthRecipient'
import { useReassignContactRecipient } from '../../../api/PandaDoc/useReassignContactRecipient'

export const useSaveAndUpdateDocument = () => {
  const { mutate: updateDeal } = useUpdateDeal()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const { mutate: updateDocument } = useUpdateDocument()
  const { mutate: reassignAuthRecipient } = useReassignAuthRecipient()
  const { mutate: reassignContactRecipient } = useReassignContactRecipient()
  const queryClient = useQueryClient()

  const saveAndUpdateDocument = async (
    cleanedDeal,
    organization,
    activeUser,
    onCancel,
    setIsUpdating,
    setIsSaving,
    totals,
    productRows,
    document
  ) => {
    setIsUpdating(true)
    setIsSaving(true)
    try {
      const documentData = {
        deal: cleanedDeal,
        organization,
        activeUser,
        document,
      }
      const timelineData = updateContractTimelineData(
        cleanedDeal?.deal_uuid,
        activeUser,
        'Modify',
        'document',
        'Document Updated and Deal Saved'
      )
      console.log('timelineData', timelineData)
      console.log('documentData', documentData)
      // Detect and reassign recipients if needed
      try {
        const documentId = cleanedDeal?.contractId // Assuming cleanedDeal? contains the documentId
        const newAuthRecipient = cleanedDeal?.recipients?.authorizedRecipient?.[0]
        const oldAuthRecipient = document?.recipients?.[0]
        console.log(
          'newAuthRecipientId',
          newAuthRecipient?.contact_id,
          'oldAuthRecipientId',
          oldAuthRecipient?.contact_id
        )
        const newContactRecipient = cleanedDeal?.recipients?.contactRecipient?.[0]
        const oldContactRecipient = document?.recipients?.[1]
        console.log(
          'newContactRecipientId',
          newContactRecipient?.contact_id,
          'oldContactRecipientId',
          oldContactRecipient?.contact_id
        )
        // Reassign authorized recipient if changed
        if (newAuthRecipient?.contact_id && oldAuthRecipient?.contact_id !== newAuthRecipient.contact_id) {
          console.log('Reassigning authorized recipient...')
          reassignAuthRecipient({
            ...documentData,
          })
        }

        // Reassign contact recipient if changed
        if (newContactRecipient?.contact_id && oldContactRecipient?.contact_id !== newContactRecipient.contact_id) {
          console.log('Reassigning contact recipient...')
          reassignContactRecipient(documentData)
        }
      } catch (error) {
        console.error('Error during recipient reassignment:', error.message)
      }
      updateDocument(documentData, {
        onSuccess: data => {
          const hasProducts = data && Array.isArray(data.products) && data.products.length > 0
          const contractId = data.id
          const dealWithContractId = {
            ...cleanedDeal,
            contractId: contractId,
            status: cleanedDeal?.status ?? 'Modified',
            stage: 'Contract Created',
            lastModified: new Date(),
            ...totals,
            hasProducts: hasProducts,
            products: productRows,
            totals: { ...totals },
          }

          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                createTimelineEntry(timelineData)
                setIsSaving(false)
                setIsUpdating(false)

                queryClient.invalidateQueries(['deal', cleanedDeal?.deal_uuid])
              },
              onError: error => {
                setIsSaving(false)
                setIsUpdating(false)

                console.error('Error in updateDeal:', error)
              },
            }
          )
        },
        onError: error => {
          setIsSaving(false)
          setIsUpdating(false)

          console.error('Error in createDocument:', error)
        },
      })
    } catch (error) {
      setIsUpdating(false)
      setIsSaving(false)
      console.error('Error in saveAndUpdateDocument:', error)
    }
  }

  return { saveAndUpdateDocument }
}
