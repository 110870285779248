import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ taskId, taskData }) => {
      const url = `${baseUrl}/aws/tasks/${taskId}`
      const response = await axios.put(url, taskData)
      return response.data
    },

    onMutate: async ({ taskId, taskData }) => {
      await queryClient.cancelQueries(['/aws/tasks', taskId])
      const previousTask = queryClient.getQueryData(['/aws/tasks', taskId])

      queryClient.setQueryData(['/aws/tasks', taskId], (old) => ({
        ...old,
        ...taskData,
      }))

      return { previousTask }
    },

    onError: (err, { taskId }, context) => {
      queryClient.setQueryData(['/aws/tasks', taskId], context.previousTask)
    },

    onSuccess: (_, { taskId }) => {
      // queryClient.invalidateQueries(['deals']) // Invalidate all deals to ensure the grid rerenders
      // queryClient.invalidateQueries(['organizations']) // Invalidate organizations if they are related to the deals grid
    },
  })

  return mutation
}
