// export const masterStatusList = [
//   {
//     value: 'Select Status',
//     label: 'Select Status',
//     color: 'rgb(121, 126, 147)',
//   },
//   {
//     value: 'Waiting On Google',
//     label: 'Waiting On Google',
//     color: 'rgb(161, 227, 246)',
//   },
//   {
//     value: 'In Progress',
//     label: 'In Progress',
//     color: 'rgb(253, 188, 100)',
//   },
//   {
//     value: 'DMS Attention',
//     label: 'DMS Attention',
//     color: 'rgb(255, 131, 88)',
//   },
//   {
//     value: 'AM Attention',
//     label: 'AM Attention',
//     color: 'rgb(51, 158, 205)',
//   },
//   {
//     value: 'Need Client Feedback',
//     label: 'Need Client Feedback',
//     color: 'rgb(133, 214, 255)',
//   },
//   {
//     value: 'Done',
//     label: 'Done',
//     color: 'rgb(51, 211, 145)',
//   },
//   {
//     value: 'AM Follow Up',
//     label: 'AM Follow Up',
//     color: 'rgb(43, 118, 229)',
//   },
//   {
//     value: 'Ready To Launch',
//     label: 'Ready To Launch',
//     color: 'rgb(121, 175, 253)',
//   },
//   {
//     value: 'Postdated (AM)',
//     label: 'Postdated (AM)',
//     color: 'rgb(181, 125, 227)',
//   },
//   {
//     value: 'Postdated (DMS)',
//     label: 'Postdated (DMS)',
//     color: 'rgb(147, 111, 218)',
//   },
//   {
//     value: 'On Hold',
//     label: 'On Hold',
//     color: 'rgb(201, 92, 118)',
//   },
//   {
//     value: 'Waiting on Creative',
//     label: 'Waiting on Creative',
//     color: 'rgb(92, 92, 92)',
//   },
//   {
//     value: 'DMS SLA Change',
//     label: 'DMS SLA Change',
//     color: 'rgb(119, 122, 229)',
//   },
//   {
//     value: 'AM SLA Change',
//     label: 'AM SLA Change',
//     color: 'rgb(53, 153, 112)',
//   },
//   {
//     value: 'Ready to QA',
//     label: 'Ready to QA',
//     color: 'rgb(113, 214, 209)',
//   },
//   {
//     value: 'SEO Attention',
//     label: 'SEO Attention',
//     color: 'rgb(232, 105, 125)',
//   },
//   {
//     value: 'PM Attention',
//     label: 'PM Attention',
//     color: 'rgb(78, 115, 167)',
//   },
//   {
//     value: 'Needs MI Attention',
//     label: 'Needs MI Attention',
//     color: 'rgb(157, 153, 185)',
//   },
//   {
//     value: 'Waiting On Ad Approvals',
//     label: 'Waiting On Ad Approvals',
//     color: 'rgb(255, 68, 161)',
//   },
//   {
//     value: 'Churn',
//     label: 'Churn',
//     color: 'rgb(255, 123, 208)',
//   },
//   {
//     value: 'Master Status',
//     label: 'Master Status',
//     color: 'rgb(153, 153, 153)',
//   },
//   {
//     value: 'Ready to QA - Google Search',
//     label: 'Ready to QA - Google Search',
//     color: 'rgb(251, 180, 244)',
//   },
//   {
//     value: 'Ready to QA - FB/IG',
//     label: 'Ready to QA - FB/IG',
//     color: 'rgb(255, 189, 189)',
//   },
//   {
//     value: 'Ready to QA - GDN',
//     label: 'Ready to QA - GDN',
//     color: 'rgb(217, 116, 176)',
//   },
//   {
//     value: 'Ready to QA - Simplifi',
//     label: 'Ready to QA - Simplifi',
//     color: 'rgb(189, 168, 249)',
//   },
//   {
//     value: 'Ready to QA - TikTok',
//     label: 'Ready to QA - TikTok',
//     color: 'rgb(255, 145, 145)',
//   },
//   {
//     value: 'Ready to QA - Other',
//     label: 'Ready to QA - Other',
//     color: 'rgb(169, 190, 232)',
//   },
//   {
//     value: 'Requested',
//     label: 'Requested',
//     color: 'rgb(253, 188, 100)',
//   },
//   {
//     value: 'Not Needed',
//     label: 'Not Needed',
//     color: 'rgb(53, 153, 112)',
//   },
//   {
//     value: 'newMasterStatus',
//     label: 'newMasterStatus',
//     color: 'rgb(255, 255, 255)',
//   },
//   {
//     value: 'N/A',
//     label: 'N/A',
//     color: 'rgb(121, 126, 147)',
//   },
// ]
export const masterStatusList = [
  {
    value: 'Select Status',
    label: 'Select Status',
    color: 'rgb(121, 126, 147)',
  },
  {
    value: 'Waiting On Google',
    label: 'Waiting On Google',
    color: 'rgb(161, 227, 246)',
  },
  {
    value: 'In Progress',
    label: 'In Progress',
    color: 'rgb(253, 188, 100)',
  },
  {
    value: 'DMS Attention',
    label: 'DMS Attention',
    color: 'rgb(255, 131, 88)',
  },
  {
    value: 'AM Attention',
    label: 'AM Attention',
    color: 'rgb(51, 158, 205)',
  },
  {
    value: 'Need Client Feedback',
    label: 'Need Client Feedback',
    color: 'rgb(133, 214, 255)',
  },
  {
    value: 'Done',
    label: 'Done',
    color: 'rgb(51, 211, 145)',
  },
  {
    value: 'AM Follow Up',
    label: 'AM Follow Up',
    color: 'rgb(43, 118, 229)',
  },
  {
    value: 'Ready To Launch',
    label: 'Ready To Launch',
    color: 'rgb(121, 175, 253)',
  },
  {
    value: 'Postdated (AM)',
    label: 'Postdated (AM)',
    color: 'rgb(181, 125, 227)',
  },
  {
    value: 'Postdated (DMS)',
    label: 'Postdated (DMS)',
    color: 'rgb(147, 111, 218)',
  },
  {
    value: 'On Hold',
    label: 'On Hold',
    color: 'rgb(201, 92, 118)',
  },
  {
    value: 'Waiting on Creative',
    label: 'Waiting on Creative',
    color: 'rgb(92, 92, 92)',
  },
  {
    value: 'DMS SLA Change',
    label: 'DMS SLA Change',
    color: 'rgb(119, 122, 229)',
  },
  {
    value: 'AM SLA Change',
    label: 'AM SLA Change',
    color: 'rgb(53, 153, 112)',
  },
  {
    value: 'Ready to QA',
    label: 'Ready to QA',
    color: 'rgb(113, 214, 209)',
  },
  {
    value: 'SEO Attention',
    label: 'SEO Attention',
    color: 'rgb(232, 105, 125)',
  },
  {
    value: 'PM Attention',
    label: 'PM Attention',
    color: 'rgb(78, 115, 167)',
  },
  {
    value: 'Needs MI Attention',
    label: 'Needs MI Attention',
    color: 'rgb(157, 153, 185)',
  },
  {
    value: 'Waiting On Ad Approvals',
    label: 'Waiting On Ad Approvals',
    color: 'rgb(255, 68, 161)',
  },
  {
    value: 'Churn',
    label: 'Churn',
    color: 'rgb(255, 123, 208)',
  },
  {
    value: 'Ready to QA - Google Search',
    label: 'Ready to QA - Google Search',
    color: 'rgb(251, 180, 244)',
  },
  {
    value: 'Ready to QA - FB/IG',
    label: 'Ready to QA - FB/IG',
    color: 'rgb(255, 189, 189)',
  },
  {
    value: 'Ready to QA - GDN',
    label: 'Ready to QA - GDN',
    color: 'rgb(217, 116, 176)',
  },
  {
    value: 'Ready to QA - Simplifi',
    label: 'Ready to QA - Simplifi',
    color: 'rgb(189, 168, 249)',
  },
  {
    value: 'Ready to QA - TikTok',
    label: 'Ready to QA - TikTok',
    color: 'rgb(255, 145, 145)',
  },
  {
    value: 'Ready to QA - Other',
    label: 'Ready to QA - Other',
    color: 'rgb(169, 190, 232)',
  },
  {
    value: 'N/A',
    label: 'N/A',
    color: 'rgb(121, 126, 147)',
  },
]
