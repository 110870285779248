import React, { useState, useEffect } from 'react'
import { Grid, Typography, Card, useTheme } from '@mui/material'
import { ncoDependenciesList, dependenciesNameMapping } from './ncoTaskObjects'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

const Channels = ({ handleChange }) => {
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const deal = queryClient.getQueryData(['deal', dealId])
  // Initialize statuses based on the dependency list and deal data
  const initializeSelectedStatuses = () => {
    const initialStatuses = {}
    ncoDependenciesList[0].Dependency.forEach(dep => {
      const key = Object.keys(dep)[0]
      initialStatuses[key] = deal?.[key] || 'N/A' // Default status
    })
    return initialStatuses
  }

  const [selectedStatuses, setSelectedStatuses] = useState(initializeSelectedStatuses())

  useEffect(() => {
    setSelectedStatuses(initializeSelectedStatuses())
  }, [deal, ncoDependenciesList])

  const handleCardClick = (dependency, statusOptions) => {
    const currentIndex = statusOptions.findIndex(option => option.name === selectedStatuses[dependency])
    const nextIndex = (currentIndex + 1) % statusOptions.length // Cycle through statuses
    const updatedStatus = statusOptions[nextIndex].name

    setSelectedStatuses(prev => ({
      ...prev,
      [dependency]: updatedStatus,
    }))

    handleChange(dependency, updatedStatus)
  }

  return (
    <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      {ncoDependenciesList[0].Dependency.map((dep, index) => {
        const dependencyKey = Object.keys(dep)[0]
        const dependencyName = dependenciesNameMapping[dependencyKey] || dependencyKey
        const statusOptions = dep[dependencyKey]
        const currentStatus = selectedStatuses[dependencyKey]
        const currentStatusColor =
          statusOptions.find(option => option.name === currentStatus)?.color || theme.palette.grey[300]

        return (
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={2}
            xl={2}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}
          >
            <Card
              onClick={() => handleCardClick(dependencyKey, statusOptions)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                // padding: theme.spacing(2),
                textAlign: 'center',
                backgroundColor: currentStatusColor,
                color: theme.palette.getContrastText(currentStatusColor),
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: `0px 0px 12px ${theme.palette.grey[400]}`,
                },
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                minHeight: 55, // Fixed height for consistency
                height: {
                  xs: 55,
                  sm: 55,
                  md: 65,
                  lg: 65,
                  xl: 65, // Ensures text does not exceed card width
                },
                minWidth: 50, // Optional for ensuring consistent width
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%', // Ensures text does not exceed card width
                },
              }}
            >
              <Typography
                variant='body1'
                noWrap
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: {
                    xs: '0.9rem',
                    sm: '0.9rem',
                    md: '0.8rem',
                    lg: '1rem',
                    xl: '1rem',
                  }, // Responsive font size
                }}
              >
                {dependencyName}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 'bold',
                  mt: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: {
                    xs: '1rem',
                    sm: '0.8rem',
                    md: '0.8rem',
                    lg: '0.8rem',
                    xl: '0.8rem',
                  }, // Responsive font size
                }}
              >
                {currentStatus}
              </Typography>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Channels

// import React, { useState, useEffect } from 'react'
// import { Grid, Typography, Card, Checkbox, FormControlLabel, useTheme } from '@mui/material'
// import { ncoDependenciesList, dependenciesNameMapping } from './ncoTaskObjects'

// const Channels = ({ deal, handleChange }) => {
//   const theme = useTheme()

//   // Initialize statuses based on the dependency list and deal data
//   const initializeSelectedStatuses = () => {
//     const initialStatuses = {}
//     ncoDependenciesList[0].Dependency.forEach(dep => {
//       const key = Object.keys(dep)[0]
//       initialStatuses[key] = deal[key] || 'N/A' // Default status
//     })
//     return initialStatuses
//   }

//   const [selectedStatuses, setSelectedStatuses] = useState(initializeSelectedStatuses())

//   useEffect(() => {
//     setSelectedStatuses(initializeSelectedStatuses())
//   }, [deal, ncoDependenciesList])

//   const handleCardClick = (dependency, statusOptions) => {
//     const currentIndex = statusOptions.findIndex(option => option.name === selectedStatuses[dependency])
//     const nextIndex = (currentIndex + 1) % statusOptions.length // Cycle through statuses
//     const updatedStatus = statusOptions[nextIndex].name

//     setSelectedStatuses(prev => ({
//       ...prev,
//       [dependency]: updatedStatus,
//     }))

//     handleChange(dependency, updatedStatus)
//   }

//   return (
//     <Grid container spacing={0} alignItems='center'>
//       {ncoDependenciesList[0].Dependency.map((dep, index) => {
//         const dependencyKey = Object.keys(dep)[0]
//         const dependencyName = dependenciesNameMapping[dependencyKey] || dependencyKey
//         const statusOptions = dep[dependencyKey]
//         const currentStatus = selectedStatuses[dependencyKey]
//         const currentStatusColor =
//           statusOptions.find(option => option.name === currentStatus)?.color || theme.palette.grey[300]

//         return (
//           <Grid item xs={12} sm={2} md={6} lg={4} xl={2} key={index}>
//             <Card
//               onClick={() => handleCardClick(dependencyKey, statusOptions)}
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 padding: 1,
//                 margin: theme.spacing(2),
//                 textAlign: 'center',
//                 backgroundColor: currentStatusColor,
//                 color: theme.palette.getContrastText(currentStatusColor),
//                 cursor: 'pointer',
//                 '&:hover': {
//                   transform: 'scale(1.02)',
//                   boxShadow: `0px 0px 12px ${theme.palette.grey[400]}`,
//                 },
//                 transition: 'transform 0.2s ease, box-shadow 0.2s ease',
//                 height: 75,
//                 overflow: 'hidden',
//                 alignItems: 'center',
//               }}
//             >
//               <Typography>{dependencyName}</Typography>
//               <Typography variant='body2' sx={{ fontWeight: 'bold', mt: 1 }}>
//                 {currentStatus}
//               </Typography>
//             </Card>
//           </Grid>
//         )
//       })}
//     </Grid>
//   )
// }

// export default Channels
