import React, { createContext, useContext, useState } from 'react'

const ValidationContext = createContext()

export const ValidationProvider = ({ children }) => {
  const [errors, setErrors] = useState({})
  const [errorKeys, setErrorKeys] = useState([])
  const [strategyErrors, setStrategyErrors] = useState({})
  const [isStrategyValid, setIsStrategyValid] = useState(false)
  const hasErrors = Object.values(errors).some(error => error)
  const errorStyle = {
    borderColor: 'red',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  }
  // const isValidWebsite = url => {
  //   // console.log('Validating: ', url)
  //   const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/.*)?$/
  //   // console.log('isValidUrl? : ', pattern.test(url))
  //   return pattern.test(url)
  // }
  const isValidWebsite = url => {
    // Trim the URL and remove any non-printable characters
    const trimmedUrl = url.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') // Removes zero-width characters

    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol (optional)
      "((([a-zA-Z0-9$_.+!*'(),;:&=~-]+)@)?" + // authentication (optional)
      '([a-zA-Z0-9-]+\\.)*' + // subdomain (optional)
      '([a-zA-Z0-9-]{1,63}\\.[a-zA-Z]{2,6}))' + // main domain and extension
      '(\\:\\d+)?' + // port (optional)
      '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path (optional)
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-zA-Z0-9_]*)?$' // fragment (optional)
    )

    return pattern.test(trimmedUrl)
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        return value ? '' : 'Name is required'
      case 'email':
        return /\S+@\S+\.\S+/.test(value) ? '' : 'Email is invalid'
      case 'website': {
        if (!value) return ''

        // Trim and sanitize URL before validation
        const trimmedValue = value.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') // Remove zero-width characters

        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol (optional)
          "((([a-zA-Z0-9$_.+!*'(),;:&=~-]+)@)?" + // authentication (optional)
          '([a-zA-Z0-9-]+\\.)*' + // subdomain (optional, can be multiple levels)
          '([a-zA-Z0-9-]{1,63}\\.[a-zA-Z]{2,6}))' + // main domain and extension
          '(\\:\\d+)?' + // port (optional)
          '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path (optional)
          '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
            '(\\#[-a-zA-Z0-9_]*)?$' // fragment (optional)
        )

        return urlPattern.test(trimmedValue) ? '' : 'Please enter a valid URL'
      }
      case 'vertical':
        return value ? '' : 'Vertical is required'
      default:
        return ''
    }
  }
  const validateStrategy = async (strategy, deal) => {
    // console.log('[validateStrategy] strategyReceived: ', strategy)
    const csaTypes = ['CSA', 'RCSA']
    // const errors = {
    //   street: strategy?.addtlInfo?.street ? '' : 'Street is required (Complete on organization)',
    //   city: strategy?.addtlInfo?.city ? '' : 'City is required (Complete on organization)',
    //   state: strategy?.addtlInfo?.state ? '' : 'State is required (Complete on organization)',
    //   zip: strategy?.addtlInfo?.zip ? '' : 'ZIP is required (Complete on organization)',
    //   cmsUsed: strategy?.addtlInfo?.cmsUsed ? '' : 'CMS Used is required',
    //   productsAndServices: strategy?.addtlInfo?.productsAndServices ? '' : 'Products and Services are required',
    //   demographicTargeting: strategy?.addtlInfo?.demographicTargeting ? '' : 'Demographic Targeting is required',
    //   marketingObjective: strategy?.addtlInfo?.marketingObjective ? '' : 'Marketing Objective is required',
    //   geoTargeting: strategy?.addtlInfo?.geoTargeting ? '' : 'Geo Targeting is required',
    //   digitalAdPhone: strategy?.addtlInfo?.digitalAdPhone ? '' : 'Digital Ad Phone is required',
    //   website: strategy?.generalInfo?.website ? '' : 'Website is required',
    // }
    const errors =
      deal && deal?.type && csaTypes.includes(deal?.type)
        ? {
            street: { value: !strategy?.addtlInfo?.street, errorText: 'Street is required (Complete on organization)' },
            city: { value: !strategy?.addtlInfo?.city, errorText: 'City is required (Complete on organization)' },
            state: { value: !strategy?.addtlInfo?.state, errorText: 'State is required (Complete on organization)' },
            zip: { value: !strategy?.addtlInfo?.zip, errorText: 'ZIP is required (Complete on organization)' },
            cmsUsed: { value: !strategy?.addtlInfo?.cmsUsed, errorText: 'CMS Used is required' },
            productsAndServices: {
              value: !strategy?.addtlInfo?.productsAndServices,
              errorText: 'Products and Services are required',
            },
            demographicTargeting: {
              value: !strategy?.addtlInfo?.demographicTargeting,
              errorText: 'Demographic Targeting is required',
            },
            marketingObjective: {
              value: !strategy?.addtlInfo?.marketingObjective,
              errorText: 'Marketing Objective is required',
            },
            geoTargeting: { value: !strategy?.addtlInfo?.geoTargeting, errorText: 'Geo Targeting is required' },
            website: { value: !strategy?.generalInfo?.website, errorText: 'Website is required' },
          }
        : {
            street: { value: !strategy?.addtlInfo?.street, errorText: 'Street is required (Complete on organization)' },
            city: { value: !strategy?.addtlInfo?.city, errorText: 'City is required (Complete on organization)' },
            state: { value: !strategy?.addtlInfo?.state, errorText: 'State is required (Complete on organization)' },
            zip: { value: !strategy?.addtlInfo?.zip, errorText: 'ZIP is required (Complete on organization)' },
            cmsUsed: { value: !strategy?.addtlInfo?.cmsUsed, errorText: 'CMS Used is required' },
            productsAndServices: {
              value: !strategy?.addtlInfo?.productsAndServices,
              errorText: 'Products and Services are required',
            },
            demographicTargeting: {
              value: !strategy?.addtlInfo?.demographicTargeting,
              errorText: 'Demographic Targeting is required',
            },
            marketingObjective: {
              value: !strategy?.addtlInfo?.marketingObjective,
              errorText: 'Marketing Objective is required',
            },
            geoTargeting: { value: !strategy?.addtlInfo?.geoTargeting, errorText: 'Geo Targeting is required' },
            digitalAdPhone: { value: !strategy?.addtlInfo?.digitalAdPhone, errorText: 'Digital Ad Phone is required' },
            website: { value: !strategy?.generalInfo?.website, errorText: 'Website is required' },
          }
    // Check each product in `updatedProducts` for required fields
    const productErrors = {}
    if (strategy?.updatedProducts && Array.isArray(strategy.updatedProducts)) {
      strategy.updatedProducts.forEach(product => {
        const productError = {}
        if (!product.creativeSuppliedBy) productError.creativeSuppliedBy = 'Required'
        if (!product.copySuppliedBy) productError.copySuppliedBy = 'Required'
        if (!product.marketingObjective) productError.marketingObjective = 'Required'
        if (Object.keys(productError).length > 0) {
          productErrors[product.instanceId] = productError
        }
      })
    }
    setStrategyErrors({ ...errors })

    const newErrorKeys = Object.entries(errors)
      .filter(([key, { value }]) => value === true) // Filter out only the keys where the value is true
      .map(([key, { value }]) => ({ key, value }))
    // Map the filtered entries to objects
    setErrorKeys(newErrorKeys)

    return { isValid: !Object.values(errors).some(Boolean), errors: errors }
  }
  const handleValidateStrategy = (strategy, deal) => {
    const { isValid, errors } = validateStrategy(strategy, deal)
    return { isValid, errors }
  }

  return (
    <ValidationContext.Provider
      value={{
        validateStrategy,
        validateField,
        isValidWebsite,
        errors,
        setErrors,
        errorKeys,
        setErrorKeys,
        hasErrors,
        errorStyle,
        strategyErrors,
        isStrategyValid,
        handleValidateStrategy,
      }}
    >
      {children}
    </ValidationContext.Provider>
  )
}

export const useValidationContext = () => useContext(ValidationContext)
