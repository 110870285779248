import React, { useState, useEffect, useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { filterPanelProps, gridContainerStyles } from './style'
import LoadingOverlay from '../Loading/LoadingOverlay'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useUsers } from '../../api/aws/useUsers'
import { renderApprovalsColumns } from '../DataGrid/createColumns/renderApprovalsColumns'
import CustomApprovalsToolbar from './CustomApprovalsToolbar'
import { calculateProgress } from './calculateProgress'
import { ApprovalsGridFooter } from './ApprovalsGridFooter'
import { useDeals } from '../../api/aws/useDeals'

const initialSortModel = [
  { field: 'isApproved', sort: 'asc' },
  { field: 'requestedTime', sort: 'desc' },
  { field: 'approvedTime', sort: 'desc' },
]

const ApprovalsPage = () => {
  const theme = useTheme()
  const { deals, isDealsLoading, isDealsError } = useDeals(true, ['approvals', 'deals'])
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['approvals', 'users'])
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['approvals', 'organizations'])
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const [isUpdating, setIsUpdating] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // Load filters from localStorage or use an empty default filter model
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('approvalsFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('approvalsVisibilityModel')
    return savedVisibility ? JSON.parse(savedVisibility) : {}
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('approvalsSortModel')
    return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
  })
  // Function to check if the sort model has changed
  const isSortModelChanged = sortModel.some((sort, index) => {
    const initialSort = initialSortModel[index]
    return sort.field !== initialSort?.field || sort.sort !== initialSort?.sort
  })
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [density, setDensity] = useState('standard')
  const [selectionModel, setSelectionModel] = useState([])
  const [massUpdateData, setMassUpdateData] = useState({
    vertical: [],
    type: '',
    status: '',
  })
  const [rowCount, setRowCount] = useState(!isOrganizationsLoading ? organizations?.length : 0)
  const apiRef = useGridApiRef()

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('approvalsFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (selectionModel.length === 1) {
      const newOrganization = organizations.find(org => org.id === selectionModel[0])
      setSelectedOrganization(newOrganization)
      setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedOrganization(null)
    }
  }, [selectionModel, organizations])
  useEffect(() => {
    const savedFilters = localStorage.getItem('approvalsFilterModel')
    if (savedFilters) {
      setFilterModel(JSON.parse(savedFilters))
    }
  }, []) // Run only on mount
  const processedDeals = useMemo(() => {
    if (!deals) return []
    const filteredDeals = deals.filter(
      deal =>
        deal &&
        deal.isRequested &&
        deal.status !== 'Completed Internally' &&
        Array.isArray(deal.approvers) && // Ensures the deal has approvers
        deal.approvers.length > 0 // Ensures the approvers array is not empty
    )
    return filteredDeals.map(deal => {
      const progressData = calculateProgress(deal.approvers)
      return {
        ...deal,
        progress: progressData.individualProgress,
        progressPercentage: progressData.progressPercentage || 0, // Default to 0 if undefined
      }
    })
  }, [deals])

  const isLoading = isOrganizationsLoading || isUsersLoading || isDealsLoading

  if (isLoading) {
    return <DetailsLoading />
  }
  const columns = renderApprovalsColumns(processedDeals, users, theme, density, columnVisibilityModel)

  const handleFilterModelChange = model => {
    if (JSON.stringify(model) !== JSON.stringify(filterModel)) {
      setFilterModel(model)
    }
  }
  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('approvalsSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleSetOpenApprovals = e => {
    e.preventDefault()
    setFilterModel({
      items: [{ field: 'progressPercentage', operator: 'notContains', value: '100' }], // Filter out 100% completed
      quickFilterValues: [],
    })
    setSortModel([...initialSortModel])
    localStorage.setItem('approvalsSortModel', JSON.stringify(initialSortModel))
  }

  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {}
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('approvalsVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ...gridContainerStyles,
          height: 'calc(100vh - 20px)',
        }}
      >
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: {},
            },
          }}
          rows={processedDeals}
          columns={columns}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid}
          // checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('approvalsVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('approvalsSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          apiRef={apiRef}
          slots={{
            toolbar: CustomApprovalsToolbar,
            footer: ApprovalsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              setFilterModel: setFilterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              isSortModelChanged: isSortModelChanged,
              onOpenApprovals: handleSetOpenApprovals,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
            },
            footer: {
              context: 'approvals',
              massUpdateData: massUpdateData,
              selectionModel: selectionModel,
              rowCount,
              approvals: processedDeals,
              anchorEl: anchorEl,
              setSelectionModel: setSelectionModel,
              apiRef: apiRef,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          // headerFilters
          loading={isLoading}
        />
      </Box>
      <LoadingOverlay open={isUpdating} message={'Updating Organizations'} />
    </>
  )
}

export default ApprovalsPage
