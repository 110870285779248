export const renderTimelineText = action => {
  switch (action) {
    case 'Approve':
    case 'approve':
      return 'Approve'
    case 'Resolve':
    case 'resolve':
      return 'Resolve'
    case 'Reject':
    case 'reject':
      return 'Reject'
    case 'Request':
    case 'request':
      return 'Request'
    case 'Creation':
    case 'creation':
    case 'Create':
      return 'Creation'
    case 'Approval':
    case 'approval':
      return 'Approval'
    case 'Deals':
    case 'deals':
      return 'Deals'
    case 'Save':
      return 'Save'
    case 'Update':
      return 'Update'
    case 'Update':
    case 'update':
    case 'productUpdate':
      return 'Update'
    case 'Product Update':
      return 'Product Update'
    case 'Modify':
      return 'Modified'
    case 'Generate':
      return 'Generate'
    case 'document':
    case 'Generation':
      return 'Document'
  }
}
