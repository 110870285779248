import React from 'react'
import { Box, Typography, Grid, CircularProgress, useTheme } from '@mui/material'
import { useOrganizationsById } from '../../api/customHooks/useOrganizationsById'
import RenderParentOrganizationLink from '../Reusable/RenderParentOrganizationLink'
import RenderPropertyLink from '../Reusable/RenderPropertyLink'
import {
  InfoBox,
  InfoRow,
  InfoLabel,
  InfoValueWrapper,
  InfoValue,
  StyledLink,
  Underline,
  CityStateZipRow,
} from './style'
import { useUsersById } from '../../api/customHooks/useUsersById'
import UserImageComponent from '../Users/UserImageComponent'
import EditIconButton from '../ui/edit-icon-button'
import { formatPhoneNumber } from './formatPhoneNumber'
import { useGetQueryByIds } from '../../api/aws/useGetQueryByIds'

const ContactInfoCard = ({ contact, onSave, onEdit }) => {
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const textColor = theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0, 1)'
  const organizationIds = contact && Array.isArray(contact.organizations) ? contact.organizations.map(id => id) : []
  const userIds = contact && Array.isArray(contact.owner) ? contact.owner.map(id => id) : []
  // const currentOrganizations = useOrganizationsById(organizationIds)
  const {
    organizations: currentOrganizations,
    isOrganizationsLoading: isCurrentOrganizationsLoading,
    isOrganizationsError: isCurrentOrganizationsError,
  } = useOrganizationsById(organizationIds || [])
  const { users, isUsersByIdLoading, isUsersByIdError } = useUsersById(userIds)
  return (
    <>
      <InfoBox sx={{ backgroundColor: gridBgColor }}>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <EditIconButton variant='contained' onEdit={onEdit}>
            Edit Contact
          </EditIconButton>
        </Box>
        <Typography variant='h6' gutterBottom>
          Information
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <InfoRow>
              <InfoLabel>Owner</InfoLabel>
              <InfoValueWrapper>
                {users && Array.isArray(users) ? (
                  users.map(user => <UserImageComponent key={user.id || user.user_uuid} imageUrl={user.image_512} />)
                ) : (
                  <Typography>{'No Owner Assigned'}</Typography>
                )}
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Name</InfoLabel>
              <InfoValueWrapper>
                <InfoValue>{contact.name}</InfoValue>
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Title</InfoLabel>
              <InfoValueWrapper>
                <InfoValue>{contact.title || 'N/A'}</InfoValue>
              </InfoValueWrapper>
              <Underline />
            </InfoRow>
            <InfoRow>
              <InfoLabel>Organizations</InfoLabel>
              <InfoValueWrapper>
                {currentOrganizations ? (
                  currentOrganizations.map(organization => (
                    <StyledLink
                      key={organization.id || organization.org_uuid}
                      to={`/organizations/${organization.id || organization.org_uuid}`}
                    >
                      <RenderPropertyLink organization={organization} />
                    </StyledLink>
                  ))
                ) : (
                  <Typography>{'No Organization Assigned'}</Typography>
                )}
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Related Organizations</InfoLabel>
              <InfoValueWrapper>
                {currentOrganizations ? (
                  currentOrganizations.map(organization => (
                    <StyledLink
                      key={organization.id || organization.org_uuid}
                      to={`/organizations/${organization.id || organization.org_uuid}`}
                    >
                      <RenderParentOrganizationLink organization={organization} />
                    </StyledLink>
                  ))
                ) : (
                  <Typography>{'No Related Organizations Assigned'}</Typography>
                )}
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoRow>
              <InfoLabel>Mobile Phone</InfoLabel>
              {contact && contact.phone ? (
                <Typography sx={{ fontWeight: 'bold' }}>{formatPhoneNumber(contact.phone)}</Typography>
              ) : (
                <Typography sx={{ color: 'rgb(129, 127, 127)' }}>No Phone</Typography>
              )}
            </InfoRow>
            <InfoRow>
              <InfoLabel>Office Phone</InfoLabel>
              <InfoValueWrapper>
                {contact && contact.officePhone ? (
                  <Typography sx={{ fontWeight: 'bold' }}>{formatPhoneNumber(contact.officePhone)}</Typography>
                ) : (
                  <Typography sx={{ color: 'rgb(129, 127, 127)' }}>No Phone</Typography>
                )}
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
            <CityStateZipRow>
              <InfoValueWrapper>
                <InfoLabel>Email</InfoLabel>
                <InfoValue>{contact.email || 'N/A'}</InfoValue>
                <Underline />
              </InfoValueWrapper>
              <InfoValueWrapper>
                <InfoLabel>Billing Email</InfoLabel>
                <InfoValue>{contact.billingEmail || 'N/A'}</InfoValue>
                <Underline />
              </InfoValueWrapper>
            </CityStateZipRow>
            <InfoRow>
              <InfoLabel>Linkedin</InfoLabel>
              <InfoValueWrapper>
                <InfoValue>{contact.linkedinProfile || 'N/A'}</InfoValue>
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
            {contact && contact.street && (
              <InfoRow>
                <InfoLabel>Street</InfoLabel>
                <InfoValue>{contact.street || 'N/A'}</InfoValue>
              </InfoRow>
            )}
            <CityStateZipRow>
              {contact && contact.city && (
                <Box sx={{ flex: 2 }}>
                  <InfoLabel>City</InfoLabel>
                  <InfoValueWrapper>
                    <InfoValue>{contact.city || 'N/A'}</InfoValue>
                  </InfoValueWrapper>
                </Box>
              )}
              {contact && contact.state && (
                <Box sx={{ flex: 1, mx: 2 }}>
                  <InfoLabel>State</InfoLabel>
                  <InfoValueWrapper>
                    <InfoValue>{contact.state || 'N/A'}</InfoValue>
                  </InfoValueWrapper>
                </Box>
              )}
              {contact && contact.zip && (
                <Box sx={{ flex: 1 }}>
                  <InfoLabel>Zip</InfoLabel>
                  <InfoValueWrapper>
                    <InfoValue>{contact.zip || 'N/A'}</InfoValue>
                  </InfoValueWrapper>
                </Box>
              )}
              {contact && !contact.street && !contact.city && !contact.state && !contact.zip && (
                <InfoValueWrapper>
                  <Typography>No Address Info Available</Typography>
                </InfoValueWrapper>
              )}
              <Underline />
            </CityStateZipRow>
          </Grid>
        </Grid>
      </InfoBox>
    </>
  )
}

export default ContactInfoCard
