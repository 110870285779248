import React, { createContext, useContext, useState, useEffect } from 'react'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useLeadsById } from '../../api/customHooks/useLeadsById'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { useSnackbarContext } from '../SnackbarProvider/SnackbarProvider'
import { useLeads } from '../../api/aws/useLeads'

const LeadsContext = createContext()

export const LeadsProvider = ({ children }) => {
  const { showSnackbar } = useSnackbarContext()
  const [selectedLeads, setSelectedLeads] = useState(null)
  const [selectedLead, setSelectedLead] = useState(null)
  const [leadId, setLeadId] = useState(null)
  const [leadIds, setLeadIds] = useState([])

  const { data: leads, error: leadsError, isLoading: leadsLoading } = useLeads()
  const { data: fetchedLeads, isLoading: leadsByIdLoading } = useLeadsById(leadIds)
  const { data: fetchedLead, isLoading: leadByIdLoading } = useLeadsById(leadId ? [leadId] : [])

  const { mutate: deleteLead, isLoading: isDeleting } = useDeleteMutation()

  const fetchLeadsForOrganization = async ids => {
    setLeadIds(ids) // Update the leadIds state to trigger the useLeadsById hook
  }

  const handleDeleteLead = async leadId => {
    if (!leadId) return
    deleteLead(
      { endpoint: `/aws/delete/leads/${leadId}`, table: 'leads' },
      {
        onSuccess: message => {
          showSnackbar(message, 'success')
          setSelectedLead(null)
          // Optionally, refetch leads or update state to remove the deleted lead
        },
        onError: error => {
          showSnackbar(error.message, 'error')
        },
      }
    )
  }

  useEffect(() => {
    if (fetchedLeads) {
      setSelectedLeads(fetchedLeads)
    }
  }, [fetchedLeads])

  useEffect(() => {
    if (fetchedLead) {
      setSelectedLead(fetchedLead)
    }
  }, [fetchedLead])

  const leadsState = {
    leads,
    leadsLoading,
    leadsByIdLoading,
    selectedLeads,
    setSelectedLeads,
    selectedLead,
    setSelectedLead,
    fetchLeadsForOrganization,
    setLeadId,
    handleDeleteLead, // Add the delete function to the context
    isDeleting, // Add the deleting state to the context
  }

  return <LeadsContext.Provider value={leadsState}>{children}</LeadsContext.Provider>
}

export const useLeadsContext = () => useContext(LeadsContext)
