import React, { useRef, useState } from 'react'
import { Autocomplete, TextField, Chip, Typography, Box, useTheme } from '@mui/material'
import { contactChipStyle } from '../ContractDialog/style'
import { useContacts } from '../../api/aws/useContacts'

const ContactRecipientAutocomplete = ({ value, label, onChange, error, isDraft }) => {
  const theme = useTheme()
  const inputRef = useRef(null) // To manage focus programmatically
  const [isOpen, setIsOpen] = useState(false)
  const { contacts: recipients, isContactsLoading, isContactsError } = useContacts(true, [
    'contactRecipientsAutocomplete',
    'contacts',
  ])
  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(value || [])

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue) // Trigger the update in the parent component
  }

  const handleRemoveUser = contactId => {
    const newSelectedContacts = selectedUsers.filter(
      contact => contact.id !== contactId && contact.contacts_uuid !== contactId
    )
    setSelectedUsers(newSelectedContacts)
    onChange(newSelectedContacts)
    if (inputRef.current) {
      inputRef.current.focus() // Keep the input focused after removing a user
    }
    setIsOpen(true) // Reopen the dropdown
  }

  return (
    <Autocomplete
      multiple
      open={isOpen}
      id='contact-recipients-autocomplete'
      disabled={!isDraft ?? false}
      options={recipients || []}
      getOptionLabel={option => `${option.firstName} ${option.lastName} (${option.email})`}
      value={selectedUsers}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onChange={handleSelectUser}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      filterOptions={(options, { inputValue }) => {
        return options.filter(option =>
          `${option.firstName} ${option.lastName}`.toLowerCase().includes(inputValue.toLowerCase())
        )
      }}
      renderOption={(props, option) => {
        const firstName = option.firstName || option.first_name
        const lastName = option.lastName || option.last_name
        return (
          <Box
            {...props}
            key={option.id || option.contacts_uuid}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography flex={1} sx={{ textAlign: 'left', width: '100%' }}>{`${firstName} ${lastName}`}</Typography>
            {option?.email && (
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '11px',
                  fontStyle: 'italic',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                {option.email}
              </Typography>
            )}

            {option?.panda_contact_id && (
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: '11px',
                  fontStyle: 'italic',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                {option.panda_contact_id}
              </Typography>
            )}
          </Box>
        )
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, onDelete, ...rest } = getTagProps({ index })
          const firstName = option.firstName || option.first_name
          const lastName = option.lastName || option.last_name
          return (
            <Chip
              key={key}
              label={`${firstName} ${lastName}`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id || option.contacts_uuid)}
              sx={contactChipStyle(theme)}
            />
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          placeholder={selectedUsers?.length > 0 ? 'Addtl Recipients' : 'Select Contact Recipient'}
          inputRef={inputRef} // Attach the ref to the input
          InputLabelProps={{
            shrink: true, // Keeps the label in the "focused" state
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important', // Ensure border is removed
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text.secondary, // Gray color for placeholder
              fontStyle: 'italic', // Optional: make it italic
            },
          }}
        />
      )}
      fullWidth
    />
  )
}

export default ContactRecipientAutocomplete
