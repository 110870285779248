import React from 'react'
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
const CancelContractButton = ({ onClick, isSaving }) => {
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isLargeScreen || isExtraLargeScreen || isMediumScreen || isSmallScreen ? (
        <Button
          sx={{
            width: '100%',
            height: '100%',
            minHeight: 42,
            borderColor: theme.palette.icon.decline,
            zIndex: 20,
          }}
          // disabled={isRequesting || isRequested || !isValid}
          disabled={isSaving}
          onClick={onClick}
          name='cancel-contract-button'
          variant='outlined'
          color='primary'
        >
          <Typography>Cancel</Typography>
        </Button>
      ) : (
        <Tooltip title={<Typography>Cancel</Typography>}>
          <IconButton
            onClick={onClick}
            disabled={isSaving}
            size={'medium'}
            sx={{
              borderRadius: '50px',
              border: `2px solid ${theme.palette.icon.decline}`,
            }}
          >
            <CloseIcon sx={{ fontSize: '34px' }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default CancelContractButton
