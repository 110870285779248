import React, { useEffect, useState, useRef, useMemo } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { ncoStatusFilters } from '../DataGrid/filterArrays'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import AddRoadIcon from '@mui/icons-material/AddRoad'

const initialSortModel = [
  { field: 'ncoStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(251, 193, 30)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
}
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const NcoGridToolbar = ({
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  context,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridFilterBgColor = theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'
  const slotPropsColor = theme.palette.text.primary
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [ncoFilterStatus, setNcoFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  const isSortModelChanged = useMemo(() => {
    return !isSortModelEqual(sortModel, initialSortModel)
  }, [sortModel, initialSortModel])

  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetFilter = (fieldName, newValue) => {
    const updatedFilters = filterModel.items.filter(item => item.field !== fieldName)

    updatedFilters.push({
      field: fieldName,
      operator: 'contains',
      value: newValue,
    })

    setFilterModel({
      ...filterModel,
      items: updatedFilters,
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setNcoFilterStatus('')
    setFilterStage('')
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 2 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CustomStatusFilter
            context={'ncoStatus'}
            options={ncoStatusFilters}
            setterFn={setNcoFilterStatus}
            filterModel={filterModel}
            value={ncoFilterStatus}
            onChange={handleSetFilter}
          />
          <Button
            sx={{
              height: 40,
              color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
            }}
            onClick={handleFilterMyData}
          >
            My Deals
          </Button>
          <ResetColumnsButton
            visibilityModel={visibilityModel}
            onClearVisibility={onClearVisibility}
            initialVisibilityState={initialVisibilityState}
          />
          <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
          <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
          <Badge
            badgeContent={
              <>
                {filterModel &&
                filterModel.quickFilterValues &&
                Array.isArray(filterModel.quickFilterValues) &&
                filterModel.quickFilterValues.length > 0 ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                      bgcolor: 'rgb(251, 193, 30)',
                      p: 0.5,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      display: 'flex', // Flexbox centering
                      alignItems: 'center', // Vertically center
                      justifyContent: 'center', // Horizontally center
                      color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                      fontWeight: 'bold',
                    }}
                  >
                    {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                      ? filterModel.quickFilterValues.length
                      : null}
                  </Typography>
                ) : null}
              </>
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
          </Badge>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default NcoGridToolbar
