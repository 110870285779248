import React from 'react'
import {
  Box,
  DialogTitle,
  Chip,
  Typography,
  Divider,
  useTheme,
} from '@mui/material'
import {
  boxOneStyle,
  boxTwoStyle,
  boxThreeStyle,
  boxFourStyle,
  dialogTitleStyle,
} from './style'
import GrboboticLight from '../../images/Grobotic png-lightmode.png'
import GrboboticDark from '../../images/Grobotic png.png'
import ThemedImage from '../../images/ThemedImage/ThemedImage'
import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'
import { capitalizeFirstLetter } from '../DataGrid/createColumns/renderDealsColumns'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { renderColorByString } from '../DataGrid/renderColorByString'

const ContractHeaderComponent = ({
  handleChange,
  validationErrors,
  organization,
}) => {
  const theme = useTheme()
  const titleColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const { newDeal, setNewDeal } = useDealDetailsContext()
  return (
    <>
      <Box
        key={
          newDeal && newDeal.id
            ? newDeal.id
            : newDeal && newDeal.deal_uuid
            ? newDeal.deal_uuid
            : ''
        }
        sx={boxOneStyle('30px')}
      >
        <ThemedImage srcLight={GrboboticLight} srcDark={GrboboticDark} />
        {organization ? (
          <DialogTitle sx={dialogTitleStyle('4rem', titleColor)}>
            {organization.name}
          </DialogTitle>
        ) : (
          <DialogTitle sx={dialogTitleStyle}>
            {'No Organization Found'}
          </DialogTitle>
        )}

        <Box sx={boxTwoStyle}>
          <Chip
            sx={{
              marginRight: 2,
              backgroundColor: renderColorByString(
                newDeal && newDeal.status ? newDeal.status : ''
              ),
            }}
            size="lg"
            label={
              <Typography>
                {newDeal && newDeal.status
                  ? capitalizeFirstLetter(newDeal.status)
                  : 'Set Status'}
              </Typography>
            }
          />
        </Box>
      </Box>
      <ContractInfoHeader
        // newDeal={newDeal}
        // setNewDeal={setNewDeal}
        handleChange={handleChange}
        validationErrors={validationErrors}
        organization={organization}
      />
    </>
  )
}

export default ContractHeaderComponent
