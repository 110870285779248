import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/queryMutations'

const createNotification = async notificationData => {
  const url = `${baseUrl}/aws/notifications`
  console.log('New Notification Data: ', notificationData)
  const response = await axios.post(url, notificationData)
  return response.data // Assumes the response data includes the new lead with `id`
}

export const useCreateNotification = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createNotification,
    onMutate: async newNotification => {
      await queryClient.cancelQueries(['notifications'])

      // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData(['notifications'])

      // Do not optimistically update here because we don't have the new `id` yet
      return { previousNotifications }
    },
    onError: (err, newNotification, context) => {
      queryClient.setQueryData(['notifications'], context.previousNotifications)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      queryClient.setQueryData(['notifications'], old => {
        if (!Array.isArray(old)) {
          // Initialize as an array if it's not already
          return [{ ...data, id: data.notify_uuid }]
        }
        // Append the new notification to the existing array
        return [...old, { ...data, id: data.notify_uuid }]
      })

      showSnackbar('Notification created', 'info')
    },
    onSettled: () => {
      queryClient.invalidateQueries(['notifications'])
    },
  })

  return mutation
}
