import React, { useEffect, useState } from 'react'
import { Box, Popover, Typography, useTheme } from '@mui/material'
import Notification from '../Notifications/Notification'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import ReadNotification from '../Notifications/ReadNotification'
import dayjs from 'dayjs'
import format from 'date-fns/format'

function formatDateTime(timestamp) {
  return format(new Date(timestamp), 'MMM dd h:mm a')
}

const NotificationDrawer = ({ userId, drawerOpen, closeDrawer, anchorEl }) => {
  const theme = useTheme()
  const { notifications, isNotificationsLoading } = useNotify()
  const [visuallyReadIds, setVisuallyReadIds] = useState([]) // Track IDs of visually read notifications
  const [unreadIds, setUnreadIds] = useState([]) // Track IDs of unread notifications to update on close
  // Track initial unread notifications
  useEffect(() => {
    if (notifications && !isNotificationsLoading) {
      const unreadNotifyIds = notifications.filter(notify => !notify.isRead).map(notify => notify.notify_uuid)
      setUnreadIds(unreadNotifyIds || [])
    }
  }, [notifications, isNotificationsLoading])

  const handleHover = notification => {
    if (!notification.isRead && !visuallyReadIds.includes(notification.notify_uuid)) {
      setVisuallyReadIds(prev => [...prev, notification.notify_uuid]) // Temporarily clear badge
    }
  }

  const handleClose = () => {
    closeDrawer(unreadIds.filter(id => visuallyReadIds.includes(id))) // Update only visually read notifications
    setVisuallyReadIds([]) // Reset visually read state
  }
  // Sort notifications by creation_timestamp in descending order (most recent first)
  const sortedUnreadNotifications = (notifications?.filter(notification => !notification.isRead) || []).sort(
    (a, b) => new Date(b.creation_timestamp) - new Date(a.creation_timestamp)
  )

  const sortedReadNotifications = (notifications?.filter(notification => notification.isRead) || []).sort(
    (a, b) => new Date(b.creation_timestamp) - new Date(a.creation_timestamp)
  )
  return (
    <Popover
      open={drawerOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disablePortal // Keeps the Popover within its parent hierarchy
      slotProps={{
        root: {
          sx: {
            pointerEvents: 'auto', // Ensure clicks outside can be captured to close
            zIndex: 1699,
          },
        },
        paper: {
          sx: {
            bgcolor: 'transparent', // Transparent background
            backdropFilter: 'blur(8px)', // Optional: adds blur effect
            boxShadow: 'none', // Removes default shadow
          },
        },
      }}
      sx={{
        pointerEvents: 'none', // Disables pointer events outside the Popover
      }}
    >
      <Box
        key='notifications-section'
        sx={{
          marginTop: 2,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '30vw',
          maxHeight: '90vh',
          boxShadow: '0px 6px 9px rgba(0, 0, 0, 0.4)',
          zIndex: 1500,
        }}
      >
        {/* Unread Notifications Section */}
        {sortedUnreadNotifications.length > 0 ? (
          <>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>Unread Notifications</Typography>
            {sortedUnreadNotifications.map(notification => (
              <Box key={`${userId}-${notification.notify_uuid}`}>
                <Notification
                  key={`${userId}-${notification.notify_uuid}`}
                  itemKey={notification.notify_uuid}
                  board={notification?.board ?? ''}
                  boardId={notification?.boardId}
                  boardName={notification?.boardName ?? ''}
                  imageUrl={notification?.imageUrl ?? null}
                  url={notification?.url ?? ''}
                  timestamp={notification?.creation_timestamp ? formatDateTime(notification?.creation_timestamp) : null}
                  message={notification?.message}
                  severity={notification?.severity}
                  title={notification?.title}
                  type={notification?.type}
                  userId={notification?.userId}
                  isRead={visuallyReadIds.includes(notification.notify_uuid)} // Use visuallyReadIds to control badge visibility
                  onHover={() => handleHover(notification)}
                />
              </Box>
            ))}
          </>
        ) : (
          <Box key={`${userId}-unread-empty-section-2`}>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                marginBottom: 1,
                color: theme.palette.text.primary,
              }}
            >
              Unread Notifications
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid',
                borderColor: theme.palette.text.primary,
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                cursor: 'pointer',
                width: '100%', // Ensure the notification takes the full width of its container
                backgroundColor: theme.palette.background.paper,
                transition: 'background-color 0.3s ease, opacity 0.3s ease',
              }}
            >
              <Typography>No New Notifications</Typography>
            </Box>
          </Box>
        )}

        <Box key={`${userId}-read-notifications-3`}>
          {/* Divider */}
          {sortedReadNotifications.length > 0 && sortedUnreadNotifications.length > 0 && (
            <Box
              key={`${userId}-read-notifications-section`}
              sx={{
                height: 1,
                bgcolor: theme.palette.divider,
                marginY: 2,
              }}
            />
          )}

          {/* Read Notifications Section */}
          {sortedReadNotifications.length > 0 && (
            <Box key={`${userId}-read-empty-section-4`}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginBottom: 1,
                  color: theme.palette.text.primary,
                }}
              >
                Read Notifications
              </Typography>
              {sortedReadNotifications.map(notification => (
                <React.Fragment key={notification.notify_uuid}>
                  <ReadNotification
                    key={notification.notify_uuid}
                    itemKey={notification.notify_uuid}
                    board={notification?.board ?? ''}
                    boardId={notification?.itemId || notification?.boardId}
                    boardName={notification?.boardName ?? ''}
                    imageUrl={notification?.imageUrl ?? null}
                    url={notification?.url ?? ''}
                    timestamp={
                      notification?.creation_timestamp ? formatDateTime(notification?.creation_timestamp) : null
                    }
                    message={notification?.message}
                    severity={notification?.severity}
                    title={notification?.title}
                    type={notification?.type}
                    userId={notification?.userId}
                    isRead={visuallyReadIds.includes(notification.notify_uuid)} // Use visuallyReadIds to control badge visibility
                    onHover={() => handleHover(notification)}
                  />
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  )
}

export default NotificationDrawer
