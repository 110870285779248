import React, { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  TableCell,
  IconButton,
  useTheme,
  Tooltip,
  TableRow,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material'
import CustomProductsAutocomplete from '../CustomProductsAutocomplete/CustomProductsAutocomplete'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CustomOptionSelect from '../Reusable/CustomOptionSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import PaidSelect from '../ContractDialog/PaidSelect'
import EditIcon from '@mui/icons-material/Edit'
import RichTextField from '../ContractDialog/MuiRichTextField'
import RichTextTooltip from '../ContractDialog/RichTextTooltip'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useProducts } from '../../api/aws/useProducts'
import DetailsLoading from '../Loading/DetailsLoading'
import dayjs from 'dayjs'
import StyledInputAdornment from '../ui/styled-input-adornment'

const productStatusOptions = [
  { label: 'Pending', color: 'gray' },
  { label: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Monthly', color: 'rgb(1, 142, 242)' },
  { label: 'Expired', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
]
const csaTypes = ['CSA', 'RCSA']
const paidProductsList = [
  'Meta Advertising',
  'Linkedin Advertising',
  'Paid Search Advertising',
  'Google Display Advertising',
  'Spotify Advertising',
  'Youtube Advertising',
  'TikTok Advertising',
]

const numberInputProps = column => {
  const marginProps = {
    // endAdornment: <InputAdornment position='end'>%</InputAdornment>,
    endAdornment: <StyledInputAdornment text={'%'} />,
  }
  const numberProps = {
    // startAdornment: <InputAdornment position='start'>$</InputAdornment>,
    startAdornment: <StyledInputAdornment />,
  }
  const qtyProps = {
    startAdornment: <InputAdornment position='start'></InputAdornment>,
  }
  return column.label === 'Margin' ? marginProps : column.label === 'Qty' ? qtyProps : numberProps
}

const NewRenderCell = ({ row, column, index, handleProductChange, validationErrors, handleRemoveRow, isDraft }) => {
  const theme = useTheme()
  const { products, isProductsLoading, isProductsError } = useProducts(true)
  const { removeRow } = useDealDetailsContext()
  const sortedProducts = products
    ? products.sort((a, b) => {
        const categoryA = a.category_name || '' // Assuming you have subCategory
        const categoryB = b.category_name || ''
        const categoryComparison = categoryA.localeCompare(categoryB)
        if (categoryComparison !== 0) {
          return categoryComparison
        }
        return a.title.localeCompare(b.title)
      })
    : []

  const [anchorEl, setAnchorEl] = useState(null)
  const [description, setDescription] = useState(row.description) // Initialize with current description
  const [isOpen, setIsOpen] = useState(false)
  const descriptionBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255,255,255,1)'

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
    setDescription(row.description) // Ensure the current description is set when opening the popover
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const handleBlur = e => {
    if (e.target.value === '') {
      handleProductChange(index, column.id, 0)
    }
  }
  const handleSaveDescription = () => {
    handleProductChange(index, 'description', description)
    handleClose()
  }

  const open = Boolean(anchorEl)

  const handleProductSelect = (index, selectedProduct) => {
    if (selectedProduct === null) {
      handleProductChange(index, 'selectedProduct', null, {
        description: '',
        margin: '0',
        productName: '',
        price: '0.00',
      })
    } else {
      const updates = {
        instanceId: uuidv4(),
        description: selectedProduct.description,
        margin: selectedProduct.custom_fields?.find(f => f.name === 'Margin %')?.value || selectedProduct.margin || '0',
        productName: selectedProduct.title,
        price: selectedProduct.price || '0.00',
        category: selectedProduct.category_name || '',
        subcategory: selectedProduct.subcategory || '',
      }
      // Add this logic to handle specific product titles
      if (paidProductsList.includes(selectedProduct.title)) {
        updates.payer = 'GRO'
        updates.accountUsed = 'GRO'
      }
      handleProductChange(index, 'selectedProduct', selectedProduct, updates)
    }
  }
  const handleClick = event => {
    event.stopPropagation() // Prevent event bubbling that causes popover to close
  }

  const handleOpen = () => {
    setDescription(row.description)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  const cellValue = row[column.id]
  const startDate = row.startDate
  const endDate = row.endDate
  const hasError = validationErrors.products && !row.productName
  const paidSelectLabels = ['Payer', 'Account Used']
  // Check if the product is in the paidProductsList
  const isProductPaid = paidProductsList.includes(row.productName)
  const displayLabel = column && column.id === 'payer' ? 'Payer' : 'Account Used'
  const isDisabled = column && column.id === 'payer' ? !isProductPaid : !isProductPaid
  const defaultValue = column.id === 'payer' ? 'GRO' : 'GRO'
  if (isProductsLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <DetailsLoading />
      </Box>
    )
  }
  return (
    <>
      <Grid item xs={6} sm={6} md={4} lg={2} xl={1}>
        {column.id === 'addtlDescriptor' && (
          <TextField
            type='text'
            fullWidth
            placeholder='additional descriptor'
            variant='outlined'
            value={cellValue || ''}
            onChange={e => handleProductChange(index, column.id, e.target.value)}
            sx={{ width: column.width }}
            disabled={!isDraft}
          />
        )}
      </Grid>
      {column.label === 'Total' || column.label === 'Gross Profit' ? (
        <>
          <TextField
            type='text'
            label={column.label}
            fullWidth
            variant='outlined'
            value={cellValue === '' ? '' : parseFloat(cellValue).toFixed(2)}
            onChange={e => handleProductChange(index, column.id, e.target.value)}
            onBlur={handleBlur}
            sx={{ width: 'auto' /*minWidth: 110 */ }}
            disabled={!isDraft}
            InputProps={{
              ...numberInputProps(column),
              readOnly: true,
              sx: {
                maxHeight: 45,
              },
            }}
          />
        </>
      ) : column.label === 'Media Spend' ? (
        <>
          <TextField
            type='text'
            label={column.label}
            fullWidth
            variant='outlined'
            value={cellValue === '' ? '' : parseFloat(cellValue).toFixed(2)}
            onChange={e => handleProductChange(index, column.id, e.target.value)}
            onBlur={handleBlur}
            sx={{ width: 'auto' /*minWidth: 110 */ }}
            disabled={!isDraft}
            InputProps={{
              ...numberInputProps(column),
              readOnly: true,
              sx: {
                maxHeight: 45,
              },
            }}
          />
        </>
      ) : column.label === 'Margin' ? (
        <>
          <TextField
            type='text'
            fullWidth
            label={column.label}
            variant='outlined'
            value={cellValue === '' ? '' : parseFloat(cellValue)}
            onChange={e => handleProductChange(index, column.id, e.target.value)}
            onBlur={handleBlur}
            sx={{ width: 'auto' /*minWidth: 85 */ }}
            disabled={!isDraft}
            InputProps={{
              ...numberInputProps(column),
              sx: {
                maxHeight: 45,
              },
            }}
            error={validationErrors.margin}
          />
        </>
      ) : column.label === 'Price' ? (
        <>
          <TextField
            type='text'
            fullWidth
            label={column.label}
            variant='outlined'
            value={cellValue === '' ? '' : parseFloat(cellValue)}
            onChange={e => handleProductChange(index, column.id, e.target.value)}
            onBlur={handleBlur}
            sx={{ width: 'auto' /*minWidth: 85 */ }}
            disabled={!isDraft}
            InputProps={{
              ...numberInputProps(column),
              sx: {
                maxHeight: 45,
              },
            }}
            error={validationErrors.price}
          />
        </>
      ) : column.label === 'Billed Price' ? (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto' /*minWidth: 85 */ }}
          disabled={!isDraft}
          InputProps={{
            ...numberInputProps(column),
            readOnly: true,
            sx: {
              maxHeight: 45,
            },
          }}
          error={validationErrors.billedPrice}
        />
      ) : column.label === 'Qty' ? (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto' /*minWidth: 50*/ }}
          disabled={!isDraft}
          InputProps={{
            ...numberInputProps(column),
            sx: {
              maxHeight: 45,
            },
          }}
          error={validationErrors.price}
        />
      ) : null}
      {column?.label === 'Date Range' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DateRangePicker
              startText='Start Date'
              endText='End Date'
              value={
                Array.isArray(cellValue) && cellValue.length === 2
                  ? cellValue.map(date => (dayjs.isDayjs(date) ? date : dayjs(date).isValid() ? dayjs(date) : null))
                  : [null, null]
              }
              onChange={newValue => {
                const sanitizedValue = Array.isArray(newValue)
                  ? newValue.map(date => (dayjs.isDayjs(date) ? date.toISOString() : null))
                  : [null, null]
                handleProductChange(index, 'dateRange', sanitizedValue)
              }}
              slotProps={{
                textField: {
                  sx: { fontSize: '12px' },
                  InputLabelProps: { style: { fontSize: '14px' } },
                },
              }}
              sx={{
                width: '100%',
                '& .MuiMultiInputDateRangeField-separator': {
                  display: 'none',
                  marginLeft: 0,
                  marginRight: 0,
                },
                '& .MuiOutlinedInput-root': {
                  maxHeight: 45,
                },
              }}
              disabled={!isDraft}
            />
          </Box>
        </LocalizationProvider>
      )}
      {paidSelectLabels?.includes(column?.label) && (
        <>
          <PaidSelect
            context={column.id}
            displayLabel={displayLabel}
            value={isDisabled ? defaultValue : cellValue || ''}
            setterFn={newValue => handleProductChange(index, column.id, newValue)}
            disabled={isDisabled}
            isDraft={isDraft}
          />
        </>
      )}
      {column?.type === 'popover' && (
        <>
          <RichTextTooltip content={row.description || 'No description'} onClick={handleOpen} isDraft={isDraft} />
          {isOpen && (
            <Dialog
              open={isOpen}
              onClose={handleClose}
              fullWidth
              maxWidth='sm'
              disableEnforceFocus
              disableAutoFocus
              disableRestoreFocus
            >
              <DialogTitle>Edit Description</DialogTitle>
              <DialogContent>
                <Box sx={{ width: '100%', margin: 0 }}>
                  <RichTextField
                    content={description || '<p></p>'}
                    onContentChange={setDescription}
                    onBlur={() => {}}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSaveDescription} color='primary'>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
      {column?.type === 'autocomplete' && (
        <>
          <CustomProductsAutocomplete
            products={sortedProducts}
            value={
              row.selectedProduct ? row.selectedProduct : products.find(product => product.title === row.productName)
            }
            onChange={newValue => handleProductSelect(index, newValue)}
            label='Select Service'
            error={hasError}
            isLoading={isProductsLoading}
            disabled={!isDraft}
          />
        </>
      )}
      {column?.type === 'component' && (
        <Grid item xs={8} sm={8} md={8} lg={6} xl={6} sx={{ width: 'auto' /*minWidth: 85 */ }}>
          <IconButton onClick={() => removeRow(row.instanceId)} color='error' disabled={!isDraft}>
            <DeleteIcon sx={{ color: !isDraft ? theme.palette.text.secondary : theme.palette.text.primary }} />
          </IconButton>
        </Grid>
      )}
    </>
  )
}

export default NewRenderCell
