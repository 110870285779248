import { Box, Drawer, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { slugify } from '../../../helperFunctions/slugify'
import { formatDayjsDate } from './renderDealsColumns'

export const renderLeadsColumns = onLeadClick => [
  {
    field: 'name',
    headerName: 'Name',
    width: 100,
    flex: 1.75,
    valueGetter: params => params,
    renderCell: params => {
      return (
        <Box component='span' onClick={() => onLeadClick(params.row)} sx={{ cursor: 'pointer' }}>
          {params.value}
        </Box>
      )
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   width: 100,
  //   flex: 1,
  // },
  {
    field: 'email',
    headerName: 'Email',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 100,
    flex: 1,
    valueGetter: params => params,
    // renderCell: params => {
    //   // Assuming 'lead_uuid' is your ID and 'accountName' is your sort key
    //   const leadId = params.row.id
    //   return (
    //     // <Tooltip title={params.value}>
    //     <Box>{params.value}</Box>
    //     // </Tooltip>
    //   )
    // },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'submittedDate',
    headerName: 'Submitted Date',
    width: 100,
    flex: 1,
    valueGetter: params => {
      if (!params) return null
      // Convert date string to a dayjs object
      const date = dayjs(params, 'MMMM D, YYYY')
      return date.isValid() ? date.toDate() : null
    },
    renderCell: params => {
      return <Box>{params.row ? params.row.submittedDate : ''}</Box>
    },
    sortComparator: (date1, date2) => {
      // Handle null dates
      if (date1 === null) return -1
      if (date2 === null) return 1
      return date1.getTime() - date2.getTime()
    },
  },
  // {
  //   field: 'createdTime',
  //   headerName: 'Created Time',
  //   width: 100,
  //   flex: 1,
  //   valueGetter: params => {
  //     return params
  //   },
  //   renderCell: params => {
  //     return <Box>{formatDayjsDate(params.value)}</Box>
  //   },
  // },
  {
    field: 'pageURL',
    headerName: 'Page Url',
    flex: 0.2,
    minWidth: 200,
    valueGetter: params => params,
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 0.2,
    width: 100,
    valueGetter: params => params,
  },
  {
    field: 'medium',
    headerName: 'Medium',
    width: 100,
    flex: 0.5,
    valueGetter: params => params,
  },
  {
    field: 'keyTerm',
    headerName: 'Key Term',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'ggLid',
    headerName: 'Gglid',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'userId',
    headerName: 'User Id',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'sessionId',
    headerName: 'Session Id',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
]
