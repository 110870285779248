import React from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-pro'
import { Box, Select, MenuItem, Typography, useTheme, FormControl } from '@mui/material'
const slotProps = theme => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: 'rgb(251, 193, 30)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})
const CustomNcoDrawerToolbar = ({ roles, selectedRole, setSelectedRole }) => {
  const theme = useTheme()
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {/* Role Filter Dropdown */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, pl: 1 }}>
          <FormControl sx={{ display: 'flex', height: 50 }} fullWidth>
            <Select
              value={selectedRole}
              onChange={e => setSelectedRole(e.target.value)}
              sx={{ height: 40, borderRadius: '4px' }}
            >
              <MenuItem value='All'>All Roles</MenuItem>
              {roles.map(role => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, pr: 1 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} />
        </Box>
        {/* Default Toolbar Buttons */}
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomNcoDrawerToolbar
