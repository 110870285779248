import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  IconButton,
  useTheme,
  Typography,
} from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { fetchViewLink } from '../../api/customHooks/fetchViewLink'

const PandaDocViewer = ({ contractId, customFileName, height, width, disabled }) => {
  const [open, setOpen] = useState(false)
  const [blob, setBlob] = useState(null)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const handleClickOpen = async () => {
    setLoading(true)
    const fetchedBlob = await fetchViewLink(contractId)
    if (fetchedBlob) setBlob(fetchedBlob)
    setLoading(false)
    setOpen(true)
  }

  const handleDownload = () => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = customFileName || `contract_${contractId}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setBlob(null)
  }

  return (
    <>
      <IconButton color='inherit' onClick={handleClickOpen} sx={{ ml: 2, zIndex: 20 }} disabled={disabled}>
        <FindInPageIcon
          sx={{
            color: disabled ? theme.palette.text.secondary : 'rgb(37, 133, 103)',
            height: 30,
            width: 30,
          }}
        />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography>{customFileName}</Typography>
          <Typography>Document Preview</Typography>
          {/* Custom Download Button */}
          <Button onClick={handleDownload} sx={{ float: 'right' }} disabled={loading || !blob}>
            Download
          </Button>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : blob ? (
            // Replace iframe with object for better display control
            <object data={window.URL.createObjectURL(blob)} type='application/pdf' width='100%' height='800px'>
              <p>
                Your browser does not support PDFs.{' '}
                <a href={window.URL.createObjectURL(blob)} target='_blank' rel='noreferrer'>
                  Click here to download the PDF
                </a>
              </p>
            </object>
          ) : (
            <p>Error loading document.</p>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PandaDocViewer
// import React, { useState } from 'react'
// import { Dialog, DialogContent, DialogTitle, Button, CircularProgress, IconButton, useTheme } from '@mui/material'
// import FindInPageIcon from '@mui/icons-material/FindInPage'
// import { fetchViewLink } from '../../api/customHooks/fetchViewLink'

// const PandaDocViewer = ({ contractId, customFileName, height, width, disabled }) => {
//   const [open, setOpen] = useState(false)
//   const [blob, setBlob] = useState(null)
//   const [loading, setLoading] = useState(false)
//   const theme = useTheme()

//   // Fetch the document and create a Blob URL
//   const handleClickOpen = async () => {
//     setLoading(true)
//     const fetchedBlob = await fetchViewLink(contractId)
//     if (fetchedBlob) setBlob(fetchedBlob)
//     setLoading(false)
//     setOpen(true)
//   }

//   // Custom download handler
//   const handleDownload = () => {
//     if (blob) {
//       const url = window.URL.createObjectURL(blob)
//       const link = document.createElement('a')
//       link.href = url
//       link.download = customFileName || `contract_${contractId}.pdf`
//       document.body.appendChild(link)
//       link.click()
//       document.body.removeChild(link)
//       window.URL.revokeObjectURL(url)
//     }
//   }

//   const handleClose = () => {
//     setOpen(false)
//     setBlob(null) // Clean up the Blob when closing
//   }

//   return (
//     <>
//       {/* Open Viewer */}
//       <IconButton color='inherit' onClick={handleClickOpen} sx={{ ml: 2 }} disabled={disabled}>
//         <FindInPageIcon
//           sx={{
//             color: disabled ? theme.palette.text.secondary : 'rgb(37, 133, 103)',
//             height: 30,
//             width: 30,
//           }}
//         />
//       </IconButton>

//       {/* Dialog with Document Viewer */}
//       <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
//         <DialogTitle>
//           Document Preview
//           {/* Custom Download Button */}
//           <Button onClick={handleDownload} sx={{ float: 'right', marginTop: '-10px' }} disabled={loading || !blob}>
//             Download
//           </Button>
//         </DialogTitle>
//         <DialogContent>
//           {loading ? (
//             <CircularProgress />
//           ) : blob ? (
//             <iframe
//               src={window.URL.createObjectURL(blob)}
//               width='100%'
//               height='800px'
//               title='PandaDoc Document'
//               style={{ border: 'none' }}
//             />
//           ) : (
//             <p>Error loading document.</p>
//           )}
//         </DialogContent>
//       </Dialog>
//     </>
//   )
// }

// export default PandaDocViewer

// import React, { useState } from 'react'
// import {
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Button,
//   CircularProgress,
//   IconButton,
//   Badge,
//   useTheme,
// } from '@mui/material'
// import { fetchViewLink } from '../../api/customHooks/fetchViewLink'
// import PandaDocLogo from '../../images/pandadoc.png'
// import FileOpenIcon from '@mui/icons-material/FileOpen'
// import SearchIcon from '@mui/icons-material/Search'
// import PandaDocLinkIcon from '../Icons/PandaDockLinkIcon'
// import PageviewIcon from '@mui/icons-material/Pageview'
// import FindInPageIcon from '@mui/icons-material/FindInPage'

// const PandaDocViewer = ({ contractId, height, width, disabled }) => {
//   const [open, setOpen] = useState(false)
//   const [blobUrl, setBlobUrl] = useState(null)
//   const [loading, setLoading] = useState(false)
//   const theme = useTheme()
//   const handleClickOpen = async () => {
//     setLoading(true)
//     const url = await fetchViewLink(contractId)
//     setBlobUrl(url)
//     setLoading(false)
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//     setBlobUrl(null) // Clean up the Blob URL when closing
//   }

//   return (
//     <>
//       <IconButton color='inherit' onClick={handleClickOpen} sx={{ ml: 2 }} disabled={disabled}>
//         {/* <Badge
//           badgeContent={
//             <SearchIcon />
//             // <img style={{ width: 20, borderRadius: 25 }} src={} />
//           }
//         >
//           <FileOpenIcon sx={{ width: width ?? 40, height: height ?? 40 }} />
//           <PandaDocLinkIcon />
//           <img style={{ width: size ? size : 40, borderRadius: 25 }} src={PandaDocLogo} />
//         </Badge> */}
//         <FindInPageIcon
//           sx={{ color: disabled ? theme.palette.text.secondary : 'rgb(37, 133, 103)', height: 30, width: 30 }}
//         />
//       </IconButton>
//       <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
//         <DialogTitle>Document Preview</DialogTitle>
//         <DialogContent>
//           {loading ? (
//             <CircularProgress />
//           ) : blobUrl ? (
//             <iframe src={blobUrl} width='100%' height='800px' title='PandaDoc Document' style={{ border: 'none' }} />
//           ) : (
//             <p>Error loading document.</p>
//           )}
//         </DialogContent>
//       </Dialog>
//     </>
//   )
// }

// export default PandaDocViewer
