import React, { useEffect, useState } from 'react'
import { TextField, Box, InputAdornment, Typography, useTheme, Grid } from '@mui/material'
import ExistingDeal from '../CreateDeal/ExistingDealSelect'
import { calculateDayjsTotalMonths } from '../../utility/calculateTotalMonths'
import { dynamicGridSizes, feeFieldStyles, totalsReadOnlyFieldStyles } from './style'
import StyledInputAdornment from '../ui/styled-input-adornment'

const formatNumber = number => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
}

const ContractTotals = ({ newDeal, setNewDeal, totals, onChange, organization, gridSizes }) => {
  const [isSignatureRequired, setIsSignatureRequired] = useState(newDeal?.noSignature || false)
  const [payments, setPayments] = useState(2)
  const { totalMonths, allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const totalGrossProfit = allSame ? newDeal?.totals?.grossProfit * (totalMonths || 1) : null
  const theme = useTheme()
  const handleChange = event => {
    const { name, value } = event.target
    console.log('name: ', name, 'value: ', value)
    if (name === 'numberOfPayments') {
      setNewDeal(prevDeal => ({
        ...prevDeal,
        numberOfPayments: value, // Update newDeal with selected payments
      }))
    } else {
      onChange(name, parseFloat(value))
    }
  }
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(232, 246, 252, 0.5)'
  const csaTypes = ['CSA', 'RCSA']
  // const numberOfPaymentOptions = [
  //   { label: '1', value: '1' },
  //   { label: '2', value: '2' },
  //   { label: '3', value: '3' },
  //   { label: '4', value: '4' },
  //   { label: '5', value: '5' },
  //   { label: '6', value: '6' },
  // ]

  // Effect to handle automatic updates to payments based on totals.total
  useEffect(() => {
    if (newDeal && csaTypes.includes(newDeal.type)) {
      console.log('[ContractTotals] setting new csa deal....')
      setNewDeal(prevDeal => ({
        ...prevDeal,
        numberOfPayments:
          totals.total < 2501
            ? 1
            : prevDeal.numberOfPayments === 1 && totals.total > 2500
            ? 2
            : prevDeal.numberOfPayments,
        totals: { ...totals, totalGrossProfit: totalGrossProfit },
      }))
    }
    // Do not automatically change payments if total is above 2500, let user control
  }, [totals.total, setNewDeal])

  return (
    <>
      <Grid item {...gridSizes.numbers}>
        <Box width={'100%'}>
          <TextField
            label='Gross Profit Total'
            value={
              totals && totals.totalGrossProfit
                ? formatNumber(totals.totalGrossProfit)
                : formatNumber(totals.grossProfit)
            }
            InputProps={{
              sx: {
                display: 'flex',
                alignItems: 'center',
              },
              startAdornment: <StyledInputAdornment />,
            }}
            sx={totalsReadOnlyFieldStyles(fieldBgColor)}
            // Conditionally disable the Select
            disabled={!newDeal?.isDraft}
          />

          {allSame ? (
            <TextField
              label='Monthly Total'
              value={!csaTypes.includes(newDeal.type) ? formatNumber(totals.monthlyTotal) : '—'}
              InputProps={{
                startAdornment: <StyledInputAdornment />,
              }}
              sx={totalsReadOnlyFieldStyles(fieldBgColor)}
              disabled={!csaTypes.includes(newDeal.type)}
            />
          ) : (
            <TextField
              label='Monthly Total'
              value={'Varying dates'}
              sx={totalsReadOnlyFieldStyles(fieldBgColor)}
              disabled
            />
          )}
        </Box>
      </Grid>
      <Grid item {...gridSizes.numbers}>
        <Box width={'100%'}>
          <TextField
            label='Implementation Fee'
            name='implementationFee'
            type='number'
            value={totals.implementationFee || 0}
            onChange={handleChange}
            InputProps={{
              startAdornment: <StyledInputAdornment />,
            }}
            sx={feeFieldStyles(fieldBgColor)}
            disabled={!newDeal?.isDraft}
          />
          <TextField
            label='Total'
            value={formatNumber(totals.total)}
            InputProps={{
              startAdornment: <StyledInputAdornment />,
              endAdornment:
                newDeal && newDeal.type === 'IO' ? (
                  <InputAdornment position='end'>
                    <Typography
                      sx={{ fontSize: 13, color: 'rgb(134, 137, 145)' }}
                    >{`${totalMonths} mo(s).`}</Typography>
                  </InputAdornment>
                ) : null,
            }}
            sx={totalsReadOnlyFieldStyles(fieldBgColor)}
            disabled={!newDeal?.isDraft}
          />
        </Box>
      </Grid>
      <Grid item {...gridSizes.noSignature}>
        <Box width={'100%'}>
          <ExistingDeal
            noSignature={isSignatureRequired}
            setNoSignature={setIsSignatureRequired}
            newDeal={newDeal}
            setNewDeal={setNewDeal}
            organization={organization}
          />
        </Box>
      </Grid>
    </>
  )
}

export default ContractTotals
