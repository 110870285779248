import React, { useState, useRef } from 'react'
import { Autocomplete, TextField, Chip, useTheme, Box } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { authorizedChipStyle } from '../ContractDialog/style'
import { renderUserOption } from '../Users/renderOptions'

const AuthorizedRecipientAutocomplete = ({ recipients, value, label, onChange, error, isDraft }) => {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState('')
  const [selectedUsers, setSelectedUsers] = useState(value || [])
  const inputRef = useRef(null) // To manage focus programmatically

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue)
  }

  const handleRemoveUser = userId => {
    const newSelectedUsers = selectedUsers.filter(user => user.id !== userId)
    setSelectedUsers(newSelectedUsers)
    onChange(newSelectedUsers)
    if (inputRef.current) {
      inputRef.current.focus() // Keep the input focused
    }
  }

  return (
    <Autocomplete
      multiple
      id='authorized-recipients-autocomplete'
      disabled={!isDraft ?? false}
      options={recipients || []}
      getOptionLabel={option =>
        option.real_name
          ? option.real_name
          : `${option['First Name'] || option.firstName || option.first_name} ${
              option['Last Name'] || option.lastName || option.last_name
            } (${option['Employee Email'] || option.email})`
      }
      value={selectedUsers}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(options, { inputValue }) => {
        return options.filter(option =>
          option.real_name
            ? option.real_name
            : `${option['First Name'] || option.firstName || option.first_name} ${
                option['Last Name'] || option.lastName || option.last_name
              }`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
        )
      }}
      renderOption={(props, option, state) => renderUserOption(props, option, state, theme)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={key}
              label={`${option['First Name'] || option.firstName || option.first_name} ${
                option['Last Name'] || option.lastName || option.last_name
              }`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id || option.gusers_uuid)}
              sx={authorizedChipStyle(theme)}
              deleteIcon={<CancelIcon />}
            />
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={error}
          placeholder={selectedUsers?.length > 0 ? 'Addtl Recipients' : 'Select Authorized Recipient'}
          inputRef={inputRef} // Attach the ref here
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text.secondary,
              fontStyle: 'italic',
            },
          }}
        />
      )}
      fullWidth
    />
  )
}

export default AuthorizedRecipientAutocomplete
