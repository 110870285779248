import React, { useEffect, useState, useRef } from 'react'
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  gridFocusStateSelector,
} from '@mui/x-data-grid-pro'
import { Badge, Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from './createColumns/CustomStatusFilter'
import CustomTypeFilter from './CustomTypeFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { statusFilters, contractStatusFilters, dealStageFilters } from './filterArrays'
import CustomStageFilter from './createColumns/CustomStageFilter'
import ResetColumnsButton from '../ui/reset-columns-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetFiltersButton from '../ui/reset-filters-button'

export const initialContactsVisibilityState = {
  source: false,
  timeCreated: false,
}

export const organizationTypeOptions = [
  {
    label: 'Property Management Company',
    type: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
  { label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  {
    label: 'Organization',
    type: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]

const slotProps = (theme, context) => ({
  button: {
    sx: {
      borderRadius: 2,
      maxHeight: '100%',
      mt: 2,
      mb: 2,
      '&:hover': { backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)' },
      color: theme.palette.text.primary,
      '& .MuiBadge-badge': {
        backgroundColor: context && context === 'leads' ? 'rgb(94, 123, 167)' : 'rgb(255, 188, 220)', // Your custom background color
        color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)', // Change the text color if needed
        fontSize: '14px', // Optional: adjust the font size
        width: '20px', // Adjust the width/height to control the size of the badge
        height: '20px',
      },
    },
  },
})

const CustomGridToolbar = ({
  filterModel,
  setFilterModel,
  visibilityModel = {},
  initialSortModel = {},
  sortModel,
  onClearSorting,
  onClearVisibility,
  context,
}) => {
  const theme = useTheme()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [filterType, setFilterType] = useState('')
  const [orgStatus, setOrgStatus] = useState('')
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  // Function to check if the sort model has changed
  const isSortModelChanged = sortModel
    ? sortModel.some((sort, index) => {
        const initialSort = initialSortModel[index]
        return sort.field !== initialSort?.field || sort.sort !== initialSort?.sort
      })
    : {}
  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setFilterStatus('')
    setFilterStage('')
  }

  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 3 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme, context)} />
          <GridToolbarExport slotProps={slotProps(theme)} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {context && context === 'dashboard' && (
            <>
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyData}
              >
                My Deals
              </Button>
              <Button sx={{ height: 40, color: 'rgb(240,128,128)' }} onClick={handleClearFilters}>
                Clear Filters
              </Button>
              <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
            </>
          )}
          {context && context === 'contacts' && (
            <>
              {/* <CustomStatusFilter
                context={'status'}
                options={contractStatusFilters}
                setterFn={setFilterStatus}
                value={filterStatus}
                onChange={handleSetFilter}
              />
              <CustomStatusFilter
                context={'stage'}
                options={dealStageFilters}
                setterFn={setFilterStage}
                value={filterStage}
                onChange={handleSetFilter}
              /> */}
              {filterModel &&
              Array.isArray(filterModel.quickFilterValues) &&
              filterModel.quickFilterValues.length > 0 ? (
                <Button
                  sx={{
                    height: 40,
                    color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                  }}
                  onClick={handleFilterMyData}
                >
                  My Contacts
                </Button>
              ) : (
                <Button
                  sx={{
                    height: 40,
                    color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                  }}
                  onClick={handleFilterMyData}
                >
                  My Contacts
                </Button>
              )}

              <ResetColumnsButton
                visibilityModel={visibilityModel}
                onClearVisibility={onClearVisibility}
                initialVisibilityState={initialContactsVisibilityState}
              />
              <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
              <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
              <Badge
                badgeContent={
                  <>
                    {filterModel &&
                    filterModel.quickFilterValues &&
                    Array.isArray(filterModel.quickFilterValues) &&
                    filterModel.quickFilterValues.length > 0 ? (
                      <Typography
                        sx={{
                          fontSize: 12,
                          bgcolor: 'rgb(255, 188, 220)',
                          p: 0.5,
                          height: '20px',
                          width: '20px',
                          borderRadius: '50%',
                          display: 'flex', // Flexbox centering
                          alignItems: 'center', // Vertically center
                          justifyContent: 'center', // Horizontally center
                          color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                          fontWeight: 'bold',
                        }}
                      >
                        {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                          ? filterModel.quickFilterValues.length
                          : null}
                      </Typography>
                    ) : null}
                  </>
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
              </Badge>
            </>
          )}
          {context && context === 'leads' && (
            <>
              {/* <CustomStatusFilter
                context={'status'}
                options={contractStatusFilters}
                setterFn={setFilterStatus}
                value={filterStatus}
                onChange={handleSetFilter}
              />
              <CustomStatusFilter
                context={'stage'}
                options={dealStageFilters}
                setterFn={setFilterStage}
                value={filterStage}
                onChange={handleSetFilter}
              /> */}
              <Button
                sx={{
                  height: 40,
                  color: theme.palette.mode === 'dark' ? 'lightblue' : 'lightblue',
                }}
                onClick={handleFilterMyData}
              >
                My Leads
              </Button>
              <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
              <Badge
                badgeContent={
                  <>
                    {filterModel &&
                    filterModel.quickFilterValues &&
                    Array.isArray(filterModel.quickFilterValues) &&
                    filterModel.quickFilterValues.length > 0 ? (
                      <Typography
                        sx={{
                          fontSize: 12,
                          bgcolor: 'rgb(94, 123, 167)',
                          p: 0.5,
                          height: '20px',
                          width: '20px',
                          borderRadius: '50%',
                          display: 'flex', // Flexbox centering
                          alignItems: 'center', // Vertically center
                          justifyContent: 'center', // Horizontally center
                          color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                          fontWeight: 'bold',
                        }}
                      >
                        {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                          ? filterModel.quickFilterValues.length
                          : null}
                      </Typography>
                    ) : null}
                  </>
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
              </Badge>
            </>
          )}
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default CustomGridToolbar
