import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  useTheme,
  Grid,
  Tooltip,
} from '@mui/material'
import { calculateAccountingSchedule } from '../PaymentSchedule/calculateAccountingSchedule'
import { useParams } from 'react-router-dom'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'
import IOFinancialTotals from './IOFinancialTotals'
import AccountingRichTextTooltip from './AccountingRichTextTooltip'

const FinancialTotals = ({ containerHeight, hideViewIcon }) => {
  const { dealId } = useParams()
  const { newDeal: deal, productRows } = useDealDetailsContext()
  const totals = useTotals(productRows, deal?.implementationFee || 0, deal.type ?? '')
  const [isDownloading, setIsDownloading] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(null) // Track which tooltip is open
  const tooltipRef = useRef(null) // Ref for the currently open tooltip
  const [isCopied, setIsCopied] = useState(false)
  const theme = useTheme()

  let paymentSchedule = calculateAccountingSchedule(deal)
  if (!['CSA', 'RCSA'].includes(deal.type ?? '')) {
    paymentSchedule = paymentSchedule.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  }

  const handleTooltipToggle = index => {
    setOpenTooltip(prev => (prev === index ? null : index))
  }

  const handleClickOutside = event => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      !event.target.closest('.MuiTooltip-tooltip') // Ignore clicks inside the tooltip
    ) {
      setOpenTooltip(null)
      setIsCopied(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleCopy = content => {
    if (typeof content === 'string') {
      navigator.clipboard
        .write([
          new ClipboardItem({
            'text/html': new Blob([content], { type: 'text/html' }),
            'text/plain': new Blob([content.replace(/<[^>]*>/g, '')], { type: 'text/plain' }), // Fallback to plain text
          }),
        ])
        .then(() => {
          setIsCopied(true)
          setTimeout(() => setIsCopied(false), 2000) // Reset copied state after 2 seconds
        })
        .catch(err => {
          console.error('Failed to copy:', err)
        })
    } else {
      console.error('Invalid content: Expected a string for the tooltip content.')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: containerHeight ?? 'calc(100vh - 122px)',
        overflowY: 'auto',
        padding: 2,
      }}
    >
      {deal && deal.type && deal.type !== 'IO' ? (
        <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
          {paymentSchedule.map((item, index) => (
            <Grid item xs={12} key={`${index}-${item.paymentNumber || item.month}`}>
              <Table sx={{ marginBottom: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} sx={tableHeaderStyles}>
                      {item.startDate && item.endDate
                        ? `${item.startDate} - ${item.endDate}`
                        : `Payment ${item.paymentNumber}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.products.map((product, idx) => (
                    <TableRow key={`${index}-${idx}-${product.productName}`}>
                      <TableCell sx={tableCellStyles(theme)}>
                        <Box
                          ref={tooltipRef} // Attach ref to tooltip container
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {<RenderServiceIcon service={product.productName} />}
                          <AccountingRichTextTooltip
                            open={openTooltip === `${index}-${idx}`}
                            onToggle={() => handleTooltipToggle(`${index}-${idx}`)}
                            content={product?.description}
                            onCopy={handleCopy}
                            isCopied={isCopied}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyles(theme) }}>
                        <Typography
                          sx={{
                            color:
                              product?.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary,
                          }}
                        >
                          {formatCurrency(product.paymentAmount)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        ...tableCellStyles(theme),
                        textAlign: 'right',
                        borderRight: 'none',
                      }}
                      colSpan={1}
                    >
                      Total:
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyles(theme),
                        color: 'rgb(50, 187, 130)',
                        borderLeft: 'none',
                      }}
                      colSpan={1}
                    >
                      {formatCurrency(item.totalAmount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          ))}
        </Grid>
      ) : (
        <IOFinancialTotals deal={deal} totals={totals} hideViewIcon={hideViewIcon} />
      )}
    </Box>
  )
}

export default FinancialTotals
