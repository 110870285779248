import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import { useUsers } from '../../../api/aws/useUsers'

const RenderGridOwner = ({ user, densityStyles }) => {
  return (
    <>
      {user ? (
        <Avatar
          alt={user.real_name}
          src={user.image_512}
          sx={{
            height: densityStyles.avatarSize,
            width: densityStyles.avatarSize,
          }}
        />
      ) : (
        <Box
          sx={{
            width: densityStyles.avatarSize,
            height: densityStyles.avatarSize,
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: densityStyles.fontSize,
          }}
        >
          <Typography sx={{ fontSize: densityStyles.fontSize }}>?</Typography>
        </Box>
      )}
    </>
  )
}

export default RenderGridOwner
