import React, { useState } from 'react'
import { Box, Tooltip, IconButton, useTheme } from '@mui/material'
import { ImBubble2 } from 'react-icons/im'
import { ImBubble } from 'react-icons/im'

const TaskInfo = React.forwardRef(({ handleTaskClick, params }, ref) => {
  const theme = useTheme()
  const iconColor = theme.palette.type === 'dark' ? 'white' : 'black'
  const [hover, setHover] = useState(false)

  return (
    <Box title='More Info' placement='right' className='action-icon'>
      <IconButton
        // onClick={event => handleTaskClick(event, params)}
        onClick={event => handleTaskClick(event, params)}
        ref={ref}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover ? <ImBubble size={22} color={iconColor} /> : <ImBubble2 size={22} color={iconColor} />}
        {/* <BsReverseLayoutSidebarReverse size={22} color={iconColor} /> */}
      </IconButton>
    </Box>
  )
})

export default TaskInfo
