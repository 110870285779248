import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const createTimeline = async timelineData => {
  const url = `${baseUrl}/aws/timeline`

  //////////////////////////////////////////////////
  console.log('NEW DEAL DATA TO SUBMIT[MUTATION]: ', timelineData)
  const response = await axios.post(url, timelineData, {})
  return response.data // This allows the response data to be accessed in onSuccess
}

export const useCreateTimeline = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createTimeline,
    onMutate: async newTimeline => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['timeline'])

      // Snapshot the previous value
      const previousTimeline = queryClient.getQueryData(['timeline'])
      console.log(
        '[useTimeline] previousTimeline: ',
        previousTimeline ? previousTimeline : 'No Previous Timelines Cached'
      )
      // Optimistically update to the new value

      return { previousTimeline }
    },
    onError: (err, newTimeline, context) => {
      // Roll back the optimistic update
      queryClient.setQueryData(['timeline'], context.previousTimeline)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      // console.log('[useTimeline] onSuccess data: ', data)
      // Invalidate and refetch or update query data

      queryClient.setQueryData(['timeline'], old =>
        old ? [...old, { ...data, id: data.timeline_uuid }] : [{ ...data, id: data.timeline_uuid }]
      )
      showSnackbar('Timeline updated successfully', 'success')
    },

    onSettled: () => {
      // Always refetch after error or success:
      // queryClient.invalidateQueries(['timeline'])
    },
  })

  return mutation
}
