import { Select, MenuItem, Chip, Typography, Box } from '@mui/material'
import { taskTypeList } from '../../taskObjects/taskTypeList'
import { StyledSelect } from '../../../../../style/styleElements'

// Adjust imports and other parts of the component as necessary...

const SelectTaskType = ({ selectedTaskType, setSelectedTaskType }) => {
  const handleTaskTypeChange = event => {
    const newValue = event.target.value
    // Find the full task type object based on the selected value
    const selectedTypeObject = taskTypeList.find(
      type => type.value === newValue
    )
    setSelectedTaskType(selectedTypeObject) // Store the full task type object
  }

  return (
    <StyledSelect
      value={selectedTaskType ? selectedTaskType.value : ''}
      onChange={handleTaskTypeChange}
      displayEmpty
      fullWidth
      renderValue={selectedValue => (
        <Box display='flex' alignItems='center' gap={1}>
          {selectedTaskType && (
            <Chip
              size='small'
              style={{
                backgroundColor: selectedTaskType.color,
                width: 14,
                height: 14,
                borderRadius: '50%',
              }}
            />
          )}
          <Typography variant='subtitle1'>
            {selectedTaskType ? selectedTaskType.label : 'Select Task Type'}
          </Typography>
        </Box>
      )}
    >
      {taskTypeList.map(type => (
        <MenuItem key={type.value} value={type.value}>
          <Chip
            size='small'
            style={{
              backgroundColor: type.color,
              width: 14,
              height: 14,
              borderRadius: '50%',
              margin: '5px',
            }}
          />
          <Typography variant='subtitle1'>{type.label}</Typography>
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default SelectTaskType
