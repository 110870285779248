import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const pandaAccessToken =
  process.env.REACT_APP_ENV === 'local'
    ? '44c68287cd90875b3ba53db7280c56e1a4d6068'
    : localStorage.getItem('panda_access_token')

const getDocumentById = async documentId => {
  if (!pandaAccessToken) {
    throw new Error('Panda authentication token is missing or expired. Please log in again.')
  }

  let url = `${baseUrl}/aws/pandadocuments/${documentId}`
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `${pandaAccessToken}` },
    })
    // console.log('Response PandaDoc: ', response)
    return response.data
  } catch (error) {
    // Here you can handle specific errors and rethrow them
    throw new Error(error.response?.data?.message || error.message || 'An unexpected error occurred.')
  }
}

export const useGetDocument = (documentId, queryKey = ['document', documentId]) => {
  const { data: document, isLoading: isDocumentLoading, isError: isDocumentError } = useQuery({
    queryKey: queryKey, // Use consistent query key
    queryFn: () => getDocumentById(documentId),
    staleTime: Infinity, // Optional: adjust based on your caching needs
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!documentId, // Only fetch if documentId is provided
  })

  // Return in the same format as the original hook
  return {
    document,
    isDocumentLoading,
    isDocumentError,
  }
}
