import React from 'react'
import { Box, Autocomplete, TextField, Popper, Typography, useTheme, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomPopper = styled(Popper)({
  width: '100% !important',
  minWidth: '200px',
  maxWidth: '445px',
})
const renderProductColor = category => {
  switch (category) {
    case 'Digital':
    case 'Social':
    case 'SMM':
      return 'rgb(88, 188, 128)'
    case 'No category':
      return 'rgb(133, 142, 150)'
    case 'Creative':
      return 'rgb(157, 139, 241)'
    case 'LaunchPad':
      return 'rgb(250, 192, 30)'
    case 'Website':
    case 'Website Build':
    case 'Display':
    case 'Graphic Design':
    case 'Hosting':
    case 'Videography':
    case 'Photography':
    case 'Audio':
    case 'Video':
      return 'rgba(213, 184, 255, 1)'
    case 'Search':
      return 'rgb(119, 255, 173)'
    case 'Management Fee':
    case 'Management Fee/Retainer':
    case 'Retainer':
    case 'Setup Fee':
      return 'rgb(119, 255, 173)'
    case 'Organic':
      return 'rgb(25, 181, 254)'
    default:
      return ''
  }
}

const CustomProductsAutocomplete = ({ products = [], value, onChange, label, error, isLoading, disabled }) => {
  const theme = useTheme()
  const handleOnChange = (event, newValue) => {
    onChange(newValue)
  }
  // Sort the products by subCategory first, then fallback to title
  const sortedProducts = products.sort((a, b) => {
    const subCategoryA = a.subcategory || '' // Assuming you have subCategory
    const subCategoryB = b.subcategory || ''
    const subCategoryComparison = subCategoryA.localeCompare(subCategoryB)
    if (subCategoryComparison !== 0) {
      return subCategoryComparison
    }
    return a.title.localeCompare(b.title)
  })

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          sx={{ padding: '0px' }}
          PopperComponent={CustomPopper}
          value={value || null}
          options={products}
          getOptionLabel={option => option.title || ''}
          onChange={handleOnChange}
          renderOption={(props, option) => (
            <li {...props} key={option.products_uuid || option.id}>
              <Box display='flex' justifyContent='space-between' width='100%'>
                {/* Prevent text wrapping */}
                <Typography noWrap>{option.title}</Typography>
                <Typography
                  noWrap
                  sx={{
                    fontSize: 12,
                    marginLeft: '8px',
                    color: renderProductColor(option.subcategory || 'No category'),
                  }}
                >
                  {option.subcategory || 'No category'}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    fontSize: 12,
                    marginLeft: '8px',
                    color: renderProductColor(option.category_name || 'No category'),
                  }}
                >
                  {option.category_name || 'No category'}
                </Typography>
              </Box>
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '2px 8px', color: theme.palette.text.highlight }, // Adjust padding as needed
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  padding: '0px 0px 0px 16px !important',
                  // padding: '2px 8px', // Adjust padding as needed
                  // minWidth: '450px',
                  minHeight: '45px', // Adjust height as needed
                  fontSize: '14px', // Adjust font size as needed
                },
                '.MuiAutocomplete-input': {
                  // padding: '2px 8px', // Adjust padding as needed
                  padding: '0px !important',
                },
              }}
              error={error}
              // helperText={error && 'At least one product is required'}
            />
          )}
          isOptionEqualToValue={(option, value) => option.uuid === (value ? value.uuid : undefined)}
          fullWidth
          disabled={disabled}
        />
      )}
    </Box>
  )
}

export default CustomProductsAutocomplete
