import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import DealItemDetails from './DealItemDetails'

const StyledListItem = styled(ListItem)(({ theme, isDragging }) => ({
  backgroundColor:
    isDragging && theme.palette.mode === 'dark'
      ? 'rgba(0,0,0,0.4)'
      : isDragging && theme.palette.mode === 'light'
      ? 'rgba(255,255,255,0.4)'
      : theme.palette.mode === 'dark'
      ? 'rgb(26, 22, 22)'
      : 'rgb(255,255,255)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
  margin: '5px',
  width: 'calc(100% - 10px)', // Adjust width to accommodate margin
  borderRadius: '5px',
  transition: 'background-color 0.2s ease', // Smooth transition for background color change
}))

const DraggableListItem = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <StyledListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <ListItemAvatar>
            <Avatar>{/* Customize your icon based on the stage or other attributes */}</Avatar>
          </ListItemAvatar>
          <DealItemDetails deal={item} />
        </StyledListItem>
      )}
    </Draggable>
  )
}

export default DraggableListItem
