import { IconButton, Badge, Box, Tooltip, Typography } from '@mui/material'
import LinkSharpIcon from '@mui/icons-material/LinkSharp'
import PandaIconBorder from '../ui/panda-icon-border'

const ViewLinksWithBadge = ({ onClick }) => {
  return (
    <Tooltip title={<Typography>View Links</Typography>}>
      <IconButton
        color='inherit'
        onClick={onClick}
        sx={{
          width: '50px', // Same size as EditIconButton
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 20,
        }}
      >
        <Badge badgeContent={<LinkSharpIcon fontSize='small' />} overlap='circular'>
          <PandaIconBorder />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default ViewLinksWithBadge
