import { useMutation, useQueryClient } from '@tanstack/react-query'
import { baseUrl } from '../../queryMutations/mutationFunctions'

import axios from 'axios'

const batchUpdateTasks = async (tasks) => {
  const url = `${baseUrl}/aws/tasks/batch`
  const response = await axios.put(url, { tasks })
  return response.data
}

export const useBatchUpdateTasks = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: batchUpdateTasks,
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks'])
      console.log('Batch update successful')
    },
    onError: (error) => {
      console.error('Error in batch update:', error)
    },
  })
  return mutation
}
