import React, { useState, useEffect } from 'react'
import { Typography, Chip, FormControl, InputLabel, Select, MenuItem, useTheme, FormHelperText } from '@mui/material'

export const dealTypeOptions = [
  {
    label: 'Marketing',
    color: 'rgb(88, 188, 128)',
    subOptions: [
      'Marketing Services Agreement',
      'Revised Marketing Services Agreement',
      'Insertion Order',
      'Marketing Services Agreement - Housing',
      'Revised Marketing Services Agreement - Housing',
    ],
  },
  {
    label: 'Creative',
    color: 'rgb(165, 147, 255)',
    subOptions: ['Creative Services Agreement', 'Revised Creative Services Agreement'],
  },
]

export const renderDealType = type => {
  switch (type) {
    case 'Marketing Services Agreement':
      return 'MSA'
    case 'Revised Marketing Services Agreement':
      return 'RMSA'
    case 'Insertion Order':
      return 'IO'
    case 'Marketing Services Agreement - Housing':
      return 'MSA - Housing'
    case 'Revised Marketing Services Agreement - Housing':
      return 'RMSA - Housing'
    case 'Creative Services Agreement':
      return 'CSA'
    case 'Revised Creative Services Agreement':
      return 'RCSA'
    default:
      return type
  }
}

export const flattenedOptions = dealTypeOptions.flatMap(option =>
  option.subOptions.map(subOption => ({
    name: subOption,
    color: option.color,
  }))
)

const DealTypeSelect = ({ newDeal, setNewDeal, error, helperText, onChange }) => {
  // console.log('[DealTypeSelect] newDeal: ', newDeal)
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (newDeal.type) {
      setSelectedValue(newDeal.type)
    }
  }, [newDeal.type])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    onChange(renderDealType(newValue))
    setNewDeal(prev => ({
      ...prev,
      type: newValue,
    }))
  }

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id='type-label'>Type</InputLabel>
      <Select
        labelId='type-label'
        id='type-select'
        value={selectedValue || ''}
        label='Type'
        onChange={handleChange}
        onBlur={() => {
          if (!selectedValue) setNewDeal(prev => ({ ...prev, type: '' }))
        }}
        name='type'
        required
      >
        {flattenedOptions.map(option => (
          <MenuItem key={option.name} value={renderDealType(option.name) || ''}>
            <Chip
              size='small'
              label={''}
              style={{
                backgroundColor: option.color,
                height: 14,
                width: 14,
                marginRight: 8,
                borderRadius: '50%',
                margin: '5px',
              }}
            />
            <Typography variant='subtitle1'>{option.name}</Typography>
          </MenuItem>
        ))}
      </Select>
      {/* {error && <FormHelperText>{helperText}</FormHelperText>} */}
    </FormControl>
  )
}

export default DealTypeSelect
