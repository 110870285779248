import React, { useState, useEffect } from 'react'
import { useDealDetailsContext } from '../../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useCreateTask } from '../../../api/aws/useCreateTask'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material'
import SelectProject from './CreateTasksComponents/SelectProject/SelectProject'
import SelectTaskType from './CreateTasksComponents/SelectTaskType/SelectTaskType'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactQuill from 'react-quill' // Assuming you're using React Quill for rich text
import 'react-quill/dist/quill.snow.css' // Import quill styles
import ThemedImage from '../../../images/ThemedImage/ThemedImage'
import GroLogoBlack from '../../../images/gro logo dark font.png'
import GroLogoWhite from '../../../images/GRO-logo-white-01.png'
import SelectMasterStatus from './CreateTasksComponents/SelectMasterStatus/SelectMasterStatus'
import { CreateProjectTextField } from '../../../style/styleElements'
import { taskThreadPayload } from './taskThreadPayload'
import { useNotifyMessage } from '../../../api/slack/useNotifyMessage'
import { tasksInitialState } from '../../../api/initialState/initialState'
import { useDeals } from '../../../api/aws/useDeals'
import DealsAutocomplete from '../../TeamTasks/DealsAutocomplete'

const CreateTaskDialog = ({ open, onClose }) => {
  const { mutate: createTask } = useCreateTask()
  const theme = useTheme()
  const [newTask, setNewTask] = useState(tasksInitialState)
  const [selectedDeal, setSelectedDeal] = useState(null) // or useState({});
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedMasterStatus, setSelectedMasterStatus] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const { mutate: sendNotification } = useNotifyMessage()
  // console.log('Projects in CreateTaskDialog: ', projects)
  const handleChange = e => {
    const { name, value } = e.target
    setNewTask({ ...newTask, [name]: value })
  }
  const handleDateChange = (field, value) => {
    setNewTask(prev => ({
      ...prev,
      [field]: value,
    }))
  }
  ///////////////////////// Batch Creation of Tasks for Testing ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const handleBatchCreate = async () => {
    setIsCreating(true)

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0]

    // Generate 50 tasks and create them
    const tasksToCreate = []
    for (let i = 0; i < 2; i++) {
      const taskData = {
        taskName: `Batch Task #${i + 1}`, // Example task name
        project: {},
        masterStatus: 'Select Status',
        taskType: 'New Client Onboard',
        startDate: today, // Set startDate to today's date
        dueDate: null,
        doneDate: null,
        AM: [],
        DMS: [],
        description: '',
        // Populate other task data as needed
      }
      tasksToCreate.push(taskData)
    }

    // Create tasks asynchronously
    const createTasksPromises = tasksToCreate.map(taskData => createTask(taskData))
    try {
      await Promise.all(createTasksPromises)
      setIsCreating(false)
    } catch (error) {
      console.error('Error creating tasks:', error)
      setIsError(true)
      setIsCreating(false)
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSubmit = async e => {
    e.preventDefault()
    setIsCreating(true)

    if (
      newTask.taskName &&
      selectedDeal && // Now an object, so this check ensures it's not empty
      selectedTaskType &&
      newTask.startDate &&
      newTask.dueDate &&
      newTask.description &&
      selectedMasterStatus
    ) {
      const taskDataToSend = {
        ...newTask,
        deal: selectedDeal?.deal_uuid, // Include the entire project object
        taskType: selectedTaskType.label,
        taskTypeColor: selectedTaskType.color,
        masterStatus: selectedMasterStatus.label,
        masterStatusColor: selectedMasterStatus.color,
        // Add any other fields required for task creation
      }
      try {
        createTask(taskDataToSend, {
          onSuccess: data => {
            setIsCreating(false)
            onClose()
          },
          onError: error => {
            setIsCreating(false)
            setIsError(true)
          },
        })

        onClose() // Close dialog on successful task creation
      } catch (error) {
        console.error(`Error Creating task or tread: ${error}`)
      }
    } else {
      console.error('Task details are incomplete')
      setIsError(true) // Display error message to the user
    }

    setIsCreating(false)
  }

  useEffect(() => {
    if (open) {
      setNewTask(tasksInitialState)
      setSelectedDeal(null)
      setSelectedTaskType(null)
      setSelectedMasterStatus(null)
      setIsError(false)
    }
  }, [open])

  const handleDealSelect = selectedDeal => {
    // Directly use the selected project object
    if (selectedDeal) {
      // Update selectedDeal state with the entire project object
      setSelectedDeal(selectedDeal)

      // Update the newTask state if necessary, with details from the selected project
      // Note: Adjust this part based on the specific details you need from the project for the new task
      setNewTask(prev => ({
        ...prev,
        // Assuming you need to update these fields based on the selected project;
        // otherwise, you might not need to update the task state here
        AM: selectedDeal.AM || [],
        DMS: selectedDeal.DMS || [],
        PM: selectedDeal.PM || [],
        SEO: selectedDeal.SEO || [],
        QA: selectedDeal.QA || [],
        MIA: selectedDeal.MIA || [],
      }))
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Center content vertically in the dialog
          backgroundImage: 'none',
          backgroundColor: theme.palette.mode === 'dark' ? ' #1f2228' : 'rgb(236, 239, 248)',
        },
      }}
    >
      <DialogContent>
        <Stack direction='row' spacing={2} justifyContent='flex-start' sx={{ marginBottom: '40px' }}>
          <ThemedImage style={{ width: '75px', borderRadius: 5 }} srcLight={GroLogoBlack} srcDark={GroLogoWhite} />
          <Typography variant='h5'>Create New Task</Typography>
        </Stack>
        <Box component='form' onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* <CreateTasks handleChange={handleChange} /> */}
            <CreateProjectTextField
              label='Task Name'
              name='taskName'
              value={newTask.taskName}
              onChange={handleChange}
              fullWidth
            />
            {/* <SelectProject projects={deals} selectedProject={selectedDeal} onProjectSelect={handleDealSelect} /> */}
            <DealsAutocomplete multiple={false} label={''} value={selectedDeal} onAddDeal={handleDealSelect} />
            <SelectTaskType selectedTaskType={selectedTaskType} setSelectedTaskType={setSelectedTaskType} />
            <SelectMasterStatus
              selectedMasterStatus={selectedMasterStatus}
              setSelectedMasterStatus={setSelectedMasterStatus}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Start Date'
                value={newTask.startDate}
                onChange={newValue => handleDateChange('startDate', newValue)}
                component={{
                  TextField: props => <TextField {...props} />,
                }}
              />
              <DatePicker
                label='Due Date'
                value={newTask.dueDate}
                onChange={newValue => handleDateChange('dueDate', newValue)}
                component={{
                  TextField: props => <TextField {...props} />,
                }}
              />
            </LocalizationProvider>
            <ReactQuill
              theme='snow'
              value={newTask.description}
              onChange={value => setNewTask(prev => ({ ...prev, description: value }))}
            />
            <Button type='submit' variant='contained' disabled={isCreating}>
              Save
            </Button>
          </Stack>
        </Box>
        {/* <Button variant='contained' onClick={handleBatchCreate}>
          Create 50 Tasks
        </Button> */}
        {isCreating && <CircularProgress />}
        {isError && <Typography color='error'>Error creating task</Typography>}
      </DialogContent>
    </Dialog>
  )
}

export default CreateTaskDialog
