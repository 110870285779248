import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useContactsById = (contactIds, queryString) => {
  const validIds = Array.isArray(contactIds) ? contactIds.filter(id => !!id) : []
  const uniqueValidIds = [...new Set(validIds)]

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/contacts', 'contacts', uniqueValidIds, queryString ?? 'contact')

  // Extract user data from successful queries
  const newContacts = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data

  // Aggregate loading and error states
  const isContactByIdLoading = queryResults.some(result => result.isLoading)
  const isContactsByIdError = queryResults.some(result => result.isError)
  const contactsByIdError = queryResults.find(result => result.isError)?.error

  return { newContacts, isContactByIdLoading, isContactsByIdError, contactsByIdError }
}
