import { useTheme } from '@mui/material'

const PandaIconBorder = () => {
  const theme = useTheme()
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px', // Consistent size
        height: '50px',
        borderRadius: '50%',
        // border: `2px solid ${theme.palette.text.primary}`,
        backgroundColor: 'transparent',
        boxShadow: `0px 2px 5px ${theme.palette.grey[500]}`,
        cursor: 'pointer', // Pointer cursor for hover
      }}
    >
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='-2 -3 85 85'
        width='65px' // Adjusted size for better scaling
        height='65px'
        style={{
          fill: theme.palette.text.primary,
        }}
        xmlSpace='preserve'
      >
        <style type='text/css'>
          {`.st0{fill:${theme.palette.text.primary};}
            .st1{fill:${theme.palette.text.primary};}`}
        </style>
        <g id='mark_white'>
          <g id='mark_8_'>
            <path
              className='st1'
              d='M58.5,17.7v11.8l-0.2,0.1c-1.7-1.4-3.9-2.3-6.7-2.3c-3.6,0-6.9,1.3-9.1,3.9l3.4,3.6
                  c1.7-1.6,3.5-2.5,5.8-2.5c4.3,0.1,7.1,3.4,7.1,7.8c0,4.4-2.9,7.8-7.3,7.8c-11,0-7.9-20.6-22.7-20.6c-7.4,0-12.6,5.5-12.6,12.8
                  v22.3h5.3V50.5l0.2-0.1c1.7,1.4,3.9,2.3,6.7,2.3c3.6,0,6.9-1.3,9.1-3.9l-3.4-3.6c-1.7,1.6-3.5,2.5-5.8,2.5
                  c-4.3-0.1-7.1-3.4-7.1-7.8c0-4.4,2.9-7.8,7.3-7.8c11,0,7.9,20.6,22.7,20.6c7.4,0,12.6-5.5,12.6-12.8V17.7H58.5z'
            ></path>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default PandaIconBorder
