import React, { useState, useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import CharacterCount from '@tiptap/extension-character-count'
import './mention.css'
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  RichTextEditor,
} from 'mui-tiptap'
import { useUsers } from '../../api/aws/useUsers'

const RichTextMentions = ({ content, onContentChange, onBlur }) => {
  const [characterCount, setCharacterCount] = useState(0)
  const [usersList, setUsersList] = useState([])
  const { users, isUsersLoading } = useUsers()
  const theme = useTheme()

  // Format users for mentions
  useEffect(() => {
    if (users && Array.isArray(users) && !isUsersLoading) {
      const formattedUsers = users
        .filter(user => user.id) // Ensure the id field exists
        .map(user => ({
          id: user.id,
          label: user.name || `${user.firstName} ${user.lastName}`.trim(),
        }))
      console.log('Filtered and Formatted Users:', formattedUsers) // Debug formatted users
      setUsersList(formattedUsers) // Update state correctly
    }
  }, [users, isUsersLoading])

  const mentionExtension = Mention.configure({
    HTMLAttributes: {
      class: 'mention', // Custom CSS class for styling mentions
    },
    suggestion: {
      char: '@', // Ensure @ is the trigger for mentions
      items: ({ query }) => {
        console.log('Triggered Mention Query:', query)

        // If the query is empty or only contains '@', show all users
        const search = query.startsWith('@') ? query.slice(1).toLowerCase().trim() : query.toLowerCase().trim()
        console.log('Processed Query for Matching:', search)

        // Show all users if there's no additional search term
        const filtered = search ? usersList.filter(user => user.label.toLowerCase().includes(search)) : usersList

        console.log('Filtered Users:', filtered)
        return filtered
      },

      render: () => {
        let dropdown

        return {
          onStart: props => {
            console.log('Dropdown Triggered:', props.items) // Debug dropdown start
            if (!props.items.length) return // Exit early if no items

            dropdown = document.createElement('div')
            dropdown.className = 'mention-dropdown'
            document.body.appendChild(dropdown)

            // Position the dropdown correctly
            const { left, top } = props.editor.view.coordsAtPos(props.range.from)
            dropdown.style.position = 'absolute'
            dropdown.style.left = `${left}px`
            dropdown.style.top = `${top + 30}px` // Adjust positioning below the caret
            dropdown.style.zIndex = 1000
            dropdown.style.background = theme.palette.background.section
            dropdown.style.border = '1px solid #ccc'
            dropdown.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'
            dropdown.style.padding = '8px'
            dropdown.style.borderRadius = '4px'
            dropdown.style.maxHeight = '200px'
            dropdown.style.overflowY = 'auto'

            update(props.items)
          },
          onUpdate: props => {
            console.log('Dropdown Updated:', props.items) // Debug dropdown update
            if (!props.items.length) {
              dropdown?.remove()
              return // Exit early if no items
            }
            update(props.items)
          },
          onExit: () => dropdown?.remove(),
        }

        function update(items) {
          if (!dropdown) return // Safeguard against undefined dropdown
          dropdown.innerHTML = '' // Clear previous items
          console.log('Rendering Dropdown Items:', items) // Debug dropdown content

          items.forEach(item => {
            const option = document.createElement('div')
            option.className = 'mention-option'
            option.textContent = item.label
            option.style.padding = '8px'
            option.style.cursor = 'pointer'

            option.addEventListener('click', () => {
              item.command()
              dropdown.remove() // Remove dropdown on selection
            })

            dropdown.appendChild(option)
          })
        }
      },
    },
  })

  const editorExtensions = [
    StarterKit.configure({
      bulletList: true, // You can customize these directly in StarterKit
      orderedList: true,
      listItem: true,
    }),
    TextStyle,
    Color,
    Highlight,
    CharacterCount.configure({
      limit: 5000,
    }),
    mentionExtension, // Mention extension for @mentions
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      <RichTextEditor
        className='rich-text full-width-toolbar'
        extensions={editorExtensions}
        content={content}
        onUpdate={({ editor }) => {
          const updatedContent = editor.getHTML()
          console.log('Editor Content Updated:', updatedContent) // Debug content updates
          setCharacterCount(editor.storage.characterCount.characters())
          onContentChange(updatedContent)
        }}
        onBlur={onBlur}
        renderControls={() => (
          <MenuControlsContainer onClick={e => e.stopPropagation()}>
            <MenuSelectHeading />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
        editorProps={{
          attributes: {
            style: 'width: 100%; text-align: left;',
          },
        }}
      />
    </Box>
  )
}

export default RichTextMentions

// import React, { useState, useEffect } from 'react'
// import { Box, useTheme } from '@mui/material'
// import StarterKit from '@tiptap/starter-kit'
// import Mention from '@tiptap/extension-mention'
// import BulletList from '@tiptap/extension-bullet-list'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import TextStyle from '@tiptap/extension-text-style'
// import Color from '@tiptap/extension-color'
// import Highlight from '@tiptap/extension-highlight'
// import CharacterCount from '@tiptap/extension-character-count'
// import './mention.css'
// import {
//   MenuButtonBold,
//   MenuButtonItalic,
//   MenuControlsContainer,
//   MenuDivider,
//   MenuSelectHeading,
//   MenuButtonBulletedList,
//   MenuButtonOrderedList,
//   RichTextEditor,
// } from 'mui-tiptap'
// const RichTextMentions = ({ content, onContentChange, users, onBlur }) => {
//   const [characterCount, setCharacterCount] = useState(0)
//   //   const [usersList, setUsersList] = useState([])
//   const theme = useTheme()
//   // Replace dynamic usersList with a hardcoded dataset
//   const usersList = [
//     { id: '1', label: 'John Doe' },
//     { id: '2', label: 'Jane Smith' },
//     { id: '3', label: 'Alice Johnson' },
//     { id: '4', label: 'Bob Brown' },
//   ]
//   //   useEffect(() => {
//   //     if (users && Array.isArray(users)) {
//   //       const formattedUsers = users.map(user => ({
//   //         id: user.id,
//   //         label: user.name || `${user.firstName} ${user.lastName}`.trim(),
//   //       }))
//   //       console.log('Formatted Users:', formattedUsers) // Debug formatted users
//   //       setUsersList(formattedUsers) // Update state correctly
//   //     }
//   //   }, [users])
//   const mentionExtension = Mention.configure({
//     HTMLAttributes: {
//       class: 'mention', // Custom CSS class for styling mentions
//     },
//     suggestion: {
//       char: '@', // Ensure @ is the trigger for mentions
//       items: ({ query }) => {
//         console.log('Triggered Mention Query:', query)

//         // Early exit for empty query
//         const search = query.startsWith('@') ? query.slice(1).toLowerCase().trim() : query.toLowerCase().trim()
//         console.log('Processed Query for Matching:', search)

//         const filtered = usersList.filter(user => user?.label?.toLowerCase().includes(search))

//         console.log('Filtered Users:', filtered)
//         return filtered
//       },

//       render: () => {
//         let dropdown

//         return {
//           onStart: props => {
//             console.log('Dropdown Triggered:', props.items) // Debug dropdown start
//             if (!props.items.length) return // Exit early if no items

//             dropdown = document.createElement('div')
//             dropdown.className = 'mention-dropdown'
//             document.body.appendChild(dropdown)

//             // Position the dropdown correctly
//             const { left, top } = props.editor.view.coordsAtPos(props.range.from)
//             dropdown.style.position = 'absolute'
//             dropdown.style.left = `${left}px`
//             dropdown.style.top = `${top + 30}px` // Adjust positioning below the caret
//             dropdown.style.zIndex = 1000
//             dropdown.style.background = theme.palette.background.section
//             dropdown.style.border = '1px solid #ccc'
//             dropdown.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)'
//             dropdown.style.padding = '8px'
//             dropdown.style.borderRadius = '4px'
//             dropdown.style.maxHeight = '200px'
//             dropdown.style.overflowY = 'auto'

//             update(props.items)
//           },
//           onUpdate: props => {
//             console.log('Dropdown Updated:', props.items) // Debug dropdown update
//             if (!props.items.length) {
//               dropdown?.remove()
//               return // Exit early if no items
//             }
//             update(props.items)
//           },
//           onExit: () => dropdown?.remove(),
//         }

//         function update(items) {
//           if (!dropdown) return // Safeguard against undefined dropdown
//           dropdown.innerHTML = '' // Clear previous items
//           console.log('Rendering Dropdown Items:', items) // Debug dropdown content

//           items.forEach(item => {
//             const option = document.createElement('div')
//             option.className = 'mention-option'
//             option.textContent = item.label
//             option.style.padding = '8px'
//             option.style.cursor = 'pointer'

//             option.addEventListener('click', () => {
//               item.command()
//               dropdown.remove() // Remove dropdown on selection
//             })

//             dropdown.appendChild(option)
//           })
//         }
//       },
//     },
//   })

//   const editorExtensions = [
//     StarterKit.configure({
//       bulletList: true, // You can customize these directly in StarterKit
//       orderedList: true,
//       listItem: true,
//     }),
//     TextStyle,
//     Color,
//     Highlight,
//     CharacterCount.configure({
//       limit: 5000,
//     }),
//     mentionExtension, // Mention extension for @mentions
//   ]

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
//       <RichTextEditor
//         className='rich-text full-width-toolbar'
//         extensions={editorExtensions}
//         content={content}
//         onUpdate={({ editor }) => {
//           const updatedContent = editor.getHTML()
//           console.log('Editor Content Updated:', updatedContent) // Debug content updates
//           setCharacterCount(editor.storage.characterCount.characters())
//           onContentChange(updatedContent)
//         }}
//         onBlur={onBlur}
//         renderControls={() => (
//           <MenuControlsContainer onClick={e => e.stopPropagation()}>
//             <MenuSelectHeading />
//             <MenuDivider />
//             <MenuButtonBold />
//             <MenuButtonItalic />
//             <MenuDivider />
//             <MenuButtonBulletedList />
//             <MenuButtonOrderedList />
//           </MenuControlsContainer>
//         )}
//         editorProps={{
//           attributes: {
//             style: 'width: 100%; text-align: left;',
//           },
//         }}
//       />
//     </Box>
//   )
// }

// export default RichTextMentions
