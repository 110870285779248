// src/AppLayout.js
import React, { useState, useContext, useEffect } from 'react'
import { Box, AppBar, Toolbar, useTheme, useMediaQuery, SpeedDial, SpeedDialAction, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ContainerProvider } from './context/ContainerProvider/ContainerProvider'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import MiniDrawer from './components/AppSidebar/MiniDrawer'
import { useLocation } from 'react-router-dom'
import { useActiveUser } from './api/slack/useActiveUser'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'
import { useUsers } from './api/aws/useUsers'
import SpeedDialComponent from './components/SpeedDial/SpeedDial'
import { NotificationProvider } from './context/NotificationProvider/NotificationProvider'
import CreateTaskDialog from './components/Tasks/CreateTasks/CreateTaskDialog'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useUser } from './api/aws/useUser'

const MainContent = styled('main', {
  shouldForwardProp: prop => !['open'].includes(prop),
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // overflowY: 'hidden',
  marginLeft: 0,
  ...(open && {
    marginLeft: 20,
    marginRight: 20,
  }),
  // marginTop: theme.spacing(9),
}))

const AppLayout = ({ children }) => {
  const { activeUser, isActiveUserLoading } = useActiveUser()
  const { user: currentUser, isUserLoading: isCurrentUserLoading } = useUser(activeUser?.user?.id)

  // const { users, isUsersLoading, isUsersError } = useUsers(true, ['appLayout', 'users'])
  // const [currentUser, setCurrentUser] = useState(null)
  // const [isCurrentUserLoading, setIsCurrentUserLoading] = useState(isActiveUserLoading)
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const [isConnectedUsersOpen, setIsConnectedUsersOpen] = React.useState(false)
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)

  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)

  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)

  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)

  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)

  const handleOpenConnectedUsers = () => setIsConnectedUsersOpen(true)
  const handleCloseConnectedUsers = () => setIsConnectedUsersOpen(false)

  const handleOpenCreateTask = () => setIsCreateTaskOpen(true)
  const handleCloseCreateTask = () => setIsCreateTaskOpen(false)

  // const theme = useTheme()
  const theme = useTheme()

  // useEffect(() => {
  //   if (users && !isUsersLoading && !isActiveUserLoading) {
  //     setCurrentUser(users.filter(currUser => currUser.user_uuid === activeUser?.user?.id))
  //   }
  // }, [users])
  const isUserLoading = isActiveUserLoading || isCurrentUserLoading
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        overflowX: 'hidden', // Only hides horizontal overflow; don't use 'hidden' for all directions
        overflowY: 'auto', // Allow vertical scrolling if needed
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* <Grid container> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationProvider>
          <DealDetailsProvider dealData={initialDealState}>
            <MiniDrawer
              handleOpenCreateOrganization={handleOpenCreateOrganization}
              handleOpenCreateDeal={handleOpenCreateDeal}
              handleOpenCreateContact={handleOpenCreateContact}
              onOpenCreateTask={handleOpenCreateTask}
              onCloseCreateTask={handleCloseCreateTask}
              isNotifyDrawerOpen={isNotifyDrawerOpen}
              onOpenNotify={handleOpenNotifyDrawer}
              onCloseNotify={handleCloseNotifyDrawer}
              isConnectedUsersOpen={isConnectedUsersOpen}
              handleOpenConnectedUsers={handleOpenConnectedUsers}
              handleCloseConnectedUsers={handleCloseConnectedUsers}
            />
            <MainContent>
              {children}
              {isCreateOrganizationOpen && (
                <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
              )}
              {isCreateDealOpen && <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />}
              {isCreateContactOpen && <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />}
              {isCreateTaskOpen && <CreateTaskDialog open={isCreateTaskOpen} onClose={handleCloseCreateTask} />}
              {currentUser && <SpeedDialComponent currentUser={currentUser} isLoading={isUserLoading} />}
            </MainContent>
          </DealDetailsProvider>
        </NotificationProvider>
      </LocalizationProvider>
      {/* </Grid> */}
    </Box>
  )
}

export default AppLayout
