import { DataGridPro, GridToolbarQuickFilter } from '@mui/x-data-grid-pro'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  }
}

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  maxHeight: '100vh',
  maxWidth: '95vw',
  boxShadow: theme.shadows,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === 'light' ? '#f9f8ff' : 'rgba(30, 39, 53, 0.65)',
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}))

export const gridContainerStyles = {
  display: 'flex',
  maxWidth: '100vw',
  maxHeight: '100vh',
  // width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}
export const userImageStyles = {
  width: '50px', // Set a fixed width
  height: '50px', // Set a fixed height
  borderRadius: '50%', // Make the image circular
  objectFit: 'cover', // Ensure the image covers the box while maintaining aspect ratio
}
export const gridToolbarContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
export const deleteButtonStyles = theme => ({
  borderColor: 'black',
  color: 'red',
  fontWeight: 'bold',
  ':hover': {
    backgroundColor: 'rgba(201, 50, 46)',
    borderColor: 'black',
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
})
export const getDensityStyles = density => {
  switch (density) {
    case 'compact':
      return {
        chipWidth: 70,
        chipHeight: 25,
        verticalSize: 'small',
        verticalWidth: 120,
        verticalHeight: 25,
        globeSize: 25,
        avatarSize: 26,
        padding: '5px',
        fontSize: 12,
        height: 30,
        width: 30,
        gap: 1,
      }
    case 'comfortable':
      return {
        chipWidth: 70,
        chipHeight: 30,
        verticalSize: 'large',
        verticalWidth: 220,
        verticalHeight: 45,
        globeSize: 36,
        avatarSize: 52,
        padding: '10px',
        fontSize: 15,
        height: 68,
        width: 68,
        gap: 3,
      }
    case 'standard':
    default:
      return {
        chipWidth: 70,
        chipHeight: 30,
        verticalSize: 'medium',
        verticalWidth: 120,
        verticalHeight: 35,
        globeSize: 32,
        avatarSize: 42,
        padding: '10px',
        fontSize: 13,
        height: 48,
        width: 48,
        gap: 2,
      }
  }
}
// Define a styled component for the black and white effect
export const DisabledIconButton = styled(IconButton)(({ theme }) => ({
  filter: 'grayscale(100%)',
  pointerEvents: 'none',
  opacity: '40%',
}))

// export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
//   '& .MuiInputBase-input': {
//     padding: 5,
//     position: 'relative',
//     '&:focus': {
//       backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
//       transition: 'background-color 1s ease-out', // Smooth fade-out transition
//     },
//     '&:focus-visible': {
//       animation: 'fadeOut 2s ease-out forwards', // Trigger animation on focus
//     },
//   },
//   '@keyframes fadeOut': {
//     '0%': {
//       backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
//     },
//     '100%': {
//       backgroundColor: 'transparent', // End with the original background
//     },
//   },
// }))
export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
  '&.MuiFormControl-root': {
    // This applies the styles to the root element of the quick filter
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    border: '1px solid',
    borderColor: 'transparent',
    padding: '5px',
    // borderRadius: '4px',
    transition: 'border-color 0.5s ease',

    // Add a subtle focus effect
    '&:focus-within': {
      border: '1px solid',
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      border: '1px solid',
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
    },
    '100%': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-root': {
    paddingLeft: '8px', // Adjust padding or any other desired style here
  },
}))
export const fieldStyles = fieldBgColor => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: fieldBgColor,
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-input': {
    // backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on hover
  },
})
export const RenderCellBox = styled(Box)(({ densityStyles }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: densityStyles.padding,
  gap: densityStyles.gap,
}))
