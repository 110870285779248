import { Box, Tooltip, CircularProgress } from '@mui/material'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { useStrategy } from '../../api/aws/useStrategy'
import { useEffect, useState } from 'react'
import { analyzeStrategy } from '../Strategy/analyzeStrategy'
import DetailsLoading from '../Loading/DetailsLoading'

const StrategyProgress = ({ dealId, size, strategy }) => {
  // const { strategy, isLoading, isError } = useStrategy(dealId)
  const itemParams = strategy?.updatedProducts ?? []
  const [progress, setProgress] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [totalFields, setTotalFields] = useState(null)
  // Calculate the alpha value based on the progress, making sure it's between 0.3 and 1
  const dimValue = Math.max(Math.min(progress / 100, 1), 0.3)
  const globeColor = progress === 100 ? 'rgb(255, 202, 73)' : `rgba(114, 120, 129, ${dimValue})` // Blue for 9/9, canary color otherwise
  useEffect(() => {
    // if (strategy) {
    const { progress, completed, totalFields } = analyzeStrategy(strategy, itemParams)
    setProgress(progress)
    setCompleted(completed)
    setTotalFields(totalFields)
    // }
  }, [strategy])
  return (
    <Tooltip title={`${completed} / ${totalFields}`}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {completed < totalFields && (
          <CircularProgress
            variant='determinate'
            value={progress}
            size={40}
            thickness={3}
            sx={{ color: 'rgb(37, 134, 103)' }}
          />
        )}
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TrackChangesIcon sx={{ fontSize: size ? size : '25px', color: globeColor }} />
        </Box>
      </Box>
    </Tooltip>
  )
}

export default StrategyProgress
