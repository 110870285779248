import { getGridStringOperators } from '@mui/x-data-grid-pro'
const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

// Custom operator that wraps the 'contains' logic to invert it
export const customNotContainsOperator = {
  ...containsOperator,
  label: 'does not contain',
  value: 'notContains',
  getApplyFilterFn: filterItem => {
    // console.log('[customNotContainsOperator] filterItem:', filterItem)

    // Check if the filter value is defined
    if (filterItem.value === undefined || filterItem.value === null) {
      // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
      return null
    }

    const containsFn = containsOperator.getApplyFilterFn(filterItem)
    if (!containsFn) {
      // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
      return null
    }

    // Log the cellValue during the filter function application
    return cellValue => {
      // console.log('[customNotContainsOperator] cellValue:', cellValue)
      const result = !containsFn(cellValue)
      // console.log('[customNotContainsOperator] Result of filter application:', result)
      return result
    }
  },
}
export const strictEqualsOperator = {
  label: 'is exactly',
  value: 'equals',
  getApplyFilterFn: filterItem => {
    if (!filterItem.value) {
      return null // No filter applied
    }

    return cellValue => {
      return cellValue === filterItem.value // Exact match
    }
  },
}
