import React, { useState } from 'react'
import { Autocomplete, TextField, Chip, Tooltip, useTheme } from '@mui/material'
import ContactsDialog from '../Contacts/ContactsDialog'
import { useCreateContact } from '../../api/aws/useCreateContact'
import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'

const OrgAdditionalContactsAutocomplete = ({ options, value, label, onChange, onBlur }) => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('') // Temp input for dialog
  const [inputValue, setInputValue] = useState('') // Input state for Autocomplete
  const [selectedContacts, setSelectedContacts] = useState(value ?? [])
  const { mutate: createContact } = useCreateContact()
  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('') // Clear input when dialog closes
  }

  const handleCreateContacts = newValue => {
    createContact(newValue, {
      onSuccess: data => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        const updatedContacts = [...selectedContacts, newContact]
        setSelectedContacts(updatedContacts)
        onChange(updatedContacts)
        handleCloseDialog() // Also clears input here
      },
    })
  }

  const handleSelectExistingContact = (event, newValue, reason) => {
    console.log('reason', reason)
    console.log('newValue', newValue)
    if (reason === 'selectOption' || reason === 'removeOption') {
      setSelectedContacts(newValue)
      onChange(newValue)
    }
  }

  const handleRemoveContact = contactId => {
    const newSelectedContacts = selectedContacts.filter(contact => contact.contacts_uuid !== contactId)
    setSelectedContacts(newSelectedContacts)
    onChange(newSelectedContacts)
  }
  const handleBlur = () => {
    const contactIds = selectedContacts.map(contact => contact.contacts_uuid)
    onBlur('contacts', contactIds)
  }
  const renderExtraChip = (extraCount, extraContacts) => (
    <Tooltip title={extraContacts.map(contact => contact.name).join(', ')} arrow>
      <Chip label={`+${extraCount}`} size='small' />
    </Tooltip>
  )

  return (
    <>
      <Autocomplete
        multiple
        id='org-addtlcontacts-autocomplete'
        options={options}
        getOptionLabel={option => option.name || ''}
        value={selectedContacts ? selectedContacts : []}
        onChange={(event, newValue, reason) => handleSelectExistingContact(event, newValue, reason)}
        onBlur={handleBlur}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              id: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) => {
          const { key, ...rest } = props
          return option.inputValue ? (
            <AddRenderOptionButton key={option.id} {...rest} option={option} handleOpenDialog={handleOpenDialog} />
          ) : (
            <li {...rest} key={option.contacts_uuid}>
              {option.name}
            </li>
          )
        }}
        renderTags={(tagValue = [], getTagProps) => {
          const maxVisibleChips = 1
          const extraCount = tagValue.length - maxVisibleChips
          const visibleTags = tagValue.slice(0, maxVisibleChips)
          const extraTags = tagValue.slice(maxVisibleChips)
          return (
            <>
              {visibleTags.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })

                return (
                  <Chip
                    key={key}
                    label={option.name}
                    {...rest}
                    onDelete={() => handleRemoveContact(option.contacts_uuid)}
                  />
                )
              })}
              {extraCount > 0 && renderExtraChip(extraCount, extraTags)}
            </>
          )
        }}
        renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default OrgAdditionalContactsAutocomplete

// import React, { useEffect, useState } from 'react'
// import { Autocomplete, TextField, Chip, Tooltip } from '@mui/material'
// import { useCreateContact } from '../../api/aws/useCreateContact'
// import ContactsDialog from '../Contacts/ContactsDialog'
// import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'
// import { useGetQuery } from '../../api/aws/useGetQuery'
// import { useContacts } from '../../api/aws/useContacts'

// const OrgAdditionalContactsAutocomplete = ({ options, value, label, onChange, onBlur }) => {
//   const [dialogOpen, setDialogOpen] = useState(false)
//   const [tempInput, setTempInput] = useState('')
//   const [inputValue, setInputValue] = useState('')
//   const [selectedAdditionalContacts, setSelectedAdditionalContacts] = useState([])
//   const { mutate: createContact } = useCreateContact()
//   useEffect(() => {
//     // Set the initial selected options based on the value prop
//     if (value && value.length > 0) {
//       const initialContacts = value.map(contactId => options.find(contact => contact.contacts_uuid === contactId))
//       setSelectedAdditionalContacts(initialContacts)
//     }
//   }, [value, options])

//   const handleOpenDialog = inputValue => {
//     setTempInput(inputValue)
//     setDialogOpen(true)
//   }

//   const handleCloseDialog = () => {
//     setDialogOpen(false)
//     setTempInput('')
//     setInputValue('')
//   }

//   const handleCreateContacts = newValue => {
//     createContact(newValue, {
//       onSuccess: data => {
//         const newContact = { ...data, contacts_uuid: data.contacts_uuid }
//         const updatedContacts = [...(selectedAdditionalContacts || []), newContact]
//         setSelectedAdditionalContacts(updatedContacts)
//         onChange(updatedContacts.map(contact => contact.contacts_uuid))
//         handleCloseDialog()
//       },
//     })
//   }

//   // const handleSelectExistingContact = (event, newValue, reason) => {
//   //   setSelectedAdditionalContacts(newValue)
//   //   const contactIds = newValue.map(contact => contact.contacts_uuid)
//   //   onChange(contactIds)
//   // }
//   const handleSelectExistingContact = (event, newValue) => {
//     // Merge existing and newly selected contacts
//     const existingIds = selectedAdditionalContacts.map(contact => contact.contacts_uuid)
//     const newIds = newValue.map(contact => contact.contacts_uuid)

//     // Create a deduplicated array of contacts
//     const mergedContacts = [
//       ...selectedAdditionalContacts,
//       ...newValue.filter(contact => !existingIds.includes(contact.contacts_uuid)),
//     ]
//     setSelectedAdditionalContacts(mergedContacts)
//     const mergedContactIds = mergedContacts.map(contact => contact.contacts_uuid)

//     // Pass the merged contact IDs to the parent
//     onChange(mergedContacts.map(contact => contact.contacts_uuid))
//   }

//   const handleBlur = () => {
//     const contactIds = selectedAdditionalContacts.map(contact => contact.contacts_uuid)
//     onBlur('contacts', contactIds)
//   }

//   const handleRemoveContact = contactId => {
//     console.log('[handleRemoveContact] contactId: ', contactId)
//     const newSelectedContacts = selectedAdditionalContacts.filter(contact => contact.contacts_uuid !== contactId)
//     console.log('[handleRemoveContact] newSelectedContacts: ', newSelectedContacts)
//     setSelectedAdditionalContacts(newSelectedContacts)
//     onChange(newSelectedContacts.map(contact => contact.contacts_uuid))
//   }

//   return (
//     <>
//       <Autocomplete
//         multiple
//         id='additional-options-autocomplete'
//         options={options || []}
//         getOptionLabel={option => option?.name || 'Unnamed Contact'}
//         value={selectedAdditionalContacts}
//         onChange={handleSelectExistingContact}
//         onBlur={handleBlur}
//         inputValue={inputValue}
//         onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
//         filterOptions={(options, { inputValue }) => {
//           let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
//           if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
//             filtered.unshift({
//               inputValue,
//               specialOption: true,
//               id: `add-new-${inputValue}`,
//             })
//           }
//           return filtered
//         }}
//         renderOption={(props, option) =>
//           option.inputValue ? (
//             <AddRenderOptionButton key={option.id} {...props} option={option} handleOpenDialog={handleOpenDialog} />
//           ) : (
//             <li {...props} key={option.contacts_uuid}>
//               {option.name}
//             </li>
//           )
//         }
//         renderTags={(tagValue, getTagProps) => {
//           const firstContact = tagValue[0]
//           const additionalContacts = tagValue.slice(1)
//           const { key, onDelete, ...rest } = getTagProps({ index: 0 })
//           return (
//             <>
//               {firstContact && (
//                 <Chip
//                   key={firstContact?.contacts_uuid}
//                   label={firstContact?.name || 'Unnamed Contact'}
//                   {...rest}
//                   onDelete={() => handleRemoveContact(firstContact?.contacts_uuid)}
//                 />
//               )}
//               {additionalContacts.length > 0 && (
//                 <Tooltip title={additionalContacts.map(contact => contact?.name || 'Unnamed Contact').join(', ')}>
//                   <Chip
//                     key={`extra-${additionalContacts.length}`}
//                     label={`+${additionalContacts.length}`}
//                     onDelete={() => {
//                       const newSelectedContacts = selectedAdditionalContacts.slice(0, 1)
//                       setSelectedAdditionalContacts(newSelectedContacts)
//                       onChange(newSelectedContacts.map(contact => contact.contacts_uuid))
//                     }}
//                   />
//                 </Tooltip>
//               )}
//             </>
//           )
//         }}
//         renderInput={params => <TextField {...params} label={label} />}
//         fullWidth
//         freeSolo
//       />
//       <ContactsDialog
//         open={dialogOpen}
//         onClose={handleCloseDialog}
//         tempInput={tempInput}
//         onSaveContact={handleCreateContacts}
//       />
//     </>
//   )
// }

// export default OrgAdditionalContactsAutocomplete
