import React, { useState } from 'react'
import { Box, TextField, Grid, Chip, InputAdornment, useTheme } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import AuthorizedRecipientAutocomplete from '../PandaDoc/AuthorizedRecipientAutocomplete'
import ContactRecipientAutocomplete from '../PandaDoc/ContactRecipientAutocomplete'
import PersonIcon from '@mui/icons-material/Person'
import { useUsers } from '../../api/aws/useUsers'
import { useContactsById } from '../../api/customHooks/useContactsById'
import { gridItemStyles, fieldStyles, readOnlyFieldStyles, stageFieldStyles, typeFieldStyles } from './style'
import ReviseDealType from '../DealTypeSelect/ReviseDealType'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { validDateValue, normalizeDate } from '../DateTime/dateUtils'

dayjs.extend(utc)
dayjs.extend(timezone)

const ContractInfoHeader = ({ handleChange, validationErrors, organization }) => {
  const { newDeal } = useDealDetailsContext()
  const contactUUIDs = newDeal && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const { newContacts: fetchedContacts } = useContactsById(contactUUIDs, 'headerContacts')
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['contractInfoHeader', 'users'])
  const theme = useTheme()
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(255,255,255,1)'
  const userTimezone = dayjs.tz.guess() // Dynamically detect user's timezone

  return (
    <>
      <Grid container spacing={2} pb={2}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <TextField
            label='Contract Name'
            name='name'
            value={newDeal.name || ''}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={readOnlyFieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <TextField
            label='Organization'
            name='organizations'
            value={organization && organization.name ? organization.name : ''}
            fullWidth
            InputProps={{ readOnly: true }}
            sx={readOnlyFieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <ReviseDealType
            error={validationErrors.type}
            helperText={validationErrors.type && 'Deal type is required'}
            onChange={newValue => handleChange('type', newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <AuthorizedRecipientAutocomplete
            recipients={users}
            value={newDeal && newDeal.recipients ? newDeal.recipients.authorizedRecipient : []}
            label={'GRO Authorized Recipient'}
            onChange={newValue => handleChange('authorizedRecipient', newValue)}
            error={validationErrors.authorizedRecipient}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <DatePicker
            label='Start Date'
            value={validDateValue(newDeal ? newDeal.startDate : null)}
            onChange={newValue => handleChange('startDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: { InputProps: { readOnly: true }, fullWidth: true },
            }}
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <DatePicker
            label='End Date'
            value={validDateValue(newDeal ? newDeal.endDate : null)}
            onChange={newValue => handleChange('endDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: { InputProps: { readOnly: true }, fullWidth: true },
            }}
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <TextField
            label='Contacts'
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position='start'>
                  {fetchedContacts && fetchedContacts.length > 0
                    ? (() => {
                        const maxVisibleChips = 1
                        const extraCount = fetchedContacts.length - maxVisibleChips
                        const contactsBorderColor = theme.palette.mode === 'dark' ? 'transparent' : 'transparent'
                        const contactsTextColor =
                          theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                        const extraCountBorderColor =
                          theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
                        return (
                          <>
                            {fetchedContacts.slice(0, maxVisibleChips).map((contact, index) => (
                              <Box
                                key={contact.contacts_uuid}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  margin: 0,
                                  padding: 0.5,
                                }}
                              >
                                <PersonIcon
                                  sx={{
                                    color: 'rgb(255, 123, 187)',
                                  }}
                                />
                                <Chip
                                  label={contact.name}
                                  size='small'
                                  style={{
                                    height: 'auto',
                                    width: 'auto',
                                    borderColor: contactsBorderColor,
                                    borderWidth: '0.5px',
                                    borderStyle: 'solid',
                                    backgroundColor: 'transparent',
                                    color: contactsTextColor,
                                    borderRadius: '4px',
                                    marginRight: '8px',
                                  }}
                                />
                              </Box>
                            ))}
                            {extraCount > 0 && (
                              <Chip
                                label={`+${extraCount}`}
                                size='small'
                                style={{
                                  height: 'auto',
                                  width: 'auto',
                                  borderColor: extraCountBorderColor,
                                  borderWidth: '0.5px',
                                  borderStyle: 'solid',
                                  backgroundColor: 'transparent',
                                  color: contactsTextColor,
                                  borderRadius: '4px',
                                  marginRight: '8px',
                                }}
                              />
                            )}
                          </>
                        )
                      })()
                    : 'No Contacts'}
                </InputAdornment>
              ),
            }}
            sx={{
              ...readOnlyFieldStyles(fieldBgColor), // Apply your predefined readOnlyFieldStyles(fieldBgColor)
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent !important', // Force the border color to be transparent
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <TextField
            label='Legal Entity Name'
            name='dba'
            value={newDeal ? newDeal.dba : ''}
            fullWidth
            sx={readOnlyFieldStyles(fieldBgColor)}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <TextField
            label='Stage'
            name='stage'
            value={newDeal ? newDeal.stage : ''}
            fullWidth
            InputProps={{ readOnly: true }}
            sx={stageFieldStyles(newDeal ? newDeal.stage : '')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <ContactRecipientAutocomplete
            // recipients={contacts}
            value={newDeal && newDeal.recipients ? newDeal.recipients.contactRecipient : []}
            label={'Contact Recipient'}
            onChange={newValue => handleChange('contactRecipient', newValue)}
            error={validationErrors.contactRecipient}
            fullWidth
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <DatePicker
            label='Dependencies Due'
            value={validDateValue(newDeal ? newDeal.dependenciesDueDate : null)}
            onChange={newValue => handleChange('dependenciesDueDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: validationErrors.dependenciesDueDate,
              },
            }}
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={gridItemStyles}>
          <DatePicker
            label='Target Launch Date'
            value={validDateValue(newDeal ? newDeal.targetLaunchDate : null)}
            onChange={newValue => handleChange('targetLaunchDate', normalizeDate(newValue, userTimezone))}
            slotProps={{
              textField: {
                fullWidth: true,
                error: validationErrors.targetLaunchDate,
              },
            }}
            sx={fieldStyles(fieldBgColor)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ContractInfoHeader
