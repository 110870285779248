import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Token from './authentication/Token'
import AuthCallback from './authentication/AuthCallback'
import ProtectedRoute from './authentication/ProtectedRoute'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ProtectedApp from './ProtectedApp'
import AuthProvider from './authentication/AuthContext'
import { Grid } from '@mui/material'
// import QueryDataDebugger from './api/queryFunctions/QueryDataDebugger'

// const queryClient = new QueryClient()
// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       staleTime: Infinity, // Prevent all queries from becoming stale
//       cacheTime: 1000 * 60 * 30, // Cache for 30 minutes
//       refetchOnWindowFocus: false, // Disable refetching on window focus
//       refetchOnReconnect: false, // Disable refetching on reconnect
//     },
//   },
// })
// console.log('QueryClient initialized')
const Main = () => {
  console.log('Main component mounted')
  return (
    <AuthProvider>
      <Router>
        {/* <QueryDataDebugger /> */}
        <Routes>
          <Route path='/' element={<Token />} />
          <Route path='/callback' element={<AuthCallback />} />
          <Route
            path='/*'
            element={
              <ProtectedRoute>
                {/* <QueryClientProvider client={queryClient}> */}
                <ProtectedApp />
                {/* </QueryClientProvider> */}
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default Main
