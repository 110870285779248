import { Box, Grid, Avatar, Typography, InputLabel, Drawer, Tooltip, Icon } from '@mui/material'
import { formatDayjsDate, formatDayJsTime } from './renderDealsColumns'

import { useState } from 'react'
import TimelineType from '../../Timeline/TimelineType'
import { renderColorByString } from '../renderColorByString'
import { renderTimelineText } from '../renderTimelineText'

export const renderTimelineColumns = isHovered => {
  return [
    {
      field: 'user',
      headerName: 'User',
      width: 100,
      flex: 0.2,
      valueGetter: params => params,
      renderCell: params => {
        const user = params.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Tooltip title={user?.name}>
              <Avatar src={user?.image} />
            </Tooltip>
          </Grid>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const action = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography sx={{ color: renderColorByString(params?.value), fontWeight: 'bold' }}>
              {renderTimelineText(action)}
            </Typography>
          </Grid>
        )
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.5,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const details = params?.value
        const timestamp = params && params.row && params.row.timestamp ? formatDayJsTime(params.row.timestamp) : null
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* <Typography>{`${details} @ ${timestamp}`}</Typography> */}
            <Typography>{`${details}`}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Time',
      flex: 0.3,
      minWidth: 100,
      valueGetter: params => params,
      renderCell: params => {
        const details = params?.value
        const timestamp = params && params.row && params.row.timestamp ? formatDayJsTime(params.row.timestamp) : null
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{`${timestamp}`}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 0.7,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const message = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{message ? message : '—'}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'dataset',
      headerName: 'Data Set',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const dataset = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{dataset}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const type = params?.value
        return <TimelineType type={type} />
      },
    },

    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 100,
      flex: 1,
      valueGetter: params => params,
      renderCell: params => {
        const timestamp = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{formatDayjsDate(timestamp)}</Typography>
          </Grid>
        )
      },
    },
  ]
}

export default renderTimelineColumns
