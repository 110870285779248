import React, { useState } from 'react'
import { Drawer, Box, Typography, useTheme, Grid, CircularProgress } from '@mui/material'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useOrganizationById } from '../../api/aws/useOrganization'
import useTotals from '../../api/customHooks/useTotals'
import FinancialTotals from './FinancialTotals'
import { useContact } from '../../api/aws/useContact'
import AccountingDrawerHeader from './AccountingDrawerHeader'
import DetailsLoading from '../Loading/DetailsLoading'

const AccountingDrawer = ({ open, onClose }) => {
  const { newDeal, productRows } = useDealDetailsContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal?.organizations?.[0],
    ['accounting', 'organization', newDeal?.organizations?.[0]]
  )
  const {
    data: billingContact,
    isLoading: isBillingContactLoading,
    isError: isBillingContactError,
  } = useContact(organization?.billingContact?.[0], ['accounting', 'billingContact', organization?.billingContact?.[0]])
  const [isLoading, setIsLoading] = useState(isOrganizationLoading || isBillingContactLoading)
  const theme = useTheme()
  const totals = useTotals(
    productRows,
    newDeal && newDeal?.implementationFee ? newDeal?.implementationFee : 0,
    newDeal?.type
  )
  if (isOrganizationError || isBillingContactError) {
    return <Typography>Organization or Billing Contact Error...</Typography>
  }
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box
        sx={{
          width: '1200px',
          padding: 2,
          backgroundColor: theme.palette.background.default,
          overflowY: 'hidden',
        }}
      >
        {newDeal ? (
          <>
            <AccountingDrawerHeader
              organization={organization}
              billingContact={billingContact}
              newDeal={newDeal}
              totals={totals}
              isLoading={isLoading}
            />
            <Grid item xs={12} sm={12} lg={12} sx={{ height: 'calc(100vh - 75px)' }}>
              <FinancialTotals containerHeight={'100%'} hideViewIcon={true} />
            </Grid>
          </>
        ) : (
          <Typography>No Deal Selected</Typography>
        )}
      </Box>
    </Drawer>
  )
}

export default AccountingDrawer
