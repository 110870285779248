import { useGetQueryByIds } from './useGetQueryByIds'

export const useDealsByIds = dealIds => {
  const validIds = Array.isArray(dealIds) ? dealIds.filter(id => !!id) : []
  const uniqueValidIds = [...new Set(validIds)]

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/deals', 'deals', uniqueValidIds, 'deal')
  // Extract user data from successful queries
  const deals = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data
  // Aggregate loading and error states
  const isDealsLoading = queryResults.some(result => result.isLoading)
  const isDealsError = queryResults.some(result => result.isError)
  const dealsByIdsError = queryResults.find(result => result.isError)?.error

  return { deals, isDealsError, isDealsLoading, dealsByIdsError }
}
