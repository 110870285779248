import React from 'react'
import { Tooltip, IconButton, Box, Typography, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

const RichTextTooltip = ({ content, onClick, isDraft }) => {
  const theme = useTheme()
  return (
    <>
      {isDraft ? (
        <Tooltip
          title={
            <Box
              dangerouslySetInnerHTML={{ __html: content }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.text.primary,
                fontSize: '0.875rem',
                lineHeight: '1.4em',
              }}
            />
          }
          arrow
          interactive='true'
          placement='right'
          slotProps={{
            tooltip: {
              sx: {
                maxWidth: '600px', // Custom max-width for the tooltip container
                backgroundColor: theme.palette.background.tooltip, // Custom background color
                color: 'white',
                padding: '16px',
                borderRadius: '8px',
                textAlign: 'left',
              },
            },
            arrow: {
              sx: {
                color: theme.palette.background.tooltip, // Make sure the arrow matches the tooltip background
              },
            },
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10], // Adjust tooltip arrow positioning
                  },
                },
              ],
            },
          }}
        >
          <IconButton onClick={onClick} sx={{ padding: 1.5 }} size='small' disabled={!isDraft}>
            <EditIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={onClick} sx={{ padding: 1.5 }} size='small' disabled={!isDraft}>
          <EditIcon
            fontSize='small'
            sx={{ color: !isDraft ? theme.palette.text.secondary : theme.palette.text.primary }}
          />
        </IconButton>
      )}
    </>
  )
}

export default RichTextTooltip
