import React from 'react'
import { Avatar, Box, Typography, Divider, useTheme, CircularProgress } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { detailHeaderStyle, stringAvatar } from './style'
import RenderVertical from './RenderVertical'
import RenderHeaderChip from '../Headers/RenderHeaderChip'
import RenderChip from '../RenderChipComponents/RenderChip'
import { getDensityStyles } from '../DataGrid/style'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useQueryClient } from '@tanstack/react-query'

const OrganizationDetailsHeader = () => {
  const { orgId } = useParams()
  const queryClient = useQueryClient()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId, [
    'organizationHeader',
    orgId,
  ])
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 3,
        position: 'sticky',
        zIndex: 901,
        marginLeft: 5,
        mr: 1,
        p: 0.8,
      }}
    >
      {organization && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
            {organization.photoUrl ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <Avatar src={organization.photoUrl} sx={{ width: 50, height: 50 }} />
                <Typography sx={{ fontWeight: 'bold' }}>{organization.name}</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  gap: 4,
                }}
              >
                <Avatar
                  {...stringAvatar(organization.name)}
                  sx={{
                    backgroundColor: 'rgb(114, 229, 218)',
                    width: 50,
                    height: 50,
                  }}
                  alt={organization.name}
                />
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {organization.name}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
          <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
          <Typography sx={detailHeaderStyle(theme)}>{'Organization Details'}</Typography>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />

          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, gap: 1 }}>
            {organization && Array.isArray(organization.vertical) ? (
              organization.vertical.map((res, index) => (
                <RenderVertical key={`vertical-organization.id-${index}`} vertical={res} />
              ))
            ) : (
              <RenderVertical vertical={organization.vertical} />
            )}
          </Box>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, gap: 1 }}>
            <RenderChip type={organization && organization.type ? organization.type : ''} />
          </Box>
        </>
      )}
    </Box>
  )
}

export default OrganizationDetailsHeader
