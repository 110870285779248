import React from 'react'
import { Grid } from '@mui/material'
import StrategyNotes from './StrategyNotes'

const StrategyNotesSection = ({ newNoteContent, setNewNoteContent, isEditMode, notes, setNotes }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} pl={2.5}>
          <StrategyNotes
            isEditMode={isEditMode}
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            notes={notes}
            setNotes={setNotes}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StrategyNotesSection
