import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { pandaAccessToken } from './useGetDocuments'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const reassignContactRecipient = async documentData => {
  // const url = `${baseUrl}/aws/pandadocuments`
  const url = `${baseUrl}/aws/pandadocuments/${documentData?.deal?.contractId}/reassign/contact`
  if (!pandaAccessToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.post(url, documentData, {
      headers: { Authorization: ` ${pandaAccessToken}` },
    })
    console.log('Response Reassign Recipient: ', response)
    return response.data
  } catch (error) {
    console.error('Error reassigning recipient:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useReassignContactRecipient = () => {
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: reassignContactRecipient,
    onSuccess: () => {
      console.log('Successfully reassigned recipient')
      showSnackbar('Successfully reassigned recipient', 'success')
    },
    onError: error => {
      console.error('[useReassignRecipient] Error reassigning recipient:', error)
      showSnackbar('Failed to update recipients in PandaDoc.', 'error')
    },
  })

  return mutation
}
