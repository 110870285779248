import { styled } from '@mui/material'
import { Box, Typography, TextField } from '@mui/material'
import { Link } from 'react-router-dom'

export const DialogIcon = styled('img')({
  width: 50,
  height: 50,
  borderRadius: '50%',
})

// export const dialogStyles = theme => ({
//   width: '100%',
//   maxWidth: '800px',
//   backgroundColor: theme.palette.mode === 'dark' ? '#050607' : 'rgba(255, 255, 255)',
//   color: '#fff',
//   minHeight: '50vh',
//   padding: '30px',
//   '& .MuiDialogContent-root': {
//     width: '100%',
//     padding: '20px',
//   },
//   '& .MuiTextField-root': {
//     margin: '10px 0',
//   },
// })
export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  // backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'rgba(255,255,255,1)',
    minWidth: '100%', // Ensure the input base width fits within the dialog
  },
  '& .MuiFormControl-root': {
    width: '100%', // Ensure the form control takes full width
  },
  '& .MuiDateRangePicker-root': {
    width: '100%', // Ensure the DateRangePicker takes full width
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,51)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
})
export const personIconStyles = {
  color: 'rgb(255, 123, 187)',
}

export const dialogTitleStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
  fontWeight: 'bold',
  textAlign: 'center',
})
export const boxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '0 auto',
}

export const inputStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgb(229, 229, 229)',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
})
export const ContactTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim rgba(255,255,255,1) borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',

  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim rgba(255,255,255,1) border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
      // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
      // borderColor: 'rgba(255,255,255,1)', // Change border color when focused
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40 53 69)' : '#f0f0f0', // Ensure the input itself also changes background color
    },
  },
}))
export const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#f4f6f8',
  padding: theme.spacing(6),
  borderRadius: '25px',
}))

export const InfoRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: 'relative',
}))

export const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  // fontSize: '0.875rem',
  fontSize: '1.175rem',
  marginBottom: theme.spacing(0.5),
}))

export const InfoValueWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(1),
}))

export const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  fontSize: '1rem',
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2',
  fontWeight: 700,
  fontSize: '1rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const Underline = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '1px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
}))

export const CityStateZipRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  position: 'relative',
}))
