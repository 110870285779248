import React from 'react'
import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material'
const SelectBusinessType = ({ businessType, onChange, validationErrors }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id='business-type-label' error={validationErrors.businessType}>
        Business Type
      </InputLabel>
      <Select
        labelId='business-type-label'
        label='Business Type'
        value={businessType}
        onChange={onChange}
        required
        error={validationErrors.businessType}
      >
        <MenuItem value='New/Add-on Business'>New/Add-on Business</MenuItem>
        <MenuItem value='Replace Existing Business'>Replace Existing Business</MenuItem>
      </Select>
      {/* <FormHelperText>{validationErrors.businessType && 'Business type is required'}</FormHelperText> */}
    </FormControl>
  )
}

export default SelectBusinessType
