import React, { useState } from 'react'
import { Autocomplete, TextField, Chip, CircularProgress, Box } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useUsers } from '../../api/aws/useUsers'
import { renderUserOption } from '../Users/renderOptions'
import { useUsersById } from '../../api/customHooks/useUsersById'

const AssignOwnerAutocomplete = ({ users, userId, label, onChange, context, onBlur }) => {
  // const { users, isUsersLoading, isUsersError } = useUsers(true, ['assignOwnerAutocomplete', 'users'])
  const [inputValue, setInputValue] = useState('')
  const { users: currentUser, isUserLoading } = useUsersById(userId && Array.isArray(userId) ? userId : [userId])
  const [selectedUsers, setSelectedUsers] = useState(currentUser?.[0])

  const handleSelectUser = (event, newValue) => {
    onChange(context, newValue ? [newValue.id] : [])
    onBlur(context, newValue ? [newValue.id] : [])
  }
  const handleRemoveUser = userId => {
    const newSelectedUsers = selectedUsers.filter(user => user.id !== userId)
    setSelectedUsers(newSelectedUsers)
    onChange(
      context,
      newSelectedUsers.map(user => user.id)
    )
    onBlur(
      context,
      newSelectedUsers.map(user => user.id)
    )
  }
  if (isUserLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Autocomplete
      // multiple={multiple}
      id='users-autocomplete'
      options={users || []}
      getOptionLabel={option =>
        option.real_name
          ? option.real_name
          : `${option.firstName || option.first_name} ${option.lastName || option.last_name} (${option.email})`
      }
      value={selectedUsers}
      onChange={handleSelectUser}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      filterOptions={(options, { inputValue }) => {
        let filtered = options.filter(option =>
          option.real_name
            ? option.real_name
            : `${option['First Name'] || option.firstName || option.first_name} ${
                option['Last Name'] || option.lastName || option.last_name
              }`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
        )
        return filtered
      }}
      renderOption={renderUserOption}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={key}
              label={`${option['First Name'] || option.firstName || option.first_name} ${
                option['Last Name'] || option.lastName || option.last_name
              }`}
              {...rest}
              onDelete={() => handleRemoveUser(option.id || option.gusers_uuid)}
              sx={{
                fontWeight: 'bolder',
                color: 'rgb(118, 87, 184)',
                backgroundColor: '#ebdbfd',
                '& .MuiChip-deleteIcon': {
                  color: 'rgba(0, 0, 0, 0.7)', // Darken the icon color
                  '&:hover': {
                    color: 'rgba(0, 0, 0, 0.9)', // Darken more on hover
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add background color on hover
                    borderRadius: '50%', // Make it round
                  },
                },
              }}
              deleteIcon={<CancelIcon />}
            />
          )
        })
      }
      renderInput={params => <TextField {...params} label={label} />}
      fullWidth
      disabled={context === 'createOwner'}
    />
  )
}

export default AssignOwnerAutocomplete
