import React from 'react'
import { Grid, useTheme, Typography } from '@mui/material'
import RenderContactChip from './RenderContactChip'
import RenderExtraContactsTooltip from './RenderExtraContactsTooltip'

const RenderDealContacts = ({ contacts }) => {
  const theme = useTheme()
  const maxVisibleContacts = 2
  const extraCount = contacts ? contacts.length - maxVisibleContacts : 0
  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridItemStyles = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }

  if (!contacts || contacts.length === 0) {
    return <Typography sx={{ color: textColor }}>No Contacts Assigned</Typography> // Handle empty state
  }

  return (
    <Grid item xs={12} sx={gridItemStyles}>
      {contacts.length > 0 && (
        <>
          <RenderContactChip contacts={contacts} />
          {extraCount > 0 && <RenderExtraContactsTooltip contacts={contacts} />}
        </>
      )}
    </Grid>
  )
}

export default RenderDealContacts
