import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { updateDealTimelineData } from '../../components/Timeline/createTimelineData'
import { useCreateTimeline } from '../aws/useCreateTimeline'

const pandaAccessToken =
  process.env.REACT_APP_ENV === 'local'
    ? '44c682867cd90875b3ba53db7280c56e1a4d6068'
    : localStorage.getItem('panda_access_token')

const generateLinks = async linkData => {
  const contractId = linkData?.contractId
  const dealId = linkData?.dealId
  const url = `${baseUrl}/aws/pandadocuments/${contractId}/send`
  if (!pandaAccessToken) {
    console.error('No authentication token available')
    return
  }
  try {
    const response = await axios.post(
      url,
      { dealId },
      {
        headers: { Authorization: ` ${pandaAccessToken}` },
      }
    )
    console.log('Response generate links: ', response)
    return response.data
  } catch (error) {
    console.error('Error generating links:', error)
    throw error // Re-throw the error for useMutation's onError to catch
  }
}

export const useGenerateLinks = (newDeal, activeUser) => {
  const { showSnackbar } = useSnackbarContext()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const timelineData = updateDealTimelineData(
    newDeal?.deal_uuid,
    activeUser,
    'Generate',
    'document',
    `Links Generated`,
    `Links generated via PandaDoc API`
  )
  const mutation = useMutation({
    // Directly pass the object to the mutation function
    mutationFn: generateLinks,
    onSuccess: () => {
      showSnackbar('Links generated successfully', 'success')
      console.log('Successfully generated links')
      createTimelineEntry(timelineData)
    },
    onError: error => {
      console.error('[useGenerateLinks] Error setting to draft:', error)
    },
  })

  return mutation
}
