import React from 'react'
import { Box, CircularProgress, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

const SaveContractIconButton = ({ onClick, isSaving, isDraft }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={<Typography>Save & Update</Typography>}>
        <IconButton
          size='medium'
          sx={{
            borderRadius: '50px',
            // backgroundColor: isDraft ? 'rgb(78, 170, 237)' : '',
            border: isDraft ? `2px solid rgb(78, 170, 237)` : `2px solid ${theme.palette.text.secondary}`,
          }}
          disabled={isSaving || !isDraft}
          onClick={onClick}
          name='save-update-icon-button'
          variant='outlined'
        >
          {!isSaving ? (
            <SaveIcon sx={{ fontSize: '34px' }} />
          ) : isSaving ? (
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          ) : (
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default SaveContractIconButton
