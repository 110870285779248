import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Chip, useTheme } from '@mui/material'
import ContactsDialog from '../Contacts/ContactsDialog'
import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'
import { useCreateContact } from '../../api/aws/useCreateContact'

const OrgPrimaryContactAutocomplete = ({ options, value, label, onChange, onBlur }) => {
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const [inputValue, setInputValue] = useState('')
  const { mutate: createContact } = useCreateContact()

  useEffect(() => {
    // Set the initial selected contact based on the value prop
    if (value && value.length > 0 && options.length > 0) {
      const initialContact = options.find(contact => contact.contacts_uuid === value[0])
      setSelectedPrimaryContact(initialContact || null)
    }
  }, [value, options])

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('')
  }

  const handleCreateContacts = newValue => {
    createContact(newValue, {
      onSuccess: data => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        setSelectedPrimaryContact(newContact)
        onChange([newContact.contacts_uuid])
        handleCloseDialog()
      },
    })
  }

  const handleSelectExistingContact = (event, newValue) => {
    if (newValue) {
      setSelectedPrimaryContact(newValue)
      onChange([newValue.contacts_uuid])
    } else {
      setSelectedPrimaryContact(null)
      onChange([])
    }
  }

  const handleBlur = () => {
    onBlur('primaryContact', selectedPrimaryContact ? [selectedPrimaryContact.contacts_uuid] : [])
  }

  return (
    <>
      <Autocomplete
        id='primary-options-autocomplete'
        options={options || []}
        getOptionLabel={option => option.name || ''}
        value={selectedPrimaryContact}
        onChange={handleSelectExistingContact}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onBlur={handleBlur}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
            filtered.unshift({ inputValue, specialOption: true, id: `add-new-${inputValue}` })
          }
          return filtered
        }}
        renderOption={(props, option) =>
          option.inputValue ? (
            <AddRenderOptionButton
              key={option.contacts_uuid}
              props={props}
              option={option}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <li {...props} key={option.contacts_uuid}>
              {option.name}
            </li>
          )
        }
        renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default OrgPrimaryContactAutocomplete
