import React from 'react'
import SaveAndUpdateButton from '../ui/save-and-update-contract'
import SaveContractButton from '../ui/save-contract-button'
import { useTheme, useMediaQuery } from '@mui/material'
import SaveIconButton from '../ui/save-icon-button'
import SaveAndUpdateIconButton from '../ui/save-and-update-icon-button'
import SaveContractIconButton from '../ui/save-contract-icon-button'

const RenderSaveButtons = ({ contractId, onSave, onSaveAndUpdate, isSaving, isUpdating, isDraft }) => {
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.up('xs'))
  return (
    <>
      {isExtraLargeScreen || isLargeScreen || isMediumScreen || isSmallScreen ? (
        <>
          {contractId ? (
            <SaveAndUpdateButton
              onClick={onSaveAndUpdate}
              isSaving={isSaving}
              isUpdating={isUpdating}
              isDraft={isDraft ?? false}
              color='secondary'
              variant='contained'
            />
          ) : (
            <SaveContractButton
              onClick={onSave}
              isSaving={isSaving}
              isDraft={isDraft ?? false}
              color='secondary'
              variant='contained'
            />
          )}
        </>
      ) : (
        <>
          {contractId ? (
            <SaveAndUpdateIconButton
              onClick={onSaveAndUpdate}
              isSaving={isSaving}
              isUpdating={isUpdating}
              isDraft={isDraft ?? false}
            />
          ) : (
            <SaveContractIconButton onClick={onSave} isSaving={isSaving} isDraft={isDraft ?? false} />
          )}
        </>
      )}
    </>
  )
}

export default RenderSaveButtons
