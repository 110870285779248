import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Avatar, Box, AvatarGroup } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { renderUserOption } from '../Users/renderOptions'
import { useUsers } from '../../api/aws/useUsers'

const TeamMembersAutocomplete = ({ users, role, label, onChange, onBlur, defaultValue = [], isUpdating }) => {
  // const { users, isUsersLoading } = useUsers()
  const [selectedUsers, setSelectedUsers] = useState(defaultValue)

  useEffect(() => {
    setSelectedUsers(defaultValue)
  }, [defaultValue])

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue) // Trigger update in the parent component
  }
  const handleBlur = () => {
    onBlur(
      role,
      selectedUsers?.map(user => user?.user_uuid)
    )
  }

  return (
    <Autocomplete
      multiple
      options={users || []}
      getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`}
      value={selectedUsers}
      onChange={(event, newValue) => handleSelectUser(event, newValue)}
      onBlur={handleBlur}
      renderOption={renderUserOption}
      renderTags={
        (tagValue, getTagProps) => {
          // tagValue.map((option, index) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: 1,
              }}
            >
              <AvatarGroup
                max={4}
                spacing={'small'}
                sx={{ mr: 1, ml: 1 }}
                slotProps={{
                  additionalAvatar: {
                    sx: {
                      height: 36,
                      width: 36,
                    },
                  },
                }}
              >
                {tagValue.map((option, index) => {
                  const { key, onDelete, ...rest } = getTagProps({ index })
                  return (
                    <Box
                      key={option.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      {...rest}
                    >
                      <Avatar
                        key={option.id}
                        alt={option.real_name}
                        src={option.image_512}
                        sx={{ height: 36, width: 36 }}
                      />
                    </Box>
                  )
                })}
              </AvatarGroup>
            </Box>
          )
        }
        // })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          sx={{
            maxheight: '60px', // Consistent height
            '& .MuiAutocomplete-inputRoot': {
              maxHeight: '60px', // Consistent input height
              height: '60px',
            },
            '& .MuiInputBase-root': {
              p: 0,
            },
          }}
        />
      )}
      fullWidth
      sx={{
        minHeight: '60px', // Consistent overall height
      }}
    />
  )
}

export default TeamMembersAutocomplete
