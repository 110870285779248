import React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, useTheme, IconButton, Typography, Button } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import EditStrategyButton from '../ui/edit-strategy-button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { iconButtonSyles, cancelIconStyles } from './style'
import AddIcon from '@mui/icons-material/Add'

const EditModeButtons = ({ handleSave, onAddCampaign }) => {
  const theme = useTheme()
  const { dealId } = useParams()
  const { newDeal, isEditMode, handleStartEdit, setIsEditMode } = useDealDetailsContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleNavigateBack = () => {
    if (pathname.includes('/nco')) {
      console.log('path includes /nco')
      navigate(`/nco/${dealId}/strategy`) // Navigate back to the NCO strategy tab
    } else if (pathname.includes('/deals')) {
      console.log('path includes /deals')
      navigate(`/deals/${dealId}/strategy`) // Navigate back to the Deals strategy tab
    } else {
      console.log('fallback to navigate(-1)')
      navigate(-1) // Default: Go back one in history
    }
  }

  return (
    <>
      {isEditMode ? (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'right',
            width: '100%',
            height: '100%',
            pl: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'left', width: '100%', gap: 1 }}>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 22,
                height: '100%',
                alignContent: 'center',
              }}
            >
              {'Campaigns (Edit Mode)'}
            </Typography>
            <IconButton sx={{ height: 45, width: 45 }} onClick={onAddCampaign}>
              <AddIcon />
            </IconButton>
          </Box>
          <IconButton onClick={() => setIsEditMode(false)} sx={iconButtonSyles}>
            <CancelIcon sx={cancelIconStyles} />
            <Typography>Cancel</Typography>
          </IconButton>
          <IconButton
            onClick={handleSave}
            sx={{
              gap: 2,
              borderRadius: 0,
              color: theme.palette.text.secondary,
              '&:hover': {
                color: theme.palette.text.primary,
                fontWeight: 'bold',
              },
              '&:hover .MuiSvgIcon-root': {
                color: 'rgb(50, 187, 130)',
              },
            }}
          >
            <TaskAltIcon
              sx={{
                height: 30,
                width: 30,
                color: theme.palette.text.secondary,
              }}
            />
            <Typography>Save</Typography>
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignContent: 'center', pl: 1 }}>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'left', width: '100%', gap: 1 }}>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 22,
                height: '100%',
                alignContent: 'center',
              }}
            >
              Campaigns
            </Typography>
            <IconButton sx={{ height: 45, width: 45 }} onClick={onAddCampaign}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'right', width: '100%', gap: 2 }}>
            <IconButton onClick={handleNavigateBack} sx={iconButtonSyles}>
              <ArrowBackIcon
                sx={{
                  height: 30,
                  width: 30,
                  gap: 2,
                  borderRadius: 0,
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: theme.palette.text.primary,
                    fontWeight: 'bold',
                  },
                  '&:hover .MuiSvgIcon-root': {
                    color: 'rgb(50, 187, 130)',
                  },
                }}
              />
              <Typography>Back</Typography>
            </IconButton>
            <EditStrategyButton color={'primary'} variant='contained' onEdit={() => handleStartEdit()} />
          </Box>
        </Box>
      )}
    </>
    // {/* </Box> */}
  )
}

export default EditModeButtons
