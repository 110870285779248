import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useUsersById = (userIds, queryString = 'user') => {
  const validIds = Array.isArray(userIds) ? userIds.filter(id => !!id) : []
  const uniqueValidIds = [...new Set(validIds)]
  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/users', 'users', uniqueValidIds, queryString)

  // Extract user data from successful queries
  const users = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data

  // Aggregate loading and error states
  const isUsersByIdLoading = queryResults.some(result => result.isLoading)
  const isUsersByIdError = queryResults.some(result => result.isError)
  const usersByIdError = queryResults.find(result => result.isError)?.error

  return { users, isUsersByIdLoading, isUsersByIdError, usersByIdError }
}
