import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, useTheme } from '@mui/material'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { v4 as uuidv4 } from 'uuid'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { handleCreateStrategyObject } from './handleCreateStrategyObject'
import { useStrategy } from '../../api/aws/useStrategy'
import { useCreateStrategy } from '../../api/aws/useCreateStrategy'
import RenderStrategySection from './RenderStrategySection'
import useSaveStrategy from '../../api/customHooks/useSaveStrategy'
import RenderContent from './RenderContent'
import StrategyNotesSection from './StrategyNotesSection'
import DetailsLoading from '../Loading/DetailsLoading'
import { strategyFieldConfig } from './strategyFieldConfig'
import RenderHeader from './RenderHeader'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import StrategyNotesHeader from './StrategyNotesHeader'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import { initializeProductFields } from './initializeProductFields'

const Strategy = ({ dealId: propDealId }) => {
  const { dealId: routeDealId } = useParams()
  const location = useLocation()
  const dealId = propDealId || routeDealId
  const queryClient = useQueryClient()
  const { strategy: existingStrategy, isLoading } = useStrategy(dealId ?? null, ['strategy', dealId ?? null])
  const theme = useTheme()
  const { newDeal, strategy, setStrategy, handleStartEdit, handleStopEdit, isEditMode } = useDealDetailsContext()
  const { strategyErrors, validateStrategy, handleValidateStrategy } = useValidationContext()
  const orgId = newDeal?.organizations?.[0] || null
  const { organization } = useOrganizationById(orgId)
  const { showSnackbar } = useSnackbarContext()
  const { activeUser } = useActiveUser()
  const { mutate: saveStrategy } = useSaveStrategy()
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: createStrategy } = useCreateStrategy()
  const [strategyName, setStrategyName] = useState(`Strategy | ${newDeal?.name}` || '')
  const [updatedProducts, setUpdatedProducts] = useState(
    strategy?.updatedProducts?.length > 0 ? strategy?.updatedProducts : []
  )
  // Initialize each product in `newDeal.products` with fields from `productFieldsConfig`
  const [generalInfo, setGeneralInfo] = useState(existingStrategy?.generalInfo || {})
  const [addtlInfo, setAddtlInfo] = useState(existingStrategy?.addtlInfo || {})
  const [integration, setIntegration] = useState(existingStrategy?.integration || {})
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState([])
  const [isCreatingNote, setIsCreatingNote] = useState(false)

  useEffect(() => {
    if (!isLoading && existingStrategy !== undefined) {
      if (existingStrategy.status === 204) {
        console.log('no strategy found, creating new....')
        handleCreateStrategy()
      } else {
        setGeneralInfo(existingStrategy?.generalInfo)
        setAddtlInfo(existingStrategy?.addtlInfo)
        setIntegration(existingStrategy?.integration)
        setStrategy({
          name: `Strategy | ${newDeal?.name}`,
          ...existingStrategy,
        })
      }
    }
  }, [existingStrategy, isLoading])
  useEffect(() => {
    if (strategy) {
      handleValidateStrategy(strategy, newDeal)
    }
  }, [strategy])
  // useEffect(() => {
  //   if (strategyErrors) {
  //     console.log('strategyErrors', strategyErrors)
  //   }
  // }, [strategyErrors])
  useEffect(() => {
    if (newDeal && organization) {
      const authRecipientEmail = newDeal?.recipients?.authorizedRecipient?.[0]?.email || ''
      const contactRecipientEmail = newDeal?.recipients?.contactRecipient?.[0]?.email || ''
      const newStrategyName = newDeal?.name ? `Strategy | ${newDeal?.name}` : ''
      setGeneralInfo(prev => ({
        ...prev,
        groContact: authRecipientEmail,
        orgContact: contactRecipientEmail,
        type: newDeal?.type,
      }))
      setAddtlInfo(prev => ({
        ...prev,
        street: organization?.street ?? strategy?.addtlInfo?.street,
        city: organization?.city ?? strategy?.addtlInfo?.city,
        state: organization?.state ?? strategy?.addtlInfo?.state,
        zip: organization?.zip ?? strategy?.addtlInfo?.zip,
      }))
      setStrategyName(newStrategyName)
    }
  }, [newDeal, organization])
  const handleCreateStrategy = () => {
    if (existingStrategy?.length > 0) return

    // Ensure `strategy_uuid` is created if not already present
    const strategy_uuid = existingStrategy?.strategy_uuid || uuidv4()
    // const initializedProducts = newDeal?.products?.map(product => initializeProductFields(product))
    // console.log('initializedProducts', initializedProducts)
    const newStrategy =
      newDeal && organization
        ? {
            ...handleCreateStrategyObject(newDeal?.products, organization, strategy, newDeal),
            strategy_uuid: dealId, // Include strategy_uuid here
            deal_uuid: dealId,
            name: strategyName || `Strategy | ${newDeal?.name}`,
          }
        : []

    try {
      createStrategy(newStrategy, {
        onSuccess: () => {
          console.log('Successfully created new strategy')
          queryClient.invalidateQueries(['/aws/strategy', strategy_uuid]) // Use strategy_uuid here as well
        },
        onError: error => {
          console.error('[mutation] Error creating new strategy', error)
        },
      })
    } catch (error) {
      console.error('Error creating new strategy', error)
    }
    setStrategy(newStrategy)
  }

  const handleServiceFieldChange = (instanceId, fieldName, newValue) => {
    setStrategy(prevState => {
      const updatedProducts = prevState.updatedProducts.map(service =>
        service.instanceId === instanceId ? { ...service, [fieldName]: newValue } : service
      )
      return { ...prevState, updatedProducts }
    })
  }

  const handleSectionChange = setSectionState => (fieldName, value) => {
    setSectionState(prev => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  const handleSave = () => {
    const updatedStrategy = {
      ...strategy,
      name: strategyName,
      generalInfo: { ...generalInfo },
      addtlInfo: { ...addtlInfo },
      integration: { ...integration },
      ...updatedProducts,
    }
    console.log('updatedStrategy', updatedStrategy)

    saveStrategy(
      { strategyId: dealId, strategyData: updatedStrategy },
      {
        onSuccess: () => {
          showSnackbar('Strategy updated successfully!', 'success')
          queryClient.invalidateQueries(['strategy', dealId])
        },
        onError: error => {
          showSnackbar('Failed to update strategy', 'error')
          console.error('Failed to update strategy:', error)
        },
      }
    )
  }
  ////////// Notes //////////////
  const handleAddNote = () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)
      // Avoid adding empty notes
      const newNote = {
        note_id: uuidv4(),
        contents: newNoteContent,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedNotes = [...notes, newNote]
      setNotes(updatedNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'strategy_uuid',
              tableName: 'strategy',
              itemId: newDeal.deal_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: data => {
            showSnackbar('Note added successfully', 'success')
            console.log('successful note addition data: ', data)
            queryClient.setQueryData(['strategy', newDeal?.deal_uuid], old =>
              old ? { data: { ...data, id: data.strategy_uuid } } : { data: { ...data, id: data.strategy_uuid } }
            )
            handleClear()
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
          },
        }
      )
    }
  }
  const handleClear = () => {
    setIsCreatingNote(false)
    setNewNoteContent('') // This clears the note content state
  }

  if (isLoading) {
    return <DetailsLoading />
  }

  return (
    <>
      <Grid container sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
        <Grid item xs={9}>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 30,
              mb: 1,
              bgcolor: theme.palette.background.default,
              border: '1px solid',
            }}
          >
            {strategy && strategy.generalInfo && (
              <RenderHeader
                formState={strategy.generalInfo}
                isEditMode={isEditMode}
                generalInfo={generalInfo}
                setGeneralInfo={setGeneralInfo}
                onSave={handleSave}
                onChange={handleSectionChange(setGeneralInfo)}
                activeUser={activeUser}
                onRefresh={handleCreateStrategy}
                errors={strategyErrors}
              />
            )}
          </Box>
          <Box
            sx={{
              height: location?.pathname.includes('/nco') ? 'calc(100vh - 415px)' : 'calc(100vh - 325px)',
              overflowY: 'auto',
              p: 1,
              pr: 1.5,
              pb: 2,
            }}
          >
            {strategy && strategy.addtlInfo && (
              <RenderStrategySection
                page="Addt'l Info"
                fields={strategyFieldConfig["Addt'l Info"]}
                formState={addtlInfo}
                isEditMode={isEditMode}
                onChange={handleSectionChange(setAddtlInfo)}
                errors={strategyErrors}
              />
            )}

            {strategy && strategy.integration && (
              <RenderStrategySection
                page='Integration'
                fields={strategyFieldConfig['Integration']}
                formState={integration}
                isEditMode={isEditMode}
                onChange={handleSectionChange(setIntegration)}
                errors={strategyErrors}
              />
            )}

            {strategy && strategy.updatedProducts && strategy.updatedProducts.length > 0 && (
              <RenderContent
                strategy={strategy}
                setStrategy={setStrategy}
                organization={organization}
                sectionKey={'updatedProducts'}
                sectionLabel={'Products'}
                section={strategy.updatedProducts}
                isEditMode={isEditMode}
                onServiceFieldChange={handleServiceFieldChange}
                errors={strategyErrors}
                dealId={dealId}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 10,
              mb: 1,
            }}
          >
            <StrategyNotesHeader
              newNoteContent={newNoteContent}
              setNewNoteContent={setNewNoteContent}
              handleAddNote={handleAddNote}
              onClear={handleClear}
              isCreatingNote={isCreatingNote}
              setIsCreatingNote={setIsCreatingNote}
            />
          </Box>
          <Box
            sx={{
              height: location?.pathname.includes('/nco') ? 'calc(100vh - 415px)' : 'calc(100vh - 325px)',
              overflowY: 'auto',
              p: 1,
              pb: 2,
            }}
          >
            <StrategyNotesSection
              newNoteContent={newNoteContent}
              setNewNoteContent={setNewNoteContent}
              handleAddNote={handleAddNote}
              handleClear={handleClear}
              isCreatingNote={isCreatingNote}
              setIsCreatingNote={setIsCreatingNote}
              isEditMode={isEditMode}
              notes={notes}
              setNotes={setNotes}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Strategy
