import React from 'react'
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'

const SaveContractButton = ({ onClick, isSaving, isDraft }) => {
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 42,
          backgroundColor: isDraft ? 'rgb(78, 170, 237)' : '',
        }}
        // disabled={isRequesting || isRequested || !isValid}
        disabled={isSaving || !isDraft}
        onClick={onClick}
        name='save-contract-button'
        variant='outlined'
      >
        {!isSaving ? (
          <Typography>Save</Typography>
        ) : (
          <Typography>
            Saving...{' '}
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          </Typography>
        )}
      </Button>
    </Box>
  )
}

export default SaveContractButton
