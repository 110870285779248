import React, { useState, useEffect } from 'react'
import { Select, MenuItem, Typography, useTheme, Chip } from '@mui/material'
import { invoiceViewState, billingViewState, churnViewState } from '../Accounting/viewStates'

const ChangeViewSelect = ({ onChangeView, selectedView, setSelectedView }) => {
  const theme = useTheme()

  // Initialize the selectedView state from localStorage or default to 'invoice'
  // Update localStorage and invoke onChangeView when the view changes
  const handleChange = event => {
    const newView = event.target.value
    setSelectedView(newView)
    localStorage.setItem('accountingSelectedView', JSON.stringify(newView))
    onChangeView(newView)
  }

  // Ensure the saved view is applied on component mount
  useEffect(() => {
    if (selectedView === 'invoice') {
      onChangeView(invoiceViewState)
    } else if (selectedView === 'billing') {
      onChangeView(billingViewState)
    } else if (selectedView === 'churn') {
      onChangeView(churnViewState)
    }
  }, [selectedView, onChangeView])

  const options = [
    { label: 'Invoice View', value: 'invoice', color: theme.palette.text.success },
    { label: 'Billing View', value: 'billing', color: theme.palette.text.warning },
    { label: 'Churn View', value: 'churn', color: theme.palette.text.error },
  ]

  return (
    <Select
      value={selectedView}
      onChange={handleChange}
      sx={{
        minWidth: 200,
        maxWidth: 200,
        height: 40,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.paper,
        borderRadius: 2,
        '& .MuiSelect-select': { cursor: 'pointer' }, // Prevent I-beam cursor
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Chip
            size='small'
            style={{
              backgroundColor: option.color,
              width: 14,
              height: 14,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          <Typography variant='subtitle1'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChangeViewSelect

// import React, { useState } from 'react'
// import { Select, MenuItem, Typography, useTheme, Chip, Box } from '@mui/material'
// import { invoiceViewState, billingViewState, churnViewState } from '../Accounting/AccountingPage'

// const ChangeViewSelect = ({ onChangeView, filterModel, setFilterModel }) => {
//   const theme = useTheme()
//   const [selectedView, setSelectedView] = useState('invoice')

//   const handleChange = event => {
//     const newView = event.target.value
//     setSelectedView(newView)

//     if (newView === 'invoice') {
//       onChangeView(invoiceViewState)
//     } else if (newView === 'billing') {
//       onChangeView(billingViewState)
//     } else if (newView === 'churn') {
//       const churnStatuses = ['Cancelled', '30 Day Notice', 'Churned', 'Expired']
//       const updatedFilters = (filterModel.items || []).filter(item => !churnStatuses.includes(item.field))
//       updatedFilters.push(
//         {
//           field: 'dealStatus',
//           operator: 'contains',
//           value: 'Cancelled',
//         },
//         {
//           field: 'dealStatus',
//           operator: 'contains',
//           value: '30 Day Notice',
//         },
//         {
//           field: 'dealStatus',
//           operator: 'contains',
//           value: 'Churned',
//         },
//         {
//           field: 'dealStatus',
//           operator: 'contains',
//           value: 'Expired',
//         }
//       )
//       setFilterModel({ ...filterModel, logicOperator: 'or', items: updatedFilters })
//       onChangeView(churnViewState)
//     }
//   }

//   const options = [
//     { label: 'Invoice View', value: 'invoice', color: theme.palette.text.success },
//     { label: 'Billing View', value: 'billing', color: theme.palette.text.warning },
//     { label: 'Churn View', value: 'churn', color: theme.palette.text.error },
//   ]

//   return (
//     <Select
//       value={selectedView}
//       onChange={handleChange}
//       sx={{
//         minWidth: 200,
//         maxWidth: 200,
//         height: 40,
//         color: theme.palette.text.primary,
//         bgcolor: theme.palette.background.paper,
//         borderRadius: 2,
//         '& .MuiSelect-select': { cursor: 'pointer' }, // Prevent I-beam cursor
//       }}
//     >
//       {options.map(option => (
//         <MenuItem key={option.value} value={option.value}>
//           <Chip
//             size='small'
//             style={{
//               backgroundColor: option.color,
//               width: 14,
//               height: 14,
//               borderRadius: '50%',
//               marginRight: 8,
//             }}
//           />
//           <Typography variant='subtitle1'>{option.label}</Typography>
//         </MenuItem>
//       ))}
//     </Select>
//   )
// }

// export default ChangeViewSelect
