export const sortOrganizations = organizations => {
  if (!organizations) return
  const sortedOrganizations = organizations.reduce((acc, org) => {
    const type = org.type || 'Unknown'
    if (!acc[type]) acc[type] = ['']
    acc[type].push(org)
    return acc
  }, {})

  return Object.entries(sortedOrganizations)
    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
    .map(([type, orgs]) => orgs.sort((a, b) => a.name.localeCompare(b.name)))
    .flat()
}
