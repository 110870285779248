import {
  Box,
  Chip,
  IconButton,
  Typography,
  Tooltip,
  Link as MuiLink,
  Avatar,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip, NcoStatusContainer } from '../../../style/styleElements'
import { Link } from 'react-router-dom'
import PandaDocLinkIcon from '../../Icons/PandaDockLinkIcon'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles, DisabledIconButton } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import { analyzeStrategy } from '../../Strategy/analyzeStrategy'
import PandaDocViewer from '../../PaymentSchedule/PandDocViewer'
import CustomInvoiceFilter from './CustomInvoiceSelect'
import TypographyChip from '../../ui/typography-chip'
import EditSelectCell from '../../NCO/EditSelectCell'

export const renderTypeColor = (type, theme) => {
  switch (type) {
    case 'Marketing':
    case 'MSA':
    case 'RMSA':
    case 'MSA - Housing':
    case 'RMSA - Housing':
    case 'IO':
      return 'rgb(88, 188, 128)'
    case 'Creative':
    case 'CSA':
    case 'RCSA':
      return 'rgb(165, 147, 255)'
    case 'draft':
      return 'rgb(167, 172, 182)'
    case 'Draft':
      return 'rgb(167, 172, 182)'
    case 'saved':
      return 'rgb(35, 146, 173)'
    case 'Saved':
      return 'rgb(35, 146, 173)'
    case 'sent':
      return 'rgb(108, 218, 135)'
    case 'Sent':
      return 'rgb(93, 79, 251)'
    case 'created':
      return 'rgb(114, 229, 218)'
    case 'Created':
      return 'rgb(114, 229, 218)'
    case 'Active':
      return 'rgb(80, 200, 120)'
    case 'Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Rejected':
    case 'Expired':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Cancelled':
      return theme ? theme.palette.text.primary : 'rgba(0,0,0)'
    case 'Viewed':
      return 'rgb(213, 102, 201)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 1)'
    case 'Needs Approval':
      return 'rgb(251, 193, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 1)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 1)'
    case 'Completed':
      return 'rgb(80, 200, 120)'
    case 'Proposal':
      return 'rgb(174, 92, 174)'
    case 'Invoiced':
    case 'Current (<60)':
      return 'rgb(80, 200, 120)'
    case 'Not Invoiced':
      return 'rgb(249, 167, 49)'
    case 'Past Due (60+)':
      return theme.palette.text.warning
    case 'Past Due (90+)':
    case 'Potential Bad Debt':
      return theme.palette.text.error
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208)'
    case 'Month To Month':
      return 'rgb(22, 138, 239)'

    default:
      return 'rgb(178, 190, 181)'
  }
}
export const renderDealStatusColor = status => {
  switch (status) {
    case 'draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'Draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'Saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'sent':
      return 'rgb(108, 218, 135, 0.6)'
    case 'Sent':
      return 'rgb(93, 79, 251, 0.6)'
    case 'created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Active':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Approved':
      return 'rgb(6, 255, 167, 1)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case 'Rejected':
      return 'rgba(220, 20, 60, 0.8)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Viewed':
      return 'rgb(213, 102, 201, 0.6)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69, 0.6)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205, 0.6)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 0.6)'
    case 'Needs Approval':
      return 'rgb(245, 189, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 0.6)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 0.6)'
    case 'Completed':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Proposal':
      return 'rgb(174, 92, 174, 0.6)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208, 0.6)'
    case 'Month To Month':
      return 'rgb(22, 138, 239, 0.6)'
    default:
      return 'rgb(178, 190, 181, 0.6)'
  }
}
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
export const formatDayJsTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('hh:mm A')
  } else {
    return null
  }
}
const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

export const renderAccountingColumns = (organizations, users, deals, density = 'standard', handleDealClick, theme) => {
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      // console.log('[customNotContainsOperator] filterItem:', filterItem)

      // Check if the filter value is defined
      if (filterItem.value === undefined || filterItem.value === null) {
        // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
        return null
      }

      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
        return null
      }

      // Log the cellValue during the filter function application
      return cellValue => {
        // console.log('[customNotContainsOperator] cellValue:', cellValue)
        const result = !containsFn(cellValue)
        // console.log('[customNotContainsOperator] Result of filter application:', result)
        return result
      }
    },
  }
  const strictEqualsOperator = {
    label: 'is exactly',
    value: 'equals',
    getApplyFilterFn: filterItem => {
      if (!filterItem.value) {
        return null // No filter applied
      }

      return cellValue => {
        return cellValue === filterItem.value // Exact match
      }
    },
  }

  // Log the applied filterOperators

  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    // Ensure 'name' is a string, default to an empty string if not
    const validName = typeof name === 'string' ? name : ''

    const nameParts = validName ? validName.split(' ') : ['']

    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }
    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }
  return [
    {
      field: 'name',
      headerName: 'Deal Name',
      width: 100,
      flex: 1.75,
      headerAlign: 'left',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const dealId = params.row.id
        return (
          <Box onClick={() => handleDealClick(params.row)} sx={{ cursor: 'pointer', height: '100%', width: '100%' }}>
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.45,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {contractUrl ? (
              <PandaDocViewer contractId={contractId} customFileName={`${params?.row?.name}`} height={32} width={32} />
            ) : (
              // <DisabledIconButton disabled>
              //   <PandaDocLinkIcon size={densityStyles.globeSize} />
              // </DisabledIconButton>
              <PandaDocViewer
                contractId={contractId}
                customFileName={`${params?.row?.name}`}
                height={32}
                width={32}
                disabled={true}
              />
            )}
          </Box>
        )
      },
    },
    {
      field: 'dba',
      headerName: 'Legal Entity Name',
      width: 125,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            {params.value ? (
              <Typography sx={{ fontSize: 12 }}>{params.value}</Typography>
            ) : (
              <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>{'—'}</Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'invoiceStatus',
      headerName: 'Invoice Status',
      width: 100,
      flex: 1.05,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        // Default to "Not Invoiced" if the value is null or undefined
        return params === 'Invoiced' ? 'Invoiced' : 'Not Invoiced'
      },
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              // padding: '2px', // Adjust padding to ensure no overflow
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderTypeColor(params.value, theme), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => (
        <EditSelectCell
          params={params}
          options={[
            { value: 'Invoiced', label: 'Invoiced' },
            { value: 'Not Invoiced', label: 'Not Invoiced' },
          ]}
        />
      ),
    },
    {
      field: 'billingStatus',
      headerName: 'Billing Status',
      width: 100,
      flex: 1.05,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        // Default to "Not Invoiced" if the value is null or undefined
        return params ?? 'Set Billing Status'
      },
      filterOperators: [...getGridStringOperators(), strictEqualsOperator],
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              // padding: '2px', // Adjust padding to ensure no overflow
              boxSizing: 'border-box', // Include padding in the size calculation
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderTypeColor(params.value, theme), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => (
        <EditSelectCell
          params={params}
          options={[
            { value: 'Current (<60)', label: 'Current (<60)', color: theme.palette.text.success },
            { value: 'Past Due (60+)', label: 'Past Due (60+)', color: theme.palette.text.warning },
            { value: 'Past Due (90+)', label: 'Past Due (90+)', color: theme.palette.text.error },
            { value: 'Potential Bad Debt', label: 'Potential Bad Debt', color: theme.palette.text.error },
          ]}
        />
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'organizations',
      headerName: 'Organization',
      width: 100,
      flex: 1.45,
      headerAlign: 'left',
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg = organizations.find(org => org.id === orgId) || organizations.find(org => org.zoho_id === orgId)
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params &&
          params.row &&
          params.row.organizations &&
          Array.isArray(params.row.organizations) &&
          params.row.organizations[0]
            ? params.row.organizations[0]
            : params && params.row && params.row.organizations && params.row.organizations.id
            ? params.row.organizations.id
            : null
        const photoUrl = organizations ? organizations.find(org => org.id === orgId)?.photoUrl : null
        const orgName = params && params.value ? params.value : ''

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'left',
                  height: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                }}
              >
                {photoUrl ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src={photoUrl}
                      sx={{
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      alt={params && params.value ? params.value : params}
                    />

                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params && params.value ? params.value : params}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      height: '100%',
                      gap: 1,
                    }}
                  >
                    <Avatar
                      {...stringAvatar(params && params.value ? params.value : params)}
                      sx={{
                        backgroundColor: 'rgb(114, 229, 218)',
                        width: densityStyles.avatarSize,
                        height: densityStyles.avatarSize,
                      }}
                      alt={params && params.value ? params.value : params}
                    />
                    <Typography
                      sx={{
                        fontSize: densityStyles.fontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {params && params.value ? params.value : params}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Link>
          </Box>
        )
      },
    },
    {
      field: 'dealStatus',
      headerName: 'Deal Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'reasonChurn',
      headerName: 'Reason',
      width: 125,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            {params.value ? (
              <Typography sx={{ fontSize: 12 }}>{params.value}</Typography>
            ) : (
              <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>{'—'}</Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'lastDateOfService',
      headerName: 'Date Ending',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'churnNote',
      headerName: 'Notes',
      width: 125,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            {params.value ? (
              <Typography sx={{ fontSize: 12 }}>{params.value}</Typography>
            ) : (
              <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>{'—'}</Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      width: 100,
      flex: 1.15,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        const contractedById = params && Array.isArray(params) ? params[0] : []
        const contractedBy = organizations ? organizations.find(org => org.id === contractedById) : null
        return contractedBy ? contractedBy.name : ''
      },
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const org = organizations ? organizations.find(org => org.id === orgId) : null
        const contractedById = org && Array.isArray(org.contractedBy) ? org.contractedBy[0] : null

        return (
          <>
            {params && params.value ? (
              <RenderContractedByColumn
                key={`${contractedById}`}
                orgName={params.value ?? ''}
                orgId={contractedById}
                densityStyles={densityStyles}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              >
                <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>{'—'}</Typography>
              </Box>
            )}
          </>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both are empty
        if (!a) return 1 // Treat empty as larger, so a (empty) goes after b (non-empty)
        if (!b) return -1 // Treat empty as larger, so b (empty) goes after a (non-empty)
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },

    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },
    // {
    //   field: 'creation_timestamp',
    //   headerName: 'Time Created',
    //   width: 100,
    //   flex: 1,
    //   headerAlign: 'left',
    //   renderCell: params => {
    //     return (
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '100%',
    //           padding: densityStyles.padding,
    //           gap: densityStyles.gap,
    //         }}
    //       >
    //         {formatDayjsDate(params.value)}
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsDate(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },

    // {
    //   field: 'owner',
    //   headerName: 'Owner',
    //   flex: 0.1,
    //   minWidth: 100,
    //   headerAlign: 'center',
    //   valueGetter: params => {
    //     const userId = params && params[0] ? params[0] : null
    //     const user = users.find(u => u.id === userId)
    //     return user && user.name ? user.name : 'No User Assigned'
    //   },
    //   renderCell: params => {
    //     const userId = params && params.row && params.row.owner ? params.row.owner[0] : null
    //     const user = users.find(u => u.id === userId)
    //     return (
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '100%',
    //           padding: densityStyles.padding,
    //           gap: densityStyles.gap,
    //         }}
    //       >
    //         {user ? (
    //           <UserImageComponent
    //             style={userImageStyles}
    //             imageUrl={user.image_512}
    //             width={densityStyles.avatarSize}
    //             height={densityStyles.avatarSize}
    //           />
    //         ) : (
    //           <Box
    //             sx={{
    //               width: densityStyles.avatarSize,
    //               height: densityStyles.avatarSize,
    //               borderRadius: '50%',
    //               backgroundColor: 'lightgray',
    //               display: 'flex',
    //               justifyContent: 'center',
    //               alignItems: 'center',
    //               color: 'rgba(0, 0, 0, 0.6)',
    //               fontSize: densityStyles.fontSize,
    //             }}
    //           >
    //             ?
    //           </Box>
    //         )}
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'amId',
      headerName: 'Account Manager',
      flex: 0.4,
      minWidth: 100,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        // return params ? { name: params?.name, image_512: params?.image_512 } : null
        return params ? params?.name : null
      },
      renderCell: params => {
        const user = params?.row?.amId
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user && user?.name && user?.image_512 ? (
              <Tooltip title={<Typography>{user?.name}</Typography>}>
                <Avatar
                  src={user?.image_512}
                  sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                  alt={user?.image_512}
                />
              </Tooltip>
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <Avatar />
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'billingContact',
      headerName: 'Billing Contact',
      flex: 0.4,
      minWidth: 100,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        return params ? params?.name : null
      },
      renderCell: params => {
        const user = params?.row?.billingContact
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user && user?.name && user?.photoUrl ? (
              <Tooltip title={<Typography>{user?.name}</Typography>}>
                <Avatar
                  src={user?.photoUrl}
                  sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                  alt={user?.photoUrl}
                />
              </Tooltip>
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <Avatar />
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'primaryContact',
      headerName: 'Primary Contact',
      flex: 0.4,
      minWidth: 100,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        return params ? params?.name : null
      },
      renderCell: params => {
        const user = params?.row?.primaryContact
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user && user?.name && user?.photoUrl ? (
              <Tooltip title={<Typography>{user?.name}</Typography>}>
                <Avatar
                  src={user?.photoUrl}
                  sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                  alt={user?.photoUrl}
                />
              </Tooltip>
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <Avatar />
              </Box>
            )}
          </Box>
        )
      },
    },

    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
  ]
}
