export const taskTypeList = [
  {
    value: 'New Client Onboard',
    label: 'New Client Onboard',
    color: 'rgb(176, 220, 81)',
  },
  { value: 'Campaign QA', label: 'Campaign QA', color: 'rgb(133, 214, 255)' },
  { value: 'Copy Update', label: 'Copy Update', color: 'rgb(253, 188, 100)' },
  {
    value: 'Campaign Updates',
    label: 'Campaign Updates',
    color: 'rgb(255, 213, 51)',
  },
  {
    value: 'Campaign Review',
    label: 'Campaign Review',
    color: 'rgb(51, 211, 145)',
  },
  { value: 'Budget Rec', label: 'Budget Rec', color: 'rgb(232, 105, 125)' },
  {
    value: 'Budget Updates',
    label: 'Budget Updates',
    color: 'rgb(51, 158, 205)',
  },
  {
    value: 'Launch New Creatives',
    label: 'Launch New Creatives',
    color: 'rgb(181, 125, 227)',
  },
  { value: 'GTM/GA Setup', label: 'GTM/GA Setup', color: 'rgb(53, 153, 112)' },
  {
    value: 'Campaign Setup',
    label: 'Campaign Setup',
    color: 'rgb(121, 175, 253)',
  },
  {
    value: 'AM Task  Update',
    label: 'AM Task  Update',
    color: 'rgb(213, 197, 103)',
  },
  {
    value: 'Offboard Client',
    label: 'Offboard Client',
    color: 'rgb(92, 92, 92)',
  },
  { value: 'SEO Change', label: 'SEO Change', color: 'rgb(201, 92, 118)' },
  { value: 'SEO Update', label: 'SEO Update', color: 'rgb(255, 68, 161)' },
  { value: 'Discovery', label: 'Discovery', color: 'rgb(255, 123, 208)' },
  {
    value: 'Strategy Request',
    label: 'Strategy Request',
    color: 'rgb(147, 111, 218)',
  },
  {
    value: 'New Agreement',
    label: 'New Agreement',
    color: 'rgb(255, 131, 88)',
  },
]
