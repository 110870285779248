import React from 'react'
import { Grid, useTheme, Typography } from '@mui/material'
import RenderUpdateComponents from '../MassUpdateComponents/RenderUpdateComponents'

export const AccountingGridFooter = ({
  deals = [],
  totalRowCount,
  visibleRowCount,
  rowCount,
  massUpdateData,
  selectionModel = [],
  onMassUpdateChange,
  handleMassUpdate,
  anchorEl,
}) => {
  const theme = useTheme()

  return (
    <>
      {selectionModel && selectionModel.length > 0 ? (
        <Grid
          container
          sx={{
            position: 'fixed',
            bottom: 60, // Keep it above the DataGrid footer
            left: '10%',
            right: '10%',
            maxWidth: '80%',
            zIndex: 1200,
            backgroundColor: theme.palette.background.default,
            boxShadow: '10px 21px 43px rgba(0, 0, 0, 0.1)',
            // borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            gap: 2,
            border: '1px solid',
            borderColor: theme.palette.text.secondary,
          }}
        >
          {/* Right RenderUpdateComponents Section */}
          <RenderUpdateComponents
            deals={deals}
            visibleRowCount={visibleRowCount}
            rowCount={rowCount}
            selectionModel={selectionModel}
            massUpdateData={massUpdateData}
            onMassUpdateChange={onMassUpdateChange}
            handleMassUpdate={handleMassUpdate}
          />
        </Grid>
      ) : null}
    </>
  )
}
