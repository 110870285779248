import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Button, Chip, Typography, Box, useTheme } from '@mui/material'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'
import { AutocompleteButton } from '../../style/styleElements'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useDeals } from '../../api/aws/useDeals'

const DealsAutocomplete = ({ multiple = true, label, value, onAddDeal }) => {
  const theme = useTheme()
  const { deals } = useDeals()
  const { showSnackbar } = useSnackbarContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'
  const { data: activeSlackUser } = useActiveUser()
  const [selectedDeals, setSelectedDeals] = useState(Array.isArray(value) ? value : [])
  const { mutate: createOrganization } = useCreateOrganization()

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
  }

  const handleAddDeal = (event, newDeal) => {
    console.log('New Organization Added: ', newDeal)
    if (newDeal) {
      onAddDeal(newDeal)
    } else {
      onAddDeal(null)
    }
    handleCloseDialog()
  }
  const handleRemoveDeal = dealId => {
    const newSelectedOrganizations = selectedDeals.filter(deal => deal.deal_uuid !== dealId)
    setSelectedDeals(newSelectedOrganizations)
  }

  const filterOptions = (options, { inputValue }) => {
    let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
    if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      filtered.unshift({
        inputValue,
        specialOption: true,
        id: `add-new-${inputValue}`,
      })
    }
    return filtered
  }

  useEffect(() => {
    if (selectedDeals) {
      console.log('[DealAutocomplete] selectedDeals: ', selectedDeals)
    }
  }, [selectedDeals])

  return (
    <>
      <Autocomplete
        multiple={multiple}
        // value={selectedDeals}
        id='deals-autocomplete'
        options={deals}
        onChange={(event, newValue) => handleAddDeal(event, newValue)}
        getOptionLabel={option => option.name || ''}
        // filterOptions={filterOptions}
        // renderOption={renderOption}
        renderOption={(props, option) => {
          const { key, id, ...rest } = props
          return (
            <Box
              key={`${key}-${id}`}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}
              {...rest}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{option?.name}</Typography>
              </Box>
            </Box>
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...rest } = getTagProps({ index })
            if (!option) {
              return null
            }
            return (
              <Box key={option.deal_uuid}>
                <Chip
                  key={key}
                  label={option?.name}
                  {...rest}
                  onDelete={() => handleRemoveDeal(option.deal_uuid)}
                  size='small'
                  style={{
                    height: 'auto',
                    width: 'auto',
                    borderColor: themeColor,
                    borderWidth: '0.5px',
                    borderStyle: 'solid',
                    backgroundColor: 'transparent',
                    color: themeColor,
                    borderRadius: '4px',
                    marginRight: '8px',
                  }}
                />
              </Box>
            )
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            sx={{
              '& .MuiInputBase-input': {
                color: selectedDeals ? 'lightblue' : 'inherit',
              },
            }}
          />
        )}
        // renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
    </>
  )
}

export default DealsAutocomplete
