import React from 'react'
import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material'
const SelectInvoiceOption = ({ invoiceOption, onChange, validationErrors }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id='invoice-option-label' error={validationErrors.invoiceOption}>
        Invoice Option
      </InputLabel>
      <Select
        labelId='invoice-option-label'
        label='Invoice Option'
        value={invoiceOption}
        onChange={onChange}
        required
        error={validationErrors.invoiceOption}
      >
        {/* <MenuItem value='' disabled>
          Select Business Type
        </MenuItem> */}
        <MenuItem value='Merge with Existing Invoice'>Merge with Existing Invoice</MenuItem>
        <MenuItem value='New Invoice'>New Invoice</MenuItem>
      </Select>
      {/* <FormHelperText>{validationErrors.type && 'Business type is required'}</FormHelperText> */}
    </FormControl>
  )
}

export default SelectInvoiceOption
