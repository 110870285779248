import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const createDeal = async dealData => {
  const url = `${baseUrl}/aws/deals`

  //////////////////////////////////////////////////
  console.log('NEW DEAL DATA TO SUBMIT[MUTATION]: ', dealData)
  const response = await axios.post(url, dealData, {})
  return response.data // This allows the response data to be accessed in onSuccess
}

export const useCreateDeal = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: createDeal,
    onMutate: async newDeal => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['deals'])

      // Snapshot the previous value
      const previousDeals = queryClient.getQueryData(['deals'])
      console.log('[useCreateDeal] previousDeals: ', previousDeals ? previousDeals : 'No Previous Deals Cached')
      // Optimistically update to the new value

      return { previousDeals }
    },
    onError: (err, newDeal, context) => {
      // Roll back the optimistic update
      queryClient.setQueryData(['deals'], context.previousDeals)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      console.log('[useCreateDeal] onSuccess data: ', data)
      // Invalidate and refetch or update query data

      // queryClient.setQueryData(['deals'], old =>
      //   old ? [...old, { ...data, id: data.deal_uuid }] : [{ ...data, id: data.deal_uuid }]
      // )
      showSnackbar('Deal created successfully', 'success')
    },

    onSettled: () => {
      // Always refetch after error or success:
      // queryClient.invalidateQueries(['deals'])
    },
  })

  return mutation
}
