import { styled } from '@mui/material/styles'
import { Button, Box, Card, Chip, Typography, TextField } from '@mui/material'
import { Link } from 'react-router-dom'

export const gridStyles = theme => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '20px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // This targets the border of the TextField
        border: 'none', // Removes the border
      },
      '&:hover fieldset': {
        border: 'none', // Ensures border is removed on hover
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Removes the border when the TextField is focused
      },
      '&.Mui-focused fieldset': {
        border: '2px solid rgb(159, 176, 219)', // Changes border when focused
        // backgroundColor: '#e3f2fd', // Changes background when focused
      },
    },
  },
  '& .MuiInputBase-root': {
    height: '45px',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'white',
  },
  '& input[type="number"]': {
    // Hides the up and down arrows on webkit browsers like Chrome, Safari, and Edge
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    // Hides the up and down arrows on Firefox
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
})

export const OrganizationsCard = styled(Card)(({ theme, type }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : 'rgb(255, 255, 255)',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  fontWeight: 'bold',
  //   minWidth: '100px', // Set a consistent width for the Chip
  //   maxWidth: '1000px', // Set a consistent width for the Chip
  //   minHeight: '70px',
  borderRadius: '4px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))

export const OrgDetailsBox = styled(Box)(({ theme, type }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : 'rgb(255, 255, 255)',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  fontWeight: 'bold',
  //   minWidth: '100px', // Set a consistent width for the Chip
  //   maxWidth: '500px', // Set a consistent width for the Chip
  //   minHeight: '70px',
  borderRadius: '4px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))
export const CompanyLabelChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  fontWeight: 'bold',
  // minWidth: '60px', // Set a consistent width for the Chip
  // maxWidth: '30px', // Set a consistent width for the Chip
  // minHeight: '30px',
  height: '20px',
  width: '60px',
  borderRadius: '4px',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
}))
export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '800px',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '0px 2px 3px 5px 5px',
    // flex: '1 1 45%', // Allows text fields to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for text fields
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'white',
    minWidth: '150px', // Ensures a minimum width for input base
    flex: '1 1 45%', // Allows select components to be 45% of the container width
  },
  '& .MuiSelect-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'white',
    flex: '1 1 45%', // Allows select components to be 45% of the container width
    minWidth: '150px', // Ensures a minimum width for select components
  },
})

export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '0 auto',
}
export const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '#f4f6f8',
  padding: theme.spacing(6),
  borderRadius: '25px',
}))

export const InfoRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: 'relative',
}))

export const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5),
}))

export const InfoValueWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(1), // Add padding to create space between value and underline
}))

export const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  fontSize: '1rem',
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2',
  fontWeight: 700,
  fontSize: '1rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const Underline = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '1px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
}))

export const CityStateZipRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  position: 'relative',
}))
export const PhoneRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  position: 'relative',
}))
export const OrganizationTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30 43 59)' : '#ffffff',
  textAlign: 'left',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0,0,0,0.12)', // Dim white borde
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',

  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.32)' : 'rgba(0,0,0,0.22)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.12)', // Dim white border
    },
    '&:hover fieldset': {
      borderWidth: '0px !important',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
      // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
      // borderColor: 'white', // Change border color when focused
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    // backgroundColor: theme.palette.mode === 'dark' ? 'red' : '#f0f0f0', // Change background color when focused
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40 53 69)' : '#f0f0f0', // Ensure the input itself also changes background color
    },
  },
}))
export const dataGridStyles = {
  // width: '95vw',
  // height: '100vh' /* Adjust this value to fit your needs */,
  // overflow: 'hidden',
  // height: '100%',
  // width: '100%',
  // maxWidth: '95vw',
  // maxHeight: '100%',
  // justifyContent: 'center',
  // alignItems: 'center',
}
export const gridContainerStyles = {
  display: 'flex',
  maxHeight: '100vh',
  // width: '100%',
  justifyContent: 'center',
  // alignItems: 'center',
}
export const popoverStyles = theme => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.85)' : 'rgba(255, 255, 255, 0.85)',
  zIndex: 1000,
})

export const boxContainerStyles = theme => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(2),
})

export const boxStyles = theme => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  justifyContent: 'left',
  width: '100%',
  border: '1px solid',
  borderColor: 'rgba(201, 50, 46, 0.5)',
  padding: theme.spacing(1),
})

export const deleteButtonStyles = theme => ({
  borderColor: 'black',
  color: 'red',
  fontWeight: 'bold',
  ':hover': {
    backgroundColor: 'rgba(201, 50, 46)',
    borderColor: 'black',
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
})

export const textStyles = theme => ({
  width: '100%',
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  padding: theme.spacing(1),
  fontSize: 18,
})

export const buttonContainerStyles = theme => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
})

export const overlayTextStyles = theme => ({
  position: 'absolute',
  top: '-500px', // Adjust the value to position the text above the box
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'rgba(255, 0, 0, 0.8)', // Adjust for better visibility
  fontStyle: 'italic',
  fontWeight: 'bolder',
  zIndex: 1001,
  textAlign: 'center',
  width: '80%',
  pointerEvents: 'none',
})
export const iconValidatedStyles = theme => ({
  height: 18,
  width: 18,
  color: theme.palette.mode === 'dark' ? 'rgb(25, 117, 255)' : 'rgba(0,0,0,0.8)',
})
export const iconInvalidatedStyles = theme => ({
  height: 18,
  width: 18,
  color: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.4)' : 'rgba(0,0,0,0.8)',
})
export const headerStyle = theme => ({
  fontWeight: 'bold',
  fontSize: 24,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const detailHeaderStyle = theme => ({
  // fontWeight: 'bold',
  fontSize: 20,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const detailTextStyle = theme => ({
  fontWeight: 'bold',
  fontSize: 22,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
export const HeaderButton = styled(Button)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(51, 67, 91)' : 'rgb(211, 211, 211)',
  backgroundColor: 'rgb(29, 106, 82)',
  color: theme.palette.mode === 'dark' ? 'white' : 'white',
}))
export const gridToolbarContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
export const stringAvatar = name => {
  const nameParts = name.split(' ')
  if (nameParts.length < 2) {
    return {
      children: (
        <Typography
          sx={{
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {nameParts[0][0]}
        </Typography>
      ),
    }
  }

  return {
    children: (
      <Typography
        sx={{
          fontSize: 14,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {`${nameParts[0][0]}${nameParts[1][0]}`}
      </Typography>
    ),
  }
}
export const filterPanelProps = {
  '& .MuiDataGrid-filterForm': {
    width: '100%',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& .MuiFormControl-root': {
      flex: '1 1 auto',
      margin: '0',
      minWidth: '100px',
      '& .MuiInputBase-root': {
        padding: '4px',
        borderRadius: '4px',
      },
    },
  },
  '& .MuiDataGrid-filterFormValueInput': {
    flex: '2 1 auto',
    minWidth: '150px',
    '& input': {
      padding: '6px 8px',
      width: '100%',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
  },
}
