export const renderColorByString = (string, theme) => {
  switch (string) {
    case 'Marketing':
    case 'MSA':
    case 'RMSA':
    case 'MSA - Housing':
    case 'RMSA - Housing':
    case 'IO':
      return 'rgb(88, 188, 128)'
    case 'Creative':
    case 'CSA':
    case 'RCSA':
      return 'rgb(165, 147, 255)'
    case 'draft':
      return 'rgb(167, 172, 182)'
    case 'Draft':
      return 'rgb(167, 172, 182)'
    case 'saved':
      return 'rgb(35, 146, 173)'
    case 'Saved':
      return 'rgb(35, 146, 173)'
    case 'sent':
      return 'rgb(108, 218, 135)'
    case 'Sent':
      return 'rgb(93, 79, 251)'
    case 'created':
      return 'rgb(114, 229, 218)'
    case 'Created':
      return 'rgb(114, 229, 218)'
    case 'Active':
      return 'rgb(80, 200, 120)'
    case 'Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Rejected':
    case 'Expired':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Onboarding':
      return 'rgb(110, 193, 232)'
      return 'rgba(137, 196, 244, 1)'
    case 'Awaiting Dependencies':
      return 'rgb(59, 152, 245)'
    case 'Dependencies Received':
      return 'rgb(245, 124, 79)'
    case 'Handoff Call':
      return 'rgba(106, 65, 192, 1)'
    case 'Launched':
      return 'rgb(52, 211, 145)'
    case 'Cancelled':
      return theme ? theme.palette.text.primary : 'rgba(0,0,0)'
    case 'Viewed':
      return 'rgb(213, 102, 201)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69)'
    case 'Contract Created':
      return 'rgb(11, 127, 171)'
    case 'Contract Sent':
      return 'rgb(217, 169, 102)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 1)'
    case 'Needs Approval':
      return 'rgb(251, 193, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 1)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 1)'
    case 'Completed':
      return 'rgb(80, 200, 120)'
    case 'Proposal':
      return 'rgb(174, 92, 174)'
    case 'Invoiced':
      return 'rgb(80, 200, 120)'
    case 'Not Invoiced':
      return 'rgb(249, 167, 49)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208)'
    case 'Month To Month':
      return 'rgb(22, 138, 239)'
    case 'N/A':
      return 'rgb(121, 126, 147)'
    case 'Requested':
      return 'rgb(253, 188, 100)'
    case 'Done':
      return 'rgb(51, 211, 145)'
    case 'Deal Won':
      return 'rgb(51, 211, 145)'
    case 'In Progress':
      return 'rgb(253, 188, 100)'
    case 'Stuck':
      return 'rgb(232, 105, 125)'
    case 'draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'Draft':
      return 'rgb(167, 172, 182, 0.6)'
    case 'saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'Saved':
      return 'rgb(35, 146, 173, 0.6)'
    case 'sent':
      return 'rgb(108, 218, 135, 0.6)'
    case 'Sent':
      return 'rgb(93, 79, 251, 0.6)'
    case 'created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Created':
      return 'rgb(114, 229, 218, 0.6)'
    case 'Active':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Approved':
      return 'rgb(6, 255, 167, 1)'
    case 'Resolved':
      return 'rgb(249, 167, 49)'
    case 'Rejected':
      return 'rgba(220, 20, 60, 0.8)'
    case '30 Day Notice':
      return 'rgb(235, 77, 77)'
    case 'Viewed':
      return 'rgb(213, 102, 201, 0.6)'
    case 'Updated':
      return 'rgb(255, 68, 161)'
    case 'Modified':
    case 'Modify':
      return 'rgb(255, 68, 161)'
    case 'Contract Approved':
      return 'rgb(255, 144, 69, 0.6)'
    case 'Strategy Approved':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Sent For Approval':
      return 'rgb(163, 96, 205, 0.6)'
    case 'Approval Requested':
      return 'rgba(255, 148, 112, 0.6)'
    case 'Needs Approval':
      return 'rgb(245, 189, 30)'
    case 'Pending Approval':
      return 'rgba(241, 130, 141, 0.6)'
    case 'Completed Internally':
      return 'rgba(22, 160, 133, 0.6)'
    case 'Completed':
      return 'rgb(80, 200, 120, 0.6)'
    case 'Proposal':
      return 'rgb(174, 92, 174, 0.6)'
    case 'Active - 30 Day Notice':
      return 'rgb(255, 123, 208, 0.6)'
    case 'Month To Month':
      return 'rgb(22, 138, 239, 0.6)'
    case 'Prospect':
      return 'rgb(93, 79, 251)'
    case 'Onboarding':
      return 'rgb(92, 179, 253)'
    case 'In-Progress':
      return 'rgb(110, 156, 222)'
    case 'Active':
      return 'rgb(152, 214, 129)'
    case 'Active (SEO)':
      return 'rgb(152, 98, 161)'
    case 'Active (Creative)':
      return 'rgb(147, 111, 218)'
    case 'Active (Website)':
      return 'rgb(102, 69, 169)'
    case 'Active (Past Due)':
      return 'rgb(255, 131, 88)'
    case 'Active (LTP)':
      return 'rgb(51, 158, 205)'
    case '30 day notice':
      return 'rgb(235, 77, 77)'
    case 'Cancelled':
      return 'rgb(0,0,0)'
    case 'Inactive / Paused':
      return 'rgb(255, 145, 145)'
    case 'Select Status':
      return 'rgb(121, 126, 147)'
    case 'Waiting On Google':
      return 'rgb(161, 227, 246)'
    case 'In Progress':
      return 'rgb(253, 188, 100)'
    case 'DMS Attention':
      return 'rgb(255, 131, 88)'
    case 'AM Attention':
      return 'rgb(51, 158, 205)'
    case 'Need Client Feedback':
      return 'rgb(133, 214, 255)'
    case 'Done':
      return 'rgb(51, 211, 145)'
    case 'AM Follow Up':
      return 'rgb(43, 118, 229)'
    case 'Ready To Launch':
      return 'rgb(121, 175, 253)'
    case 'Postdated (AM)':
      return 'rgb(181, 125, 227)'
    case 'Postdated (DMS)':
      return 'rgb(147, 111, 218)'
    case 'On Hold':
      return 'rgb(201, 92, 118)'
    case 'Waiting on Creative':
      return 'rgb(92, 92, 92)'
    case 'DMS SLA Change':
      return 'rgb(119, 122, 229)'
    case 'AM SLA Change':
      return 'rgb(53, 153, 112)'
    case 'Ready to QA':
      return 'rgb(113, 214, 209)'
    case 'SEO Attention':
      return 'rgb(232, 105, 125)'
    case 'PM Attention':
      return 'rgb(78, 115, 167)'
    case 'Needs MI Attention':
      return 'rgb(157, 153, 185)'
    case 'Waiting On Ad Approvals':
      return 'rgb(255, 68, 161)'
    case 'Churn':
      return 'rgb(255, 123, 208)'
    case 'Ready to QA - Google Search':
      return 'rgb(251, 180, 244)'
    case 'Ready to QA - FB/IG':
      return 'rgb(255, 189, 189)'
    case 'Ready to QA - GDN':
      return 'rgb(217, 116, 176)'
    case 'Ready to QA - Simplifi':
      return 'rgb(189, 168, 249)'
    case 'Ready to QA - TikTok':
      return 'rgb(255, 145, 145)'
    case 'Ready to QA - Other':
      return 'rgb(169, 190, 232)'
    case 'N/A':
      return 'rgb(121, 126, 147)'
    case 'New Client Onboard':
      return 'rgb(176, 220, 81)'
    case 'Campaign QA':
      return 'rgb(133, 214, 255)'
    case 'Copy Update':
      return 'rgb(253, 188, 100)'
    case 'Campaign Updates':
      return 'rgb(255, 213, 51)'
    case 'Campaign Review':
      return 'rgb(51, 211, 145)'
    case 'Budget Rec':
      return 'rgb(232, 105, 125)'
    case 'Budget Updates':
      return 'rgb(51, 158, 205)'
    case 'Launch New Creatives':
      return 'rgb(181, 125, 227)'
    case 'GTM/GA Setup':
      return 'rgb(53, 153, 112)'
    case 'Campaign Setup':
      return 'rgb(121, 175, 253)'
    case 'AM Task  Update':
      return 'rgb(213, 197, 103)'
    case 'Offboard Client':
      return 'rgb(92, 92, 92)'
    case 'SEO Change':
      return 'rgb(201, 92, 118)'
    case 'SEO Update':
      return 'rgb(255, 68, 161)'
    case 'Discovery':
      return 'rgb(255, 123, 208)'
    case 'Strategy Request':
      return 'rgb(147, 111, 218)'
    case 'New Agreement':
      return 'rgb(255, 131, 88)'
    case 'Approve':
    case 'approve':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolve':
    case 'resolve':
      return 'rgb(249, 167, 49)'
    case 'Reject':
    case 'reject':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Request':
    case 'request':
    case 'Generate':
      return 'rgb(233, 136, 103)'
    case 'Creation':
    case 'creation':
    case 'Create':
      return 'rgb(25, 117, 255)'
    case 'Save':
      return 'rgb(114, 229, 218)'
    case 'Update':
      return 'rgb(114, 229, 218)'
    case 'Product Update':
      return 'rgb(255, 123, 187)'
    default:
      return 'rgba(178, 190, 181, 0.6)'
  }
}
