import React from 'react'
import { GridFooterContainer, GridFooter } from '@mui/x-data-grid-pro'

const CustomNcoGridFooter = () => {
  return (
    <GridFooterContainer>
      <GridFooter />
    </GridFooterContainer>
  )
}
export default CustomNcoGridFooter
