import React, { useEffect, useState } from 'react'
import MentionsInput from './MentionsInput'
import { useCreateNotification } from '../../api/aws/useCreateNotification'
import { Box, Button } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import RichTextMentions from './RichTextMentions'
import { useUsers } from '../../api/aws/useUsers'
import DetailsLoading from '../Loading/DetailsLoading'
const MentionsPage = () => {
  const { addNotification, unreadCount, markAllAsRead } = useNotify()
  const [notificationsArray, setNotificationsArray] = useState([])
  const { users, isUsersLoading } = useUsers()
  const [text, setText] = useState('')
  useEffect(() => {
    if (notificationsArray && notificationsArray?.length > 0) {
      console.log(notificationsArray)
    }
  }, [notificationsArray])
  const handleAction = () => {
    // Create a new notification
    addNotification(notificationsArray)
  }
  if (isUsersLoading) {
    return <DetailsLoading />
  }
  return (
    <Box>
      <MentionsInput setNotificationsArray={setNotificationsArray} />
      <RichTextMentions content={text || '<p></p>'} onContentChange={setText} users={users} onBlur={() => {}} />
      <Button onClick={handleAction}>Submit Notification</Button>
    </Box>
  )
}

export default MentionsPage

/* PREVIOUS EDITION MENTIONSINPUT */

// import React, { useEffect, useState } from 'react'
// import MentionsInput from './MentionsInput'
// import { useCreateNotification } from '../../api/aws/useCreateNotification'
// import { Box, Button } from '@mui/material'
// const MentionsPage = () => {
//   const { mutate: createNotification } = useCreateNotification()
//   const [notificationsArray, setNotificationsArray] = useState([])
//   useEffect(() => {
//     if (notificationsArray && notificationsArray?.length > 0) {
//       console.log(notificationsArray)
//     }
//   }, [notificationsArray])
//   const handleSubmit = () => {
//     try {
//       if (notificationsArray && notificationsArray?.length > 0) {
//         notificationsArray.forEach(notification => {
//           const result = createNotification(notification)
//           console.log('result', result)
//         })
//       }
//     } catch (error) {
//       console.error('error creating new notification', error)
//     }
//   }

//   return (
//     <Box>
//       <MentionsInput setNotificationsArray={setNotificationsArray} />
//       <Button onClick={handleSubmit}>Submit Notification</Button>
//     </Box>
//   )
// }

// export default MentionsPage
