import React, { useRef, useState } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import DealInfoCard from './DealInfoCard'
import { gridStyles } from './style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useDeal } from '../../api/aws/useDeal'

const DealsDetails = () => {
  const { dealId } = useParams()
  const theme = useTheme()
  // const { newDeal, setNewDeal } = useDealDetailsContext()
  const { deal: newDeal, isDealLoading } = useDeal(dealId, ['dealPage', dealId])
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { showSnackbar } = useSnackbarContext()
  // Retrieve contacts associated with the deal
  const newContactIds = newDeal && newDeal.contacts && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const { newContacts } = useNewContactsById(newContactIds, 'dealDetails-contacts')
  const handleNavigate = () => {
    if (newDeal?.id) {
      navigate(`/deals/${newDeal.id}/contract`)
    } else {
      console.error('No deal ID found')
    }
  }
  const handleEdit = () => {
    setIsEditMode(true)
    setIsOpen(true)
    handleNavigate()
  }

  return (
    <Grid container spacing={0} justifyContent='center' sx={gridStyles(theme)}>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} gap={2}>
        <Grid container spacing={2} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <DealInfoCard key={dealId} deal={newDeal} onEdit={handleEdit} newContacts={newContacts} />
            <Outlet />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                height: 250,
                borderRadius: '25px',
                width: '100%',
                mb: 1,
              }}
            />
            <Box
              sx={{
                padding: 2,
                backgroundColor: gridBgColor,
                height: 130,
                borderRadius: '25px',
                width: '100%',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default DealsDetails
