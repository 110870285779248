import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Stack,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import {
  DialogIcon,
  dialogStyles,
  personIconStyles,
  dialogTitleStyles,
  inputStyles,
  boxStyles,
  dialogContentStyles,
} from './style'
import OrganizationsAutocomplete from '../Organizations/OrgAutocomplete'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { formatPhoneNumber } from './formatPhoneNumber'

const ContactsDialog = ({ open, onClose, tempInput, onSaveContact }) => {
  const { activeUser, isActiveUserLoadin, isActiveUserError } = useActiveUser()
  const { data: organizations, isLoading: isOrganizationsLoading, isError: isOrganizationsError } = useOrganizations()
  const [validationErrors, setValidationErrors] = useState({
    organizations: false,
  })
  const theme = useTheme()
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    officePhone: '',
    organizations: [],
    title: '',
    createdBy: {},
    owner: [],
  }
  const [newContact, setNewContact] = useState({
    ...initialState,
    createdBy: {
      id: activeUser?.user?.id,
      name: activeUser?.user?.real_name,
      firstName: activeUser?.user?.profile?.first_name,
      lastName: activeUser?.user?.profile?.last_name,
      image: activeUser?.user?.profile?.image_512,
    },
    owner: [activeUser?.user?.id],
  })

  useEffect(() => {
    const [firstName, ...lastName] = tempInput.split(' ')
    setNewContact(prev => ({
      ...prev,
      firstName: firstName || '',
      lastName: lastName.join(' ') || '',
      name: `${firstName} ${lastName.join(' ')}`.trim(),
    }))
  }, [tempInput])

  const handleChange = (key, event) => {
    let { value } = event.target
    if (key === 'phone' || key === 'officePhone') {
      value = formatPhoneNumber(value)
    }

    setNewContact(prev => ({
      ...prev,
      [key]: value,
      name: key === 'firstName' || key === 'lastName' ? `${prev.firstName} ${prev.lastName}`.trim() : prev.name,
    }))
  }
  const handleSave = () => {
    const fullName = `${newContact.firstName} ${newContact.lastName}`.trim()
    const contactToSave = { ...newContact, name: fullName }
    console.log('Creating new contact: ', contactToSave)
    onSaveContact(contactToSave)
    setNewContact({ ...initialState })
  }

  const handleAddOrganization = organization => {
    setNewContact(prev => ({
      ...prev,
      organizations: Array.isArray(organization)
        ? organization.map(item => item.org_uuid || item.id)
        : organization
        ? [organization.org_uuid || organization.id]
        : [],
    }))
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm' PaperProps={{ sx: dialogStyles(theme) }}>
      {isOrganizationsLoading && <DetailsLoading />}
      <Box sx={boxStyles}>
        <DialogIcon src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} />
        <DialogTitle sx={dialogTitleStyles}>
          <Typography>Add a New Contact</Typography>
        </DialogTitle>
        <PersonIcon sx={personIconStyles} />
      </Box>
      <DialogContent sx={dialogContentStyles}>
        <Stack spacing={0}>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs={6}>
              <TextField
                label='First Name'
                type='text'
                fullWidth
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: inputStyles(theme) }}
                value={newContact.firstName}
                onChange={e => handleChange('firstName', e)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Last Name'
                type='text'
                fullWidth
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: inputStyles(theme) }}
                value={newContact.lastName}
                onChange={e => handleChange('lastName', e)}
              />
            </Grid>
          </Grid>

          {newContact.organizations && (
            <OrganizationsAutocomplete
              organizations={organizations}
              label={'Select Organization'}
              addOrganization={handleAddOrganization}
              error={validationErrors.organizations}
              helperText={validationErrors.organizations && 'Organization is required'}
            />
          )}

          {Object.entries(newContact).map(([key, value]) => {
            if (
              key !== 'name' &&
              key !== 'firstName' &&
              key !== 'lastName' &&
              key !== 'createdBy' &&
              key !== 'organizations' &&
              key !== 'owner'
            ) {
              return (
                <TextField
                  key={key}
                  label={key[0].toUpperCase() + key.slice(1)}
                  type='text'
                  fullWidth
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: inputStyles(theme) }}
                  value={value}
                  onChange={e => handleChange(key, e)}
                />
              )
            }
            return null
          })}

          {newContact.createdBy && (
            <Typography variant='body2' color='textSecondary'>
              Created By: {newContact.createdBy.name}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSave} color='primary' variant='contained'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactsDialog
