import {
  Box,
  Chip,
  IconButton,
  Typography,
  Tooltip,
  Link as MuiLink,
  Avatar,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip, NcoStatusContainer, CreateProjectTextField } from '../../../style/styleElements'
import { Link } from 'react-router-dom'
import PandaDocLinkIcon from '../../Icons/PandaDockLinkIcon'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles, DisabledIconButton, fieldStyles } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import { analyzeStrategy } from '../../Strategy/analyzeStrategy'
import PandaDocViewer from '../../PaymentSchedule/PandDocViewer'
import EditSelectCell from '../../NCO/EditSelectCell'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import EditableDatePicker from '../../NCO/EditableDatePicker'
import { renderColorByString } from '../renderColorByString'

/*
Inside Drawer:

- Vertical, Deal Name, Strategy, Type, Contract, Stage

*/
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
export const formatDayJsTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('hh:mm A')
  } else {
    return null
  }
}
const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

export const renderNcoColumns = (organizations, users, deals, density = 'standard', handleDealClick, theme) => {
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      // console.log('[customNotContainsOperator] filterItem:', filterItem)

      // Check if the filter value is defined
      if (filterItem.value === undefined || filterItem.value === null) {
        // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
        return null
      }

      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
        return null
      }

      // Log the cellValue during the filter function application
      return cellValue => {
        // console.log('[customNotContainsOperator] cellValue:', cellValue)
        const result = !containsFn(cellValue)
        // console.log('[customNotContainsOperator] Result of filter application:', result)
        return result
      }
    },
  }

  // Log the applied filterOperators

  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    // Ensure 'name' is a string, default to an empty string if not
    const validName = typeof name === 'string' ? name : ''

    const nameParts = validName ? validName.split(' ') : ['']

    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }
    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }
  return [
    // {
    //   field: 'name',
    //   headerName: 'Deal Name',
    //   width: 100,
    //   flex: 1.75,
    //   headerAlign: 'left',
    //   valueGetter: params => {
    //     return params
    //   },
    //   filterOperators: [...getGridStringOperators(), customNotContainsOperator],
    //   renderCell: params => {
    //     const dealId = params.row.id
    //     return (
    // <Box onClick={() => handleDealClick(params.row)} sx={{ cursor: 'pointer', height: '100%', width: '100%' }}>
    //         {params.value}
    //       </Box>
    //     )
    //   },
    // },
    {
      field: 'organizations',
      headerName: 'Organization',
      width: 100,
      flex: 1.45,
      headerAlign: 'left',
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg = organizations.find(org => org.id === orgId) || organizations.find(org => org.zoho_id === orgId)
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params &&
          params.row &&
          params.row.organizations &&
          Array.isArray(params.row.organizations) &&
          params.row.organizations[0]
            ? params.row.organizations[0]
            : params && params.row && params.row.organizations && params.row.organizations.id
            ? params.row.organizations.id
            : null
        const photoUrl = organizations ? organizations.find(org => org.id === orgId)?.photoUrl : null
        const orgName = params && params.value ? params.value : ''

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            onClick={() => handleDealClick(params.row)}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                height: '100%',
                width: '100%',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              // onClick={() => handleDealClick(params.row)}
            >
              {photoUrl ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'center',
                    height: '100%',
                    gap: 1,
                  }}
                >
                  <Avatar
                    src={photoUrl}
                    sx={{
                      width: densityStyles.avatarSize,
                      height: densityStyles.avatarSize,
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    alt={params && params.value ? params.value : params}
                  />

                  <Typography
                    sx={{
                      fontSize: densityStyles.fontSize,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params && params.value ? params.value : params}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'center',
                    height: '100%',
                    gap: 1,
                  }}
                >
                  <Avatar
                    {...stringAvatar(params && params.value ? params.value : params)}
                    sx={{
                      backgroundColor: 'rgb(114, 229, 218)',
                      width: densityStyles.avatarSize,
                      height: densityStyles.avatarSize,
                    }}
                    alt={params && params.value ? params.value : params}
                  />
                  <Typography
                    sx={{
                      fontSize: densityStyles.fontSize,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params && params.value ? params.value : params}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* </Link> */}
          </Box>
        )
      },
    },

    {
      field: 'ncoStatus',
      headerName: 'NCO Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params || 'Onboarding'
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: renderColorByString(params.value),
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => (
        <EditSelectCell
          params={params}
          options={[
            { value: 'Onboarding', label: 'Onboarding' },
            { value: 'Awaiting Dependencies', label: 'Awaiting Dependencies' },
            { value: 'Dependencies Received', label: 'Dependencies Received' },
            { value: 'Handoff Call', label: 'Handoff Call' },
            { value: 'Launched', label: 'Launched' },
          ]}
        />
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'timeCompleted',
      headerName: 'Date Closed',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {params && params.value ? (
              params.value
            ) : (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'dependenciesDueDate',
      headerName: 'Dependencies Due',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            {params && params.value ? params.value : 'Set Date'}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }

        return <EditableDatePicker value={params.value} onChange={handleChange} />
      },
    },
    {
      field: 'slaDays',
      headerName: 'SLA Days',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params || 0 // Default to 0 if no value is set
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={handleClick}
          >
            {params.value ?? 'Set SLA Days'} {/* Display a default message if no value */}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = event => {
          const value = event.target.value ? parseInt(event.target.value, 10) : 0 // Parse the value as an integer
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: Math.min(100, Math.max(0, value)), // Clamp the value between 0 and 100
          })
        }

        return (
          <TextField
            type='number'
            value={params.value ?? 0} // Default value if not set
            onChange={handleChange}
            fullWidth
            autoFocus
            inputProps={{
              min: 0,
              max: 100,
              step: 1, // Control step increment
            }}
            sx={{
              textAlign: 'center', // Center the text in the input
            }}
          />
        )
      },
    },
    {
      field: 'estLaunchDate',
      headerName: 'Estimated Launch Date',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        const estLaunchDate = params
        return estLaunchDate ? dayjs(estLaunchDate).format('MM/DD/YYYY') : null
      },
      renderCell: params => {
        const estLaunchDate = params.value

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {estLaunchDate || (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'dateLaunched',
      headerName: 'Date Launched',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },

      renderCell: params => {
        const dateLaunched = params?.row?.dateLaunched ? formatDateString(params?.row?.dateLaunched) : null

        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }

        return (
          <>
            {params && params.value ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
              >
                {params.value}
              </Box>
            ) : dateLaunched ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
              >
                {dateLaunched}
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
              >
                {'Set Date Launched'}
              </Box>
            )}
          </>
        )
      },
      renderEditCell: params => {
        const dateLaunched = params?.row?.dateLaunched ?? null
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }

        return <EditableDatePicker value={params.value || dateLaunched} onChange={handleChange} />
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.45,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {contractUrl ? (
              <PandaDocViewer contractId={contractId} height={32} width={32} />
            ) : (
              <PandaDocViewer contractId={contractId} height={32} width={32} disabled={true} />
            )}
          </Box>
        )
      },
    },

    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      width: 100,
      flex: 1.15,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        const contractedById = params && Array.isArray(params) ? params[0] : []
        const contractedBy = organizations ? organizations.find(org => org.id === contractedById) : null
        return contractedBy ? contractedBy.name : ''
      },
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const org = organizations ? organizations.find(org => org.id === orgId) : null
        const contractedById = org && Array.isArray(org.contractedBy) ? org.contractedBy[0] : null

        return (
          <>
            {params && params.value ? (
              <RenderContractedByColumn
                key={`${contractedById}`}
                orgName={params.value ?? ''}
                orgId={contractedById}
                densityStyles={densityStyles}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              ></Box>
            )}
          </>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both are empty
        if (!a) return 1 // Treat empty as larger, so a (empty) goes after b (non-empty)
        if (!b) return -1 // Treat empty as larger, so b (empty) goes after a (non-empty)
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },

    {
      field: 'strategy',
      headerName: 'Strategy',
      width: 100,
      flex: 0.55,
      headerAlign: 'left',
      sortable: true,
      valueGetter: params => {
        return params?.progress ?? ''
      },
      renderCell: params => {
        const status = params.row.status
        const deal = params && params.row ? params.row : null
        const dealId = deal.id
        const orgId = deal.organizations && deal.organizations.length > 0 ? deal.organizations[0] : null
        const selectedOrganization = organizations.find(org => org.id === orgId)
        const selectedVerticals = selectedOrganization ? selectedOrganization.vertical : []
        // const { progress, completed } = analyzeStrategy(params.row)
        // const isCreated = status === 'created' || status === 'Created'
        return (
          <Link
            to={`/deals/${dealId}/strategy`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
            }}
          >
            {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
            }}
          > */}
            <StrategyProgress
              dealId={dealId}
              // progress={progress}
              // completed={completed}
              size={densityStyles.globeSize}
            />
            {/* </Box> */}
          </Link>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },

    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsDate(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },

    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const userId = params && params[0] ? params[0] : null
        const user = users.find(u => u.id === userId)
        return user && user.name ? user.name : 'No User Assigned'
      },
      renderCell: params => {
        const userId = params && params.row && params.row.owner ? params.row.owner[0] : null
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: densityStyles.fontSize,
                }}
              >
                ?
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
  ]
}
export default renderNcoColumns
