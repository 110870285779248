import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getCampaign = async (endpoint, tableName, id) => {
  try {
    const url = id ? `${baseUrl}${endpoint}/${id}` : `${baseUrl}${endpoint}`
    const response = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useCampaign = (endpoint, tableName, id = null) => {
  const { data, isLoading: isCampaignLoading, isError: isCampaignError } = useQuery({
    queryKey: ['campaign', id], // Use endpoint and id as the query key
    queryFn: () => getCampaign('/aws/campaigns', 'campaigns', id),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!id,
  })
  return { campaign: data, isCampaignLoading, isCampaignError }
}
