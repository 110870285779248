import React from 'react'
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import PandaIcon from './panda-icon'

const RevertContractButton = ({ text, isReverting, onRevert, disabled = false }) => {
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <>
      {isLargeScreen || isExtraLargeScreen || isMediumScreen || isSmallScreen ? (
        <Button
          sx={{
            width: '100%',
            height: '100%',
            minHeight: 42,
            backgroundColor: theme.palette.text.secondary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 20,
          }}
          disabled={isReverting || disabled}
          onClick={onRevert}
          name='isReverted'
          variant='contained'
        >
          {isReverting ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1, // Space between items
              }}
            >
              <PandaIcon />
              <Typography>Reverting...</Typography>
              <CircularProgress
                size={15}
                sx={{
                  color: theme.palette.text.primary, // Use primary color for visibility
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1, // Space between items
              }}
            >
              <PandaIcon />
              <Typography
                sx={{
                  textAlign: 'left', // Align text left or right as needed
                  whiteSpace: 'nowrap', // Prevent wrapping
                  overflow: 'hidden', // Add ellipsis for long links
                  textOverflow: 'ellipsis',
                }}
              >
                {text ?? 'Revert to Draft'}
              </Typography>
            </Box>
          )}
        </Button>
      ) : (
        <Tooltip title={<Typography>Revert to draft</Typography>}>
          <IconButton disabled={isReverting || disabled} onClick={onRevert} name='isReverted' variant='contained'>
            {isReverting ? <CircularProgress /> : <PandaIcon />}
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default RevertContractButton
