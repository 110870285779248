import { Box, Typography } from '@mui/material'
import React from 'react'
import dayjs from 'dayjs'

const ServiceDetails = ({ column, value }) => {
  let formattedValue = value || 'N/A'

  // Format dates for Date Range column
  if (column?.id === 'dateRange' && Array.isArray(value)) {
    formattedValue = value
      .map(date => (dayjs(date).isValid() ? dayjs(date).format('MM/DD/YYYY') : 'Invalid Date'))
      .join(' - ')
  }

  // Add currency or percentage formatting
  if (['billedPrice', 'grossProfit', 'mediaSpend', 'price'].includes(column?.id)) {
    formattedValue = `$${parseFloat(value || 0).toFixed(2)}`
  } else if (column?.id === 'margin') {
    formattedValue = `${parseFloat(value || 0).toFixed(2)}%`
  }

  return (
    <Box
      key={column?.id}
      sx={{
        display: 'flex',
        flexDirection: column?.id === 'dateRange' ? 'column' : 'row',
        justifyContent: 'space-between',
        mb: 1,
        px: 1,
      }}
    >
      <Typography
        variant='body2'
        sx={{
          fontWeight: 'bold',
          mr: 1,
          textAlign: 'left',
          flex: 1, // Ensures the labels have consistent spacing
        }}
      >
        {column?.label}:
      </Typography>
      <Typography
        variant='body2'
        sx={{
          textAlign: 'right',
          flex: 1, // Ensures values align consistently
        }}
      >
        {formattedValue}
      </Typography>
    </Box>
  )
}

export default ServiceDetails
