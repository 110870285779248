import React from 'react'
import { List, ListItem, Typography, ListItemIcon, ListItemButton, ListItemText, useTheme, Badge } from '@mui/material'
import ConnectedUsers from '../../../context/Websocket/ConnectedUsers'
import PeopleIcon from '@mui/icons-material/People'
import { useWebsocketContext } from '../../../context/Websocket/WebsocketProvider'

const ConnectedUsersNav = ({ open, isConnectedUsersOpen, handleOpenConnectedUsers, handleCloseConnectedUsers }) => {
  const theme = useTheme()
  const { connectedUsers } = useWebsocketContext()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
  return (
    <>
      <List>
        {[{ label: 'Connected Users' }].map((item, index) => (
          <ListItem key={item.label} disablePadding sx={{ display: 'block', width: '100%' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&:hover': {
                  backgroundColor: bgHoverColor,
                },
              }}
              onClick={handleOpenConnectedUsers} // Open the modal here
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? 40 : 0, // Ensure consistent spacing for icons
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Badge
                  badgeContent={
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(63, 195, 128, 1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {connectedUsers && Array.isArray(connectedUsers) ? connectedUsers.length : 0}
                    </Typography>
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <PeopleIcon />
                </Badge>
              </ListItemIcon>
              {open && (
                <ListItemText
                  sx={{
                    opacity: open ? 1 : 0,
                    ml: open ? 0 : -1, // Ensure the text moves closer to the icon when closed
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                    fontSize: '14px', // Adjust as per your preference
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
                      transition: 'opacity 0.3s ease', // Smooth transition for the text visibility
                    }}
                  >
                    {item.label}
                  </Typography>
                </ListItemText>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {isConnectedUsersOpen && (
        <ConnectedUsers
          isConnectedUsersOpen={isConnectedUsersOpen}
          handleCloseConnectedUsers={handleCloseConnectedUsers}
        />
      )}
    </>
  )
}
export default ConnectedUsersNav

// import React from 'react'
// import { List, ListItem, Typography, ListItemIcon, ListItemButton, ListItemText, useTheme } from '@mui/material'
// import ConnectedUsers from '../../../context/Websocket/ConnectedUsers'

// const ConnectedUsersNav = ({ open, isConnectedUsersOpen, setIsConnectedUsersOpen }) => {
//   const theme = useTheme()
//   return (
//     <List>
//       {[
//         { label: 'Connected Users' },

//         // Add more items here if necessary
//       ].map((item, index) => (
//         <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
//           <ListItemButton
//             sx={{
//               minHeight: 48,
//               justifyContent: open ? 'initial' : 'center',
//               px: 2.5,
//             }}
//           >
//             <ListItemIcon
//               sx={{
//                 minWidth: 0,
//                 mr: open ? 3 : 'auto',
//                 justifyContent: 'center',
//               }}
//             >
//               {/* You can decide what icon to use based on index or some other logic */}
//               {index === 0 && <ConnectedUsers isOpen={isConnectedUsersOpen} setIsOpen={setIsConnectedUsersOpen} />}
//             </ListItemIcon>
//             <ListItemText
//               sx={{
//                 opacity: open ? 1 : 0,
//               }}
//             >
//               <Typography
//                 sx={{
//                   color: theme.palette.mode === 'dark' ? 'white' : 'black',
//                 }}
//               >
//                 {item.label}
//               </Typography>
//             </ListItemText>
//           </ListItemButton>
//         </ListItem>
//       ))}
//     </List>
//   )
// }

// export default ConnectedUsersNav
