export const determineReviewState = (activeUser, approver, isResolved) => {
  if (activeUser.id === approver.id && approver.hasApproved) {
    return 0
  } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && !isResolved) {
    return 1
  } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && isResolved) {
    return 2
  } else if (activeUser.id === approver.id && !approver.hasApproved && approver.hasRejected && !isResolved) {
    return 3
  }
}
