export const formatPhoneNumber = phoneNumber => {
  // Remove all non-digit characters
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')

  // Remove the leading '1' if present
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    cleaned = cleaned.slice(1)
  }

  // Match and format the number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}`
  }

  return phoneNumber // Return original if it doesn't match expected format
}
