import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getOrganizations = async (endpoint, tableName, filters = {}) => {
  try {
    const url = `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: { table: tableName, ...filters }, // Pass filters for PK, SK, or EntityType
    })
    return data?.data
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}
export const useOrganizations = (enabled, queryKey = ['organizations']) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKey,
    queryFn: () => getOrganizations('/aws/organizations', 'organizations'),
    staleTime: Infinity, // Prevent the query from becoming stale
    cacheTime: 1000 * 60 * 30, // Cache for 30 minutes
    refetchOnWindowFocus: false, // Prevent refetching when window regains focus
    refetchOnReconnect: false, // Prevent refetching when the network reconnects
    enabled: enabled ?? false, // Keep this enabled for debugging
  })
  return { data, isLoading, isError }
}
