import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Extend dayjs with the necessary plugins
dayjs.extend(utc)
dayjs.extend(timezone)

export const validateDate = date => {
  if (!date || !dayjs(date).isValid()) {
    return null // Return null for invalid dates
  }
  return dayjs(date) // Return a valid Day.js object
}

export const normalizeDate = (date, timezone) => {
  // console.log('Input Date:', date)
  // console.log('Input Timezone:', timezone)

  const validDate = validateDate(date)
  // console.log('Validated Date:', validDate)

  const result = validDate
    ? validDate.utc().hour(12).minute(0).second(0).millisecond(0).tz(timezone).toISOString()
    : null

  // console.log('Normalized Date:', result)
  return result
}
export const validDateValue = (date, timezone) => {
  if (!date) return null // Handle null values explicitly
  const validDate = dayjs.isDayjs(date) ? date : dayjs(date).isValid() ? dayjs(date) : null

  return validDate ? validDate.tz(timezone) : null
}
