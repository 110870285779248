import React, { useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Paper, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import PersonIcon from '@mui/icons-material/Person'

const CreateMenu = ({ onOpenCreateOrganization, onOpenCreateDeal, onOpenCreateContact, onOpenCreateTask }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        title='Create Menu'
        sx={{
          color: theme => (theme.palette.mode === 'dark' ? 'white' : 'black'),
          '&:hover': {
            backgroundColor: theme => theme.palette.secondary.main,
          },
        }}
      >
        <AddIcon style={{ fontSize: 30 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust the menu's position
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: '50vh', // Limit height for smaller screens
              overflowY: 'auto', // Add scroll for long menus
              minWidth: '225px',
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onOpenCreateOrganization()
            handleClose()
          }}
        >
          <CorporateFareIcon
            sx={{
              color: 'rgb(114, 229, 218)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Organization</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onOpenCreateDeal()
            handleClose()
          }}
        >
          <AttachMoneyIcon
            sx={{
              color: '#fbc11e',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Deal</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onOpenCreateContact()
            handleClose()
          }}
        >
          <PersonIcon
            sx={{
              color: 'rgb(255, 123, 187)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Contact</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            onOpenCreateTask()
            handleClose()
          }}
        >
          <TaskAltIcon
            sx={{
              color: 'rgb(255, 68, 161)',
            }}
          />
          <Typography sx={{ margin: 1 }}>Create Task</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default CreateMenu
