import React from 'react'
import { InputAdornment, Typography, useTheme } from '@mui/material'
const StyledInputAdornment = ({ text = '$', position = 'start', fontsize = '0.8rem' }) => {
  const theme = useTheme()
  return (
    <InputAdornment position={position}>
      <Typography sx={{ color: theme.palette.text.secondary, fontSize: fontsize }}>{text}</Typography>
    </InputAdornment>
  )
}

export default StyledInputAdornment
