import React from 'react'
import { Box, Button, Modal, Typography, useTheme } from '@mui/material'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: '40vw',
  p: 4,
}

const GenerateLinksModal = ({ isOpen, onClose, onConfirm, isGenerating }) => {
  const theme = useTheme()

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography
          id='generate-link-modal-typography'
          variant='h6'
          component='h2'
          sx={{ fontStyle: 'italic', color: theme.palette.text.warning }}
        >
          You are about to generate links via PandaDoc API
        </Typography>
        <Typography
          id='generate-link-modal-typography'
          variant='body1'
          component='h2'
          sx={{ fontStyle: 'italic', color: theme.palette.text.primary }}
        >
          Links are not sent to the client automatically **
        </Typography>
        <Typography
          id='generate-link-modal-typography'
          variant='body1'
          component='h2'
          sx={{ fontStyle: 'italic', color: theme.palette.text.primary }}
        >
          Links will be immediately available once completed. Please ensure you are ready before generating.
        </Typography>
        <Box sx={{ display: 'flex', my: 2 }}>
          <Button variant='contained' color='primary' onClick={onConfirm} disabled={isGenerating}>
            {isGenerating ? 'Generating...' : 'Confirm'}
          </Button>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default GenerateLinksModal
