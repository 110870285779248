export const setStageStyle = stage => {
  if (stage === 'Lead') {
    return '#e6e6e6'
  } else if (stage === 'Discovery') {
    return '#3f8bff'
  } else if (stage === 'Proposal') {
    return '#b25eb2'
  } else if (stage === 'Awaiting Approval') {
    return 'rgba(241, 130, 141, 1)'
  } else if (stage === 'Pending Approval') {
    return 'rgba(241, 130, 141, 1)'
  } else if (stage === 'Contract Sent') {
    return 'rgb(217, 169, 102)'
  } else if (stage === 'Contract Created') {
    return 'rgba(11, 127, 171, 1)'
  } else if (stage === 'Deal Won') {
    return 'rgb(80, 200, 120)'
  } else if (stage === 'Deal Lost') {
    return '#ffb5ac'
    return '#ffcfc9'
  } else if (stage === 'Cold') {
    return '#a8caff'
  } else if (stage === 'Purgatory') {
    return '#ffdc85'
  }
}
