import React, { useState, useEffect } from 'react'
import { Drawer, Box, Typography, useTheme, useMediaQuery, Tabs, Tab, Grid } from '@mui/material'
import NcoCardsRow from './NcoCardsRow'
import NcoDrawerHeader from './NcoDrawerHeader'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, Outlet, useLocation, useParams } from 'react-router-dom'
import Strategy from '../Strategy/Strategy'
import RenderCampaign from '../StrategyCampaigns/RenderCampaign'
import { useDeal } from '../../api/aws/useDeal'
import { ncoTeamTasks } from './ncoTaskObjects'
import ReviewDeal from '../DealApproval/ReviewDeal'

const initializeTasks = deal => {
  if (!deal) return {}

  const updatedDeal = { ...deal }

  // Loop through roles and initialize task properties if missing
  Object.keys(ncoTeamTasks[0]).forEach(role => {
    const roleKey = `${role.toLowerCase()}Tasks`
    if (!updatedDeal[roleKey]) {
      const roleTasks = ncoTeamTasks[0][role].reduce((acc, taskGroup) => {
        const [taskName] = Object.keys(taskGroup)
        acc[taskName] = 'N/A' // Default status
        return acc
      }, {})
      updatedDeal[roleKey] = roleTasks
    }
  })

  return updatedDeal
}

const Notes = () => <Typography>Notes Component</Typography>

const NcoDrawer = ({ open, onClose }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dealId, campaignId } = useParams()
  // const { newDeal } = useDealDetailsContext()
  const theme = useTheme()
  const { mutate: updateDeal } = useUpdateDeal()
  const [updates, setUpdates] = useState({})
  const queryClient = useQueryClient()
  const { deal: newDeal, isDealLoading, isDealError } = useDeal(dealId)
  const [deal, setDeal] = useState(() => initializeTasks(newDeal))
  const [initialValues, setInitialValues] = useState(newDeal ?? {})
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)

    if (newValue === 0) {
      navigate(`/nco/${dealId}`) // Navigate to the base path for Main
    } else if (newValue === 1) {
      navigate(`/nco/${dealId}/strategy`)
    } else if (newValue === 2) {
      navigate(`/nco/${dealId}/review`)
    } else if (newValue === 3) {
      navigate(`/nco/${dealId}/notes`)
    }
  }

  // Determine active tab based on the URL
  useEffect(() => {
    if (location.pathname.includes(`strategy/${campaignId}`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`strategy`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`review`)) {
      setActiveTab(2)
    } else if (location.pathname.includes(`notes`)) {
      setActiveTab(3)
    } else {
      setActiveTab(0)
    }
  }, [location, campaignId])
  const handleUpdate = () => {
    if (Object.keys(updates).length === 0) return

    const dealId = newDeal?.deal_uuid
    // const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
    const updateData = { ...initialValues, ...updates, queryKey: ['deal', dealId] }
    console.log('updateData:', updateData)
    try {
      updateDeal(
        { dealId, dealData: updateData },
        {
          onSuccess: () => {
            setUpdates({})
          },
          onError: error => {
            console.error('Error updating deal:', error)
            setUpdates({})
          },
        }
      )
    } catch (e) {
      console.error('[NcoDrawer] error updating deal', e)
    }
  }
  const handleClose = async () => {
    handleUpdate()
    onClose()
  }

  // Call useMediaQuery only at the top level
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  // Calculate drawerWidth based on screen size
  let drawerWidth = '100%'
  if (isExtraLargeScreen) {
    // console.log('XL SCREEN')
    drawerWidth = '85%'
  } else if (isLargeScreen) {
    // console.log('LG SCREEN')
    drawerWidth = '65%'
  } else if (isMediumScreen) {
    // console.log('MD SCREEN')
    drawerWidth = '75%'
  } else if (isSmallScreen) {
    // console.log('SM SCREEN')
    drawerWidth = '85%'
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: drawerWidth,
          overflow: 'hidden',
          height: '100vh',
          // p: 1,
          // height: '93.2vh'
        },
      }}
    >
      <Box>
        {/* Header */}

        <NcoDrawerHeader newDeal={newDeal} />
        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
          <Tab label='Main' />
          <Tab label='Strategy' />
          <Tab label='Review' />
          <Tab label='Notes' />
        </Tabs>

        {/* Tab Content */}
        <Box>
          {activeTab === 0 && (
            <NcoCardsRow deal={deal} updates={updates} setUpdates={setUpdates} initialValues={newDeal} />
          )}
          {activeTab === 1 && location.pathname.includes(`strategy/${campaignId}`) ? (
            <RenderCampaign />
          ) : activeTab === 1 && location.pathname.includes(`strategy`) ? (
            <Strategy />
          ) : null}
          {activeTab === 2 && <ReviewDeal />}
          {activeTab === 3 && <Notes />}
        </Box>
        <Outlet />
      </Box>
    </Drawer>
  )
}

export default NcoDrawer
