import React from 'react'
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'

const SaveAndUpdateButton = ({ onClick, isSaving, isUpdating, isDraft }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 42,
          backgroundColor: isDraft ? 'rgb(78, 170, 237)' : '',
        }}
        // disabled={isRequesting || isRequested || !isValid}
        disabled={isSaving || !isDraft}
        onClick={onClick}
        name='save-contract-button'
        variant='outlined'
      >
        {!isSaving && !isUpdating ? (
          <Typography
            sx={{
              textAlign: 'left', // Align text left or right as needed
              whiteSpace: 'nowrap', // Prevent wrapping
              overflow: 'hidden', // Add ellipsis for long links
              textOverflow: 'ellipsis',
            }}
          >
            Save & Update
          </Typography>
        ) : isSaving ? (
          <Typography>
            Saving...{' '}
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          </Typography>
        ) : (
          <Typography>
            Updating...{' '}
            <CircularProgress
              size={15}
              sx={{
                color: theme.palette.text.secondary,
              }}
            />
          </Typography>
        )}
      </Button>
    </Box>
  )
}

export default SaveAndUpdateButton
