import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useGetQueryByIds } from '../aws/useGetQueryByIds'
import { baseUrl } from '../queryMutations/queryMutations'

export const useOrganizationsById = (orgIds, queryString = 'organization') => {
  const validIds = Array.isArray(orgIds) ? orgIds.filter(id => !!id) : []

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/organizations', 'organizations', validIds, queryString)
  // Extract user data from successful queries
  const organizations = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data
  // Aggregate loading and error states
  const isOrganizationsLoading = queryResults.some(result => result.isLoading)
  const isOrganizationsError = queryResults.some(result => result.isError)
  const organizationsByIdError = queryResults.find(result => result.isError)?.error

  return { organizations, isOrganizationsError, isOrganizationsLoading, organizationsByIdError }
}
