import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getUser = async (endpoint, tableName, id) => {
  try {
    const url = `${baseUrl}/aws/users/${id}`
    const response = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useUser = (userId, queryString = 'user') => {
  const { data: user, isLoading, isError } = useQuery({
    queryKey: [queryString, userId], // Use consistent query key
    queryFn: () => getUser('/aws/users', 'users', userId),
    staleTime: Infinity, // Optional: adjust based on your caching needs
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!userId, // Only fetch if userId is provided
  })

  return { user, isLoading, isError }
}
