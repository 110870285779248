import React from 'react'
import { Box, CircularProgress, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'

const SaveAndUpdateIconButton = ({ onClick, isSaving, isUpdating, isDraft }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        size='medium'
        sx={{
          borderRadius: '50px',
          // backgroundColor: isDraft ? 'rgb(78, 170, 237)' : '',
          border: isDraft ? `2px solid rgb(78, 170, 237)` : `2px solid ${theme.palette.text.secondary}`,
        }}
        disabled={isSaving || !isDraft}
        onClick={onClick}
        name='save-update-icon-button'
        variant='outlined'
      >
        {!isSaving && !isUpdating ? (
          <Tooltip title={<Typography>Save & Update</Typography>}>
            <UpdateIcon
              sx={{ fontSize: '34px', color: !isDraft ? theme.palette.text.secondary : theme.palette.text.primary }}
            />
          </Tooltip>
        ) : isSaving ? (
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.text.secondary,
            }}
          />
        ) : (
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.text.secondary,
            }}
          />
        )}
      </IconButton>
    </Box>
  )
}

export default SaveAndUpdateIconButton
